import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';

// App atm just has a lot of logic we should move into named components.
import { Provider } from 'react-redux';
import { StripeProvider } from 'react-stripe-elements';

import * as serviceWorker from './serviceWorker';
import store from './store';

import App from './app';

ReactDOM.render((
  <Provider store={store}>
    {/* We only need the stripe provider for the checkout/payments window. */}
    <StripeProvider apiKey="pk_live_RQHwQydPLS1hlpBcGu76t3LG">
      <App />
    </StripeProvider>
  </Provider>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
