import React, { Component } from "react";
import { Router, Route, Switch } from "react-router-dom";

import history from "./util/history";

import Contact from "./components/contact";
import FAQ from "./components/faq";
// import Favorites from './components/favorites';
import Featured from "./components/featured";
import Landing from "./components/landing";
import OrderStatus from "./components/order-status";
import Pay from "./components/pay";
import Search from "./components/search";
import ShirtPage from "./components/shirt-page";
import Terms from "./components/terms";
import WikiPage from "./components/wiki-page";
import RandomPage from "./components/random-page";

class AppRouter extends Component {
  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route path="/browse" component={WikiPage} />
          <Route path="/checkout" component={Pay} />
          <Route path="/contact" component={Contact} />
          <Route path="/faq" component={FAQ} />
          <Route path="/featured" component={Featured} />
          <Route path="/order" component={OrderStatus} />
          <Route path="/search" component={Search} />
          <Route path="/random" component={RandomPage} />
          <Route path="/terms" component={Terms} />
          <Route path="/:pageTitle/:imageTitle" component={ShirtPage} />
          <Route path="/:pageTitle" component={WikiPage} />
          <Route path="/" component={Landing} exact />
        </Switch>
      </Router>
    );
  }
}

export default AppRouter;
