import React, { Component } from "react";

import "./landing.scss";

class LandingCard extends Component {
  render() {
    return (
      <div
        className={`landing-item landing-card no-padding-mobile ${
          this.props.alignRight ? "align-right" : ""
        }`}
      >
        <div
          className="landing-item-shirt-background background-desktop-only"
          style={{
            backgroundImage: `url("${this.props.backgroundUrl}")`
          }}
        />
        <div className="landing-item-left landing-left-box">
          <div
            className="landing-item-shirt-background landing-thumb"
            style={{
              backgroundImage: `url("${this.props.backgroundUrl}")`
            }}
          />
          {this.props.contents}
        </div>
      </div>
    );
  }
}

export default LandingCard;
