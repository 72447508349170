import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";

import { ACTIONS } from "../../store";
import { TEES } from "../../constants";

import Footer from "../footer";
import Header from "../header";
import ItemPreview from "../item-preview";
import { PageContent } from "../page";
import LandingCard from "./landing-card";
import LandingDaily from "./landing-daily";

import "./landing.scss";

class Landing extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  componentDidMount() {
    document.title = `WearWiki`;

    this.props.setFabGhostRef(this.ref);
  }

  componentWillUnmount() {
    this.props.setFabGhostRef(null);
  }

  onShirtClick = () => {
    this.props.setGalleryScrollPosition(this.galleryRef.current.scrollLeft);
  };

  render() {
    return (
      <Fragment>
        <Header />
        <PageContent>
          <div className="landing-item landing-hero">
            <div className="landing-item-left">
              <div className="landing-detail">Wikipedia on a shirt.</div>
              <div className="landing-title">
                With over 20 million images there's something for everyone.
              </div>
              <div className="landing-text">
                For each shirt bought we send $1 to Wikipedia in your name.
              </div>
              <Link className="link-button icon-right" to="/browse">
                <span>Explore the Endless Store&nbsp;*</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  style={{ transform: "rotate(180deg)" }}
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path
                    className="icon"
                    d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"
                  />
                </svg>
              </Link>
              <div className="landing-footnote">
                * Not actually endless, just extremely vast.
              </div>
            </div>
            <div className="outline-container">
              <p className="outline-container-label">Featured Shirts ✨</p>
              <div className="landing-gallery">
                {TEES.FEATURED_TEES.map((featured, index) =>
                  index < 10 ? (
                    <Link
                      className="featured-tee"
                      key={featured.itemDisplayName + featured.imageUrl}
                      to={`/${featured.pageTitle}/${featured.imageTitle}`}
                    >
                      <ItemPreview
                        authorName={featured.authorName}
                        itemDisplayName={featured.itemDisplayName}
                        hideButtons
                        imageUrl={featured.imageUrl}
                      />
                    </Link>
                  ) : null
                )}
              </div>
            </div>
          </div>
          <LandingCard
            backgroundUrl="/unsplash-shirt-pic.jpg"
            contents={
              <Fragment>
                <div className="landing-detail">Featured Shirts *</div>
                <div className="landing-title">
                  A selection of some of the coolest shirts discovered so far.
                </div>
                <Link className="link-button" to="/featured">
                  Browse Featured
                </Link>
                <div className="landing-footnote">* Guaranteed to be dank.</div>
              </Fragment>
            }
          />
          <LandingDaily />
          <LandingCard
            backgroundUrl="/unsplash-explore.jpg"
            alignRight
            contents={
              <Fragment>
                <div className="landing-detail">
                  Explore the Endless Store *
                </div>
                <div className="landing-title">
                  There are over 5 million pages and 20 million images.
                </div>
                <Link className="link-button" to="/browse">
                  Get Lost Browsing
                </Link>
                <div className="landing-footnote">
                  * Once again, not endless, just ginormous.
                </div>
              </Fragment>
            }
          />
          <Footer />
        </PageContent>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setFabGhostRef: ref => ({
        type: ACTIONS.SET_FAB_GHOST_REF,
        ref: ref
      }),
      setGalleryScrollPosition: xOffset => ({
        type: ACTIONS.SET_GALLERY_SCROLL_POSITION,
        x: xOffset
      })
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Landing);
