import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import ACTIONS from "./actions";
import {
  DEFAULT_SHIRT_COLOR,
  DEFAULT_SHIRT_SIZE,
  PAGES,
  THEMES
} from "../constants";
import { getTotalPrice, getSubtotalFromOrders } from "../util/orders";
import { getTitleForPage } from "../util/wikipedia-images";

// const testShippingOps = [{
//   "id":"PRINTFUL_MEDIUM",
//   "name":"Standard (3-5 business days after fulfillment)",
//   "rate":"2.81",
//   "currency":"USD"
// },{"id":"PRINTFUL_FAST","name":"Express (1-3 business days after fulfillment)","rate":"3.57","currency":"USD"},{"id":"STANDARD","name":"Flat Rate (3-5 business days after fulfillment)","rate":"5.00","currency":"USD"},{"id":"PRINTFUL_1DAY","name":"Overnight (1 business day after fulfillment)","rate":"32.30","currency":"USD"}];

// const testInitialCustomerInformation = {
//   shippingName: 'Rice Towell',
//   shippingAddress1: '28 Orchard St.',
//   shippingAddress2: 'Apt 4A',
//   shippingZip: '10002',
//   shippingCity: 'New York',
//   shippingCountry: 'US',
//   shippingCountrySelected: {
//     value: 'US',
//     label: 'United States of America',
//     states: [{
//       value: 'NY',
//       label: 'New York'
//     },{
//       value: 'WV',
//       label: 'West Virginia'
//     }]
//   }, // Used for the html select.
//   shippingRegion: 'NY',
//   shippingRegionSelected: {
//     value: 'NY',
//     label: 'New York'
//   }, // Used for the html select.

//   email: 'rhysh@live.com',
// };

const initialCustomerInformation = {
  email: "",

  shippingName: "",
  shippingAddress1: "",
  shippingAddress2: "",
  shippingZip: "",
  shippingCity: "",
  shippingCountry: "",
  shippingCountrySelected: null, // Used for the html select.
  shippingRegion: "",
  shippingRegionSelected: null // Used for the html select.
};

function getInitialState() {
  const storageOrders = JSON.parse(localStorage.getItem("orders"));
  const storageProcessedImagesId = JSON.parse(
    localStorage.getItem("processedImagesId")
  );
  const orders = storageOrders ? storageOrders : [];
  const processedImagesId = storageProcessedImagesId
    ? storageProcessedImagesId
    : "";
  const subtotal = getSubtotalFromOrders(orders);

  const blackShirt = new Image();
  blackShirt.src = "/shirtdark_fullsize_labeled.png";
  const whiteShirt = new Image();
  whiteShirt.src = "/shirt_fullsize_labeled.png";
  const blackShirtThumb = new Image();
  blackShirtThumb.src = "/shirtdark_thumb_labeled.png";
  const whiteShirtThumb = new Image();
  whiteShirtThumb.src = "/shirt_thumb_labeled.png";

  const initialState = {
    customerInformation: {
      ...initialCustomerInformation
      // ...testInitialCustomerInformation
    },
    orders,
    processedImagesId,
    orderImagesAreProcessed: false,
    makingOrder: false,

    selectedShippingOption: {},
    shippingOptions: [],
    // When false, the user's address is not available to ship to.
    shippingOptionsAvailable: false,
    // When true we've loaded the shipping options.
    shippingOptionsLoaded: false,

    showCheckoutWindow: false,

    subtotal,

    stripeToken: "",

    totalPrice: null,

    currentPage: PAGES.WELCOME,
    showHeader: true,
    theme: THEMES.LIGHT,

    fabGhostRef: null,
    galleryScrollPosition: null,

    // Shirt editing state.
    includeAuthorAttribution: false,
    includePageAttribution: false,
    shirtColor: DEFAULT_SHIRT_COLOR,
    shirtSize: DEFAULT_SHIRT_SIZE,

    // Information from wikipedia.
    wikiPage: null,
    wikiPageLoading: false,
    wikiPageError: null,
    wikiImages: [],
    wikiImagesLoading: false,
    wikiImagesError: null,

    bufferWikiPage: null,
    bufferWikiPageLoading: false,
    bufferWikiPageError: null,
    bufferWikiImages: [],
    bufferWikiImagesLoading: false,
    bufferWikiImagesError: null,

    bufferReady: false,
    saveBufferAsNewPage: false,

    wikis: {},
    randomWikis: [],

    pushUrl: false,
    deepLinkImageTitle: null,

    blackShirt: blackShirt,
    whiteShirt: whiteShirt,
    blackShirtThumb: blackShirtThumb,
    whiteShirtThumb: whiteShirtThumb
  };

  return initialState;
}

function reducer(state = getInitialState(), action) {
  const newWikis = { ...state.wikis };
  switch (action.type) {
    case ACTIONS.UPDATE_CUSTOMER_INFORMATION: {
      return {
        ...state,
        customerInformation: {
          ...state.customerInformation,
          ...action.customerInformationUpdate
        }
      };
    }
    case ACTIONS.CLEAR_ORDERS:
      localStorage.removeItem("orders");
      localStorage.removeItem("processedImagesId");

      return {
        ...state,
        orders: [],
        processedImagesId: "",
        subtotal: 0,
        totalPrice: 0
      };
    case ACTIONS.REPORT_SHIRT:
      return {
        ...state,
        reportShirtWikiPage: action.wikiPage,
        reportShirtShirtName: action.shirtName
      };
    case ACTIONS.UPDATE_ORDERS:
      const updatedOrders = [...action.newOrders];
      // We also save orders in the local store so a user can come back to them.
      localStorage.setItem("orders", JSON.stringify(updatedOrders));

      return {
        ...state,
        orders: updatedOrders,
        subtotal: getSubtotalFromOrders(updatedOrders)
      };
    case ACTIONS.SELECT_SHIPPING_OPTION:
      const newTotalPrice = getTotalPrice(
        state.orders,
        action.newShippingOption
      );

      return {
        ...state,
        selectedShippingOption: action.newShippingOption,
        totalPrice: newTotalPrice
      };
    case ACTIONS.SET_MAKING_ORDER:
      return {
        ...state,
        makingOrder: action.makingOrder
      };
    case ACTIONS.SET_SHIPPING_OPTIONS:
      const shippingOptionsAvailable =
        action.newShippingOptions && action.newShippingOptions.length > 0;

      const totalPrice = shippingOptionsAvailable
        ? getTotalPrice(state.orders, action.newShippingOptions[0])
        : null;

      return {
        ...state,
        selectedShippingOption: shippingOptionsAvailable
          ? action.newShippingOptions[0]
          : {},
        shippingOptionsAvailable,
        shippingOptionsLoaded: true,
        shippingOptions: action.newShippingOptions,
        totalPrice
      };
    case ACTIONS.SET_SHIPPING_OPTIONS_LOADED:
      return {
        ...state,
        shippingOptionsLoaded: action.shippingOptionsLoaded
      };
    case ACTIONS.SET_PROCESSING_IMAGES:
      if (
        action.imagesAreProcessed &&
        state.processedImagesId !== action.processedImagesId
      ) {
        // Ignore an old processing image request.
        return {
          ...state
        };
      } else {
        return {
          ...state,
          processedImagesId: action.processedImagesId,
          orderImagesAreProcessed: action.imagesAreProcessed
        };
      }

    case ACTIONS.SET_STRIPE_TOKEN:
      return {
        ...state,
        stripeToken: action.stripeToken
      };
    case ACTIONS.FETCH_WIKI_PAGE_BEGIN:
      if (action.saveAsBuffer) {
        return {
          ...state,
          bufferWikiPageLoading: true,
          bufferWikiPageError: null,
          bufferReady: false
        };
      } else {
        return {
          ...state,
          currentPage: PAGES.LOADING_WIKI,
          wikiPageLoading: true,
          wikiPageError: null,
          deepLinkImageTitle: action.imageTitle
        };
      }
    case ACTIONS.FETCH_WIKI_PAGE_SUCCESS:
      if (action.saveAsBuffer) {
        return {
          ...state,
          bufferWikiPageLoading: false,
          bufferWikiPage: action.payload.page
        };
      } else {
        return {
          ...state,
          currentPage: PAGES.LOADING_WIKI,
          wikiPageLoading: false,
          wikiPage: action.payload.page
        };
      }
    case ACTIONS.FETCH_WIKI_PAGE_FAILURE:
      if (action.saveAsBuffer) {
        return {
          ...state,
          bufferWikiPageLoading: false,
          bufferWikiPageError: action.payload.error
        };
      } else {
        return {
          ...state,
          currentPage: PAGES.FAILURE,
          wikiPageLoading: false,
          wikiPageError: action.payload.error
        };
      }
    case ACTIONS.FETCH_WIKI_IMAGES_BEGIN:
      if (action.saveAsBuffer) {
        return {
          ...state,
          bufferWikiImagesLoading: true,
          bufferWikiImagesError: null
        };
      } else {
        return {
          ...state,
          currentPage: PAGES.LOADING_WIKI,
          wikiImagesLoading: true,
          wikiImagesError: null
        };
      }
    case ACTIONS.FETCH_WIKI_IMAGES_FAILURE:
      if (action.saveAsBuffer) {
        return {
          ...state,
          bufferWikiImagesLoading: false,
          bufferWikiImagesError: action.payload.error
        };
      } else {
        return {
          ...state,
          currentPage: PAGES.FAILURE,
          wikiImagesLoading: false,
          wikiImagesError: action.payload.error
        };
      }
    case ACTIONS.LOAD_FROM_BUFFER:
      return {
        ...state,
        wikiPage: state.bufferWikiPage,
        wikiImages: state.bufferWikiImages,
        currentPage: PAGES.WIKI,
        galleryScrollPosition: null,
        pushUrl: true,
        bufferReady: false,
        bufferWikiPage: null,
        bufferWikiImages: []
      };
    case ACTIONS.SAVE_BUFFER_AS_NEW_PAGE:
      return {
        ...state,
        saveBufferAsNewPage: true,
        currentPage: PAGES.LOADING_WIKI
      };
    case ACTIONS.SET_FAB_GHOST_REF:
      return {
        ...state,
        fabGhostRef: action.ref
      };
    case ACTIONS.SET_SHIRT_COLOR:
      return {
        ...state,
        shirtColor: action.shirtColor
      };
    case ACTIONS.SET_SHIRT_SIZE:
      return {
        ...state,
        shirtSize: action.shirtSize
      };
    case ACTIONS.SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.page
      };
    case ACTIONS.SET_INCLUDE_AUTHOR_ATTRIBUTION:
      return {
        ...state,
        includeAuthorAttribution: action.includeAuthorAttribution
      };
    case ACTIONS.SET_INCLUDE_PAGE_ATTRIBUTION:
      return {
        ...state,
        includePageAttribution: action.includePageAttribution
      };
    case ACTIONS.TOGGLE_PUSH_URL:
      return {
        ...state,
        pushUrl: false
      };
    case ACTIONS.CLEAR_DEEP_LINK_IMAGE_TITLE:
      return {
        ...state,
        deepLinkImageTitle: null
      };
    case ACTIONS.SET_GALLERY_SCROLL_POSITION:
      return {
        ...state,
        galleryScrollPosition: action.x
      };
    case ACTIONS.FETCH_WIKI_SUMMARY_SUCCESS:
      newWikis[getTitleForPage(action.payload.page)] = {
        page: action.payload.page,
        images: null
      };
      return {
        ...state,
        wikis: newWikis
      };
    case ACTIONS.FETCH_WIKI_IMAGES_SUCCESS:
      newWikis[getTitleForPage(action.payload.page)].images =
        action.payload.images;
      return {
        ...state,
        wikis: newWikis,
        randomWikis: action.payload.addToRandom
          ? [...state.randomWikis, getTitleForPage(action.payload.page)]
          : state.randomWikis
      };
    case ACTIONS.EMPTY_RANDOM_WIKIS:
      return {
        ...state,
        randomWikis: []
      };
    default:
      return state;
  }
}

let store = createStore(reducer, applyMiddleware(thunk));

export default store;
