import React from 'react';

import './loader.scss';

export default function Loader() {
  return (
    // <div className="sofloo-loader-container">
    //   <div className="sofloo-loader">
    //     <div className="sofloo-loader-outer-box"/>
    //     <div className="sofloo-loader-inner-box"/>
    //   </div>
    //   <div className="sofloo-loader">
    //     <div className="sofloo-loader-outer-box"/>
    //     <div className="sofloo-loader-inner-box"/>
    //   </div>
    //   <div className="sofloo-loader">
    //     <div className="sofloo-loader-outer-box"/>
    //     <div className="sofloo-loader-inner-box"/>
    //   </div>
    // </div>
    <div className="loader-container">
      <div className="loader">
        <div className="spinner" />
      </div>
    </div>
  );
}
