import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import { formatMoneyAsNiceString } from '../../util/money';

import './checkout.scss';

class Checkout extends Component {
  static propTypes = {
    customerInformation: PropTypes.object.isRequired,
    orders: PropTypes.array.isRequired,
    selectedShippingOption: PropTypes.object.isRequired,
    subtotal: PropTypes.number.isRequired,
    totalPrice: PropTypes.number.isRequired
  }

  componentDidMount() {
    document.title = `WearWiki - Checkout`;
  }

  getTotalOrderCount = () => {
    const { orders } = this.props;
    let totalOrderCount = 0;
    for (let i = 0; i < orders.length; i++) {
      totalOrderCount += Number(orders[i].quantity);
    }
    return totalOrderCount;
  }

  render() {
    const {
      customerInformation,
      selectedShippingOption,
      totalPrice
    } = this.props;
    
    const {
      // email,
        
      // shippingName,
      shippingAddress1,
      shippingAddress2,
      shippingZip,
      shippingCity,
      shippingCountry,
      shippingRegion
    } = customerInformation;

    const totalShirtCount = this.getTotalOrderCount();

    return (
      <div className="checkout">
        <Link
          className="link-button icon-left m0"
          to="/checkout/2"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path className="icon" d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"/></svg>
          Back to Shipping
        </Link>
        <h1>Order Summary</h1>
        
        <p className="gray">{totalShirtCount} shirt{totalShirtCount > 1 ? 's' : ''}</p>
        <h2>${formatMoneyAsNiceString(totalPrice)}</h2>

        <div className="checkout-section">
          <h2>Shipping Address</h2>
          <p>{shippingAddress1}</p>
          <p>{shippingAddress2}</p>
          <p>{shippingCity}, {shippingRegion} {shippingZip}</p>
          <p>{shippingCountry !== 'US' ? shippingCountry : null}</p>
        </div>
        <div className="checkout-section">
          <h2>Shipping method</h2>
          <p>{selectedShippingOption.name}</p>
        </div>
        <div className="checkout-button-container">
          <Link className="button m0" to="/checkout/4">Place Order</Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  customerInformation: state.customerInformation,
  orders: state.orders,
  selectedShippingOption: state.selectedShippingOption,
  subtotal: state.subtotal,
  totalPrice: state.totalPrice
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Checkout);
