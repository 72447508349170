
module.exports = [{
  "value": "AD",
  "label": "Andorra",
  "states": null
}, {
  "value": "AE",
  "label": "United Arab Emirates",
  "states": null
}, {
  "value": "AF",
  "label": "Afghanistan",
  "states": null
}, {
  "value": "AG",
  "label": "Antigua and Barbuda",
  "states": null
}, {
  "value": "AI",
  "label": "Anguilla",
  "states": null
}, {
  "value": "AL",
  "label": "Albania",
  "states": null
}, {
  "value": "AM",
  "label": "Armenia",
  "states": null
}, {
  "value": "AN",
  "label": "Netherlands Antilles",
  "states": null
}, {
  "value": "AO",
  "label": "Angola",
  "states": null
}, {
  "value": "AQ",
  "label": "Antarctica",
  "states": null
}, {
  "value": "AR",
  "label": "Argentina",
  "states": null
}, {
  "value": "AS",
  "label": "American Samoa",
  "states": null
}, {
  "value": "AT",
  "label": "Austria",
  "states": null
}, {
  "value": "AU",
  "label": "Australia",
  "states": [
      {
          "value": "ACT",
          "label": "Australian Capital Territory"
      },
      {
          "value": "NSW",
          "label": "New South Wales"
      },
      {
          "value": "NT",
          "label": "Northern Territory"
      },
      {
          "value": "QLD",
          "label": "Queensland"
      },
      {
          "value": "SA",
          "label": "South Australia"
      },
      {
          "value": "TAS",
          "label": "Tasmania"
      },
      {
          "value": "VIC",
          "label": "Victoria"
      },
      {
          "value": "WA",
          "label": "Western Australia"
      }
  ]
}, {
  "value": "AW",
  "label": "Aruba",
  "states": null
}, {
  "value": "AZ",
  "label": "Azerbaijan",
  "states": null
}, {
  "value": "BA",
  "label": "Bosnia and Herzegovina",
  "states": null
}, {
  "value": "BB",
  "label": "Barbados",
  "states": null
}, {
  "value": "BD",
  "label": "Bangladesh",
  "states": null
}, {
  "value": "BE",
  "label": "Belgium",
  "states": null
}, {
  "value": "BF",
  "label": "Burkina Faso",
  "states": null
}, {
  "value": "BG",
  "label": "Bulgaria",
  "states": null
}, {
  "value": "BH",
  "label": "Bahrain",
  "states": null
}, {
  "value": "BI",
  "label": "Burundi",
  "states": null
}, {
  "value": "BJ",
  "label": "Benin",
  "states": null
}, {
  "value": "BM",
  "label": "Bermuda",
  "states": null
}, {
  "value": "BN",
  "label": "Brunei Darussalam",
  "states": null
}, {
  "value": "BO",
  "label": "Bolivia",
  "states": null
}, {
  "value": "BR",
  "label": "Brazil",
  "states": null
}, {
  "value": "BS",
  "label": "Bahamas",
  "states": null
}, {
  "value": "BT",
  "label": "Bhutan",
  "states": null
}, {
  "value": "BV",
  "label": "Bouvet Island",
  "states": null
}, {
  "value": "BW",
  "label": "Botswana",
  "states": null
}, {
  "value": "BY",
  "label": "Belarus",
  "states": null
}, {
  "value": "BZ",
  "label": "Belize",
  "states": null
}, {
  "value": "CA",
  "label": "Canada",
  "states": [
      {
          "value": "AB",
          "label": "Alberta"
      },
      {
          "value": "BC",
          "label": "British Columbia"
      },
      {
          "value": "MB",
          "label": "Manitoba"
      },
      {
          "value": "NB",
          "label": "New Brunswick"
      },
      {
          "value": "NL",
          "label": "Newfoundland and Labrador"
      },
      {
          "value": "NS",
          "label": "Nova Scotia"
      },
      {
          "value": "NT",
          "label": "Northwest Territories"
      },
      {
          "value": "NU",
          "label": "Nunavut"
      },
      {
          "value": "ON",
          "label": "Ontario"
      },
      {
          "value": "PE",
          "label": "Prince Edward Island"
      },
      {
          "value": "QC",
          "label": "Quebec"
      },
      {
          "value": "SK",
          "label": "Saskatchewan"
      },
      {
          "value": "YT",
          "label": "Yukon"
      }
  ]
}, {
  "value": "CC",
  "label": "Cocos (Keeling) Islands",
  "states": null
}, {
  "value": "CD",
  "label": "Congo, the Democratic Republic of the",
  "states": null
}, {
  "value": "CF",
  "label": "Central African Republic",
  "states": null
}, {
  "value": "CG",
  "label": "Congo",
  "states": null
}, {
  "value": "CH",
  "label": "Switzerland",
  "states": null
}, {
  "value": "CI",
  "label": "Cote D'Ivoire",
  "states": null
}, {
  "value": "CK",
  "label": "Cook Islands",
  "states": null
}, {
  "value": "CL",
  "label": "Chile",
  "states": null
}, {
  "value": "CM",
  "label": "Cameroon",
  "states": null
}, {
  "value": "CN",
  "label": "China",
  "states": null
}, {
  "value": "CO",
  "label": "Colombia",
  "states": null
}, {
  "value": "CR",
  "label": "Costa Rica",
  "states": null
}, {
  "value": "CV",
  "label": "Cape Verde",
  "states": null
}, {
  "value": "CW",
  "label": "Curacao",
  "states": null
}, {
  "value": "CX",
  "label": "Christmas Island",
  "states": null
}, {
  "value": "CY",
  "label": "Cyprus",
  "states": null
}, {
  "value": "CZ",
  "label": "Czech Republic",
  "states": null
}, {
  "value": "DE",
  "label": "Germany",
  "states": null
}, {
  "value": "DJ",
  "label": "Djibouti",
  "states": null
}, {
  "value": "DK",
  "label": "Denmark",
  "states": null
}, {
  "value": "DM",
  "label": "Dominica",
  "states": null
}, {
  "value": "DO",
  "label": "Dominican Republic",
  "states": null
}, {
  "value": "DZ",
  "label": "Algeria",
  "states": null
}, {
  "value": "EC",
  "label": "Ecuador",
  "states": null
}, {
  "value": "EE",
  "label": "Estonia",
  "states": null
}, {
  "value": "EG",
  "label": "Egypt",
  "states": null
}, {
  "value": "EH",
  "label": "Western Sahara",
  "states": null
}, {
  "value": "ER",
  "label": "Eritrea",
  "states": null
}, {
  "value": "ES",
  "label": "Spain",
  "states": null
}, {
  "value": "ET",
  "label": "Ethiopia",
  "states": null
}, {
  "value": "FI",
  "label": "Finland",
  "states": null
}, {
  "value": "FJ",
  "label": "Fiji",
  "states": null
}, {
  "value": "FK",
  "label": "Falkland Islands (Malvinas)",
  "states": null
}, {
  "value": "FM",
  "label": "Micronesia, Federated States of",
  "states": null
}, {
  "value": "FO",
  "label": "Faroe Islands",
  "states": null
}, {
  "value": "FR",
  "label": "France",
  "states": null
}, {
  "value": "GA",
  "label": "Gabon",
  "states": null
}, {
  "value": "GB",
  "label": "United Kingdom",
  "states": null
}, {
  "value": "GD",
  "label": "Grenada",
  "states": null
}, {
  "value": "GE",
  "label": "Georgia",
  "states": null
}, {
  "value": "GF",
  "label": "French Guiana",
  "states": null
}, {
  "value": "GG",
  "label": "Guernsey",
  "states": null
}, {
  "value": "GH",
  "label": "Ghana",
  "states": null
}, {
  "value": "GI",
  "label": "Gibraltar",
  "states": null
}, {
  "value": "GL",
  "label": "Greenland",
  "states": null
}, {
  "value": "GM",
  "label": "Gambia",
  "states": null
}, {
  "value": "GN",
  "label": "Guinea",
  "states": null
}, {
  "value": "GP",
  "label": "Guadeloupe",
  "states": null
}, {
  "value": "GQ",
  "label": "Equatorial Guinea",
  "states": null
}, {
  "value": "GR",
  "label": "Greece",
  "states": null
}, {
  "value": "GS",
  "label": "South Georgia and the South Sandwich Islands",
  "states": null
}, {
  "value": "GT",
  "label": "Guatemala",
  "states": null
}, {
  "value": "GU",
  "label": "Guam",
  "states": null
}, {
  "value": "GW",
  "label": "Guinea-Bissau",
  "states": null
}, {
  "value": "GY",
  "label": "Guyana",
  "states": null
}, {
  "value": "HK",
  "label": "Hong Kong",
  "states": null
}, {
  "value": "HM",
  "label": "Heard Island and Mcdonald Islands",
  "states": null
}, {
  "value": "HN",
  "label": "Honduras",
  "states": null
}, {
  "value": "HR",
  "label": "Croatia",
  "states": null
}, {
  "value": "HT",
  "label": "Haiti",
  "states": null
}, {
  "value": "HU",
  "label": "Hungary",
  "states": null
}, {
  "value": "ID",
  "label": "Indonesia",
  "states": null
}, {
  "value": "IE",
  "label": "Ireland",
  "states": null
}, {
  "value": "IL",
  "label": "Israel",
  "states": null
}, {
  "value": "IM",
  "label": "Isle of Man",
  "states": null
}, {
  "value": "IN",
  "label": "India",
  "states": null
}, {
  "value": "IO",
  "label": "British Indian Ocean Territory",
  "states": null
}, {
  "value": "IQ",
  "label": "Iraq",
  "states": null
}, {
  "value": "IS",
  "label": "Iceland",
  "states": null
}, {
  "value": "IT",
  "label": "Italy",
  "states": null
}, {
  "value": "JE",
  "label": "Jersey",
  "states": null
}, {
  "value": "JM",
  "label": "Jamaica",
  "states": null
}, {
  "value": "JO",
  "label": "Jordan",
  "states": null
}, {
  "value": "JP",
  "label": "Japan",
  "states": null
}, {
  "value": "KE",
  "label": "Kenya",
  "states": null
}, {
  "value": "KG",
  "label": "Kyrgyzstan",
  "states": null
}, {
  "value": "KH",
  "label": "Cambodia",
  "states": null
}, {
  "value": "KI",
  "label": "Kiribati",
  "states": null
}, {
  "value": "KM",
  "label": "Comoros",
  "states": null
}, {
  "value": "KN",
  "label": "Saint Kitts and Nevis",
  "states": null
}, {
  "value": "KP",
  "label": "Korea, Democratic People's Republic of",
  "states": null
}, {
  "value": "KR",
  "label": "Korea, Republic of",
  "states": null
}, {
  "value": "KW",
  "label": "Kuwait",
  "states": null
}, {
  "value": "KY",
  "label": "Cayman Islands",
  "states": null
}, {
  "value": "KZ",
  "label": "Kazakhstan",
  "states": null
}, {
  "value": "LA",
  "label": "Lao People's Democratic Republic",
  "states": null
}, {
  "value": "LB",
  "label": "Lebanon",
  "states": null
}, {
  "value": "LC",
  "label": "Saint Lucia",
  "states": null
}, {
  "value": "LI",
  "label": "Liechtenstein",
  "states": null
}, {
  "value": "LK",
  "label": "Sri Lanka",
  "states": null
}, {
  "value": "LR",
  "label": "Liberia",
  "states": null
}, {
  "value": "LS",
  "label": "Lesotho",
  "states": null
}, {
  "value": "LT",
  "label": "Lithuania",
  "states": null
}, {
  "value": "LU",
  "label": "Luxembourg",
  "states": null
}, {
  "value": "LV",
  "label": "Latvia",
  "states": null
}, {
  "value": "LY",
  "label": "Libyan Arab Jamahiriya",
  "states": null
}, {
  "value": "MA",
  "label": "Morocco",
  "states": null
}, {
  "value": "MC",
  "label": "Monaco",
  "states": null
}, {
  "value": "MD",
  "label": "Moldova, Republic of",
  "states": null
}, {
  "value": "ME",
  "label": "Montenegro",
  "states": null
}, {
  "value": "MF",
  "label": "Sint Maarten",
  "states": null
}, {
  "value": "MG",
  "label": "Madagascar",
  "states": null
}, {
  "value": "MH",
  "label": "Marshall Islands",
  "states": null
}, {
  "value": "MK",
  "label": "Macedonia, the Former Yugoslav Republic of",
  "states": null
}, {
  "value": "ML",
  "label": "Mali",
  "states": null
}, {
  "value": "MM",
  "label": "Myanmar",
  "states": null
}, {
  "value": "MN",
  "label": "Mongolia",
  "states": null
}, {
  "value": "MO",
  "label": "Macao",
  "states": null
}, {
  "value": "MP",
  "label": "Northern Mariana Islands",
  "states": null
}, {
  "value": "MQ",
  "label": "Martinique",
  "states": null
}, {
  "value": "MR",
  "label": "Mauritania",
  "states": null
}, {
  "value": "MS",
  "label": "Montserrat",
  "states": null
}, {
  "value": "MT",
  "label": "Malta",
  "states": null
}, {
  "value": "MU",
  "label": "Mauritius",
  "states": null
}, {
  "value": "MV",
  "label": "Maldives",
  "states": null
}, {
  "value": "MW",
  "label": "Malawi",
  "states": null
}, {
  "value": "MX",
  "label": "Mexico",
  "states": null
}, {
  "value": "MY",
  "label": "Malaysia",
  "states": null
}, {
  "value": "MZ",
  "label": "Mozambique",
  "states": null
}, {
  "value": "NA",
  "label": "Namibia",
  "states": null
}, {
  "value": "NC",
  "label": "New Caledonia",
  "states": null
}, {
  "value": "NE",
  "label": "Niger",
  "states": null
}, {
  "value": "NF",
  "label": "Norfolk Island",
  "states": null
}, {
  "value": "NG",
  "label": "Nigeria",
  "states": null
}, {
  "value": "NI",
  "label": "Nicaragua",
  "states": null
}, {
  "value": "NL",
  "label": "Netherlands",
  "states": null
}, {
  "value": "NO",
  "label": "Norway",
  "states": null
}, {
  "value": "NP",
  "label": "Nepal",
  "states": null
}, {
  "value": "NR",
  "label": "Nauru",
  "states": null
}, {
  "value": "NU",
  "label": "Niue",
  "states": null
}, {
  "value": "NZ",
  "label": "New Zealand",
  "states": null
}, {
  "value": "OM",
  "label": "Oman",
  "states": null
}, {
  "value": "PA",
  "label": "Panama",
  "states": null
}, {
  "value": "PE",
  "label": "Peru",
  "states": null
}, {
  "value": "PF",
  "label": "French Polynesia",
  "states": null
}, {
  "value": "PG",
  "label": "Papua New Guinea",
  "states": null
}, {
  "value": "PH",
  "label": "Philippines",
  "states": null
}, {
  "value": "PK",
  "label": "Pakistan",
  "states": null
}, {
  "value": "PL",
  "label": "Poland",
  "states": null
}, {
  "value": "PM",
  "label": "Saint Pierre and Miquelon",
  "states": null
}, {
  "value": "PN",
  "label": "Pitcairn",
  "states": null
}, {
  "value": "PR",
  "label": "Puerto Rico",
  "states": null
}, {
  "value": "PS",
  "label": "Palestinian Territory, Occupied",
  "states": null
}, {
  "value": "PT",
  "label": "Portugal",
  "states": null
}, {
  "value": "PW",
  "label": "Palau",
  "states": null
}, {
  "value": "PY",
  "label": "Paraguay",
  "states": null
}, {
  "value": "QA",
  "label": "Qatar",
  "states": null
}, {
  "value": "RE",
  "label": "Reunion",
  "states": null
}, {
  "value": "RO",
  "label": "Romania",
  "states": null
}, {
  "value": "RS",
  "label": "Serbia",
  "states": null
}, {
  "value": "RU",
  "label": "Russian Federation",
  "states": null
}, {
  "value": "RW",
  "label": "Rwanda",
  "states": null
}, {
  "value": "SA",
  "label": "Saudi Arabia",
  "states": null
}, {
  "value": "SB",
  "label": "Solomon Islands",
  "states": null
}, {
  "value": "SC",
  "label": "Seychelles",
  "states": null
}, {
  "value": "SD",
  "label": "Sudan",
  "states": null
}, {
  "value": "SE",
  "label": "Sweden",
  "states": null
}, {
  "value": "SG",
  "label": "Singapore",
  "states": null
}, {
  "value": "SH",
  "label": "Saint Helena",
  "states": null
}, {
  "value": "SI",
  "label": "Slovenia",
  "states": null
}, {
  "value": "SJ",
  "label": "Svalbard and Jan Mayen",
  "states": null
}, {
  "value": "SK",
  "label": "Slovakia",
  "states": null
}, {
  "value": "SL",
  "label": "Sierra Leone",
  "states": null
}, {
  "value": "SM",
  "label": "San Marino",
  "states": null
}, {
  "value": "SN",
  "label": "Senegal",
  "states": null
}, {
  "value": "SO",
  "label": "Somalia",
  "states": null
}, {
  "value": "SR",
  "label": "Suriname",
  "states": null
}, {
  "value": "ST",
  "label": "Sao Tome and Principe",
  "states": null
}, {
  "value": "SV",
  "label": "El Salvador",
  "states": null
}, {
  "value": "SY",
  "label": "Syrian Arab Republic",
  "states": null
}, {
  "value": "SZ",
  "label": "Swaziland",
  "states": null
}, {
  "value": "TC",
  "label": "Turks and Caicos Islands",
  "states": null
}, {
  "value": "TD",
  "label": "Chad",
  "states": null
}, {
  "value": "TF",
  "label": "French Southern Territories",
  "states": null
}, {
  "value": "TG",
  "label": "Togo",
  "states": null
}, {
  "value": "TH",
  "label": "Thailand",
  "states": null
}, {
  "value": "TJ",
  "label": "Tajikistan",
  "states": null
}, {
  "value": "TK",
  "label": "Tokelau",
  "states": null
}, {
  "value": "TL",
  "label": "Timor-Leste",
  "states": null
}, {
  "value": "TM",
  "label": "Turkmenistan",
  "states": null
}, {
  "value": "TN",
  "label": "Tunisia",
  "states": null
}, {
  "value": "TO",
  "label": "Tonga",
  "states": null
}, {
  "value": "TR",
  "label": "Turkey",
  "states": null
}, {
  "value": "TT",
  "label": "Trinidad and Tobago",
  "states": null
}, {
  "value": "TV",
  "label": "Tuvalu",
  "states": null
}, {
  "value": "TW",
  "label": "Taiwan",
  "states": null
}, {
  "value": "TZ",
  "label": "Tanzania",
  "states": null
}, {
  "value": "UA",
  "label": "Ukraine",
  "states": null
}, {
  "value": "UG",
  "label": "Uganda",
  "states": null
}, {
  "value": "UM",
  "label": "United States Minor Outlying Islands",
  "states": null
}, {
  "value": "US",
  "label": "United States",
  "states": [
      {
          "value": "AA",
          "label": "Armed Forces Americas (except Canada)"
      },
      {
          "value": "AE",
          "label": "Armed Forces"
      },
      {
          "value": "AK",
          "label": "Alaska"
      },
      {
          "value": "AL",
          "label": "Alabama"
      },
      {
          "value": "AP",
          "label": "Armed Forces Pacific"
      },
      {
          "value": "AR",
          "label": "Arkansas"
      },
      {
          "value": "AS",
          "label": "American Samoa"
      },
      {
          "value": "AZ",
          "label": "Arizona"
      },
      {
          "value": "CA",
          "label": "California"
      },
      {
          "value": "CO",
          "label": "Colorado"
      },
      {
          "value": "CT",
          "label": "Connecticut"
      },
      {
          "value": "DC",
          "label": "District of Columbia"
      },
      {
          "value": "DE",
          "label": "Delaware"
      },
      {
          "value": "FL",
          "label": "Florida"
      },
      {
          "value": "FM",
          "label": "Federated States of Micronesia"
      },
      {
          "value": "GA",
          "label": "Georgia"
      },
      {
          "value": "GU",
          "label": "Guam"
      },
      {
          "value": "HI",
          "label": "Hawaii"
      },
      {
          "value": "IA",
          "label": "Iowa"
      },
      {
          "value": "ID",
          "label": "Idaho"
      },
      {
          "value": "IL",
          "label": "Illinois"
      },
      {
          "value": "IN",
          "label": "Indiana"
      },
      {
          "value": "KS",
          "label": "Kansas"
      },
      {
          "value": "KY",
          "label": "Kentucky"
      },
      {
          "value": "LA",
          "label": "Louisiana"
      },
      {
          "value": "MA",
          "label": "Massachusetts"
      },
      {
          "value": "MD",
          "label": "Maryland"
      },
      {
          "value": "ME",
          "label": "Maine"
      },
      {
          "value": "MH",
          "label": "Marshall Islands"
      },
      {
          "value": "MI",
          "label": "Michigan"
      },
      {
          "value": "MN",
          "label": "Minnesota"
      },
      {
          "value": "MO",
          "label": "Missouri"
      },
      {
          "value": "MP",
          "label": "Northern Mariana Islands"
      },
      {
          "value": "MS",
          "label": "Mississippi"
      },
      {
          "value": "MT",
          "label": "Montana"
      },
      {
          "value": "NC",
          "label": "North Carolina"
      },
      {
          "value": "ND",
          "label": "North Dakota"
      },
      {
          "value": "NE",
          "label": "Nebraska"
      },
      {
          "value": "NH",
          "label": "New Hampshire"
      },
      {
          "value": "NJ",
          "label": "New Jersey"
      },
      {
          "value": "NM",
          "label": "New Mexico"
      },
      {
          "value": "NV",
          "label": "Nevada"
      },
      {
          "value": "NY",
          "label": "New York"
      },
      {
          "value": "OH",
          "label": "Ohio"
      },
      {
          "value": "OK",
          "label": "Oklahoma"
      },
      {
          "value": "OR",
          "label": "Oregon"
      },
      {
          "value": "PA",
          "label": "Pennsylvania"
      },
      {
          "value": "PR",
          "label": "Puerto Rico"
      },
      {
          "value": "PW",
          "label": "Palau"
      },
      {
          "value": "RI",
          "label": "Rhode Island"
      },
      {
          "value": "SC",
          "label": "South Carolina"
      },
      {
          "value": "SD",
          "label": "South Dakota"
      },
      {
          "value": "TN",
          "label": "Tennessee"
      },
      {
          "value": "TX",
          "label": "Texas"
      },
      {
          "value": "UT",
          "label": "Utah"
      },
      {
          "value": "VA",
          "label": "Virginia"
      },
      {
          "value": "VI",
          "label": "Virgin Islands"
      },
      {
          "value": "VT",
          "label": "Vermont"
      },
      {
          "value": "WA",
          "label": "Washington"
      },
      {
          "value": "WI",
          "label": "Wisconsin"
      },
      {
          "value": "WV",
          "label": "West Virginia"
      },
      {
          "value": "WY",
          "label": "Wyoming"
      }
  ]
}, {
  "value": "UY",
  "label": "Uruguay",
  "states": null
}, {
  "value": "UZ",
  "label": "Uzbekistan",
  "states": null
}, {
  "value": "VA",
  "label": "Vatican City",
  "states": null
}, {
  "value": "VC",
  "label": "Saint Vincent and the Grenadines",
  "states": null
}, {
  "value": "VE",
  "label": "Venezuela",
  "states": null
}, {
  "value": "VG",
  "label": "Virgin Islands, British",
  "states": null
}, {
  "value": "VI",
  "label": "Virgin Islands, U.S.",
  "states": null
}, {
  "value": "VN",
  "label": "Vietnam",
  "states": null
}, {
  "value": "VU",
  "label": "Vanuatu",
  "states": null
}, {
  "value": "WF",
  "label": "Wallis and Futuna",
  "states": null
}, {
  "value": "WS",
  "label": "Samoa",
  "states": null
}, {
  "value": "YE",
  "label": "Yemen",
  "states": null
}, {
  "value": "YT",
  "label": "Mayotte",
  "states": null
}, {
  "value": "ZA",
  "label": "South Africa",
  "states": null
}, {
  "value": "ZM",
  "label": "Zambia",
  "states": null
}, {
  "value": "ZW",
  "label": "Zimbabwe",
  "states": null
}];
