import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';
// import { Route, Switch } from 'react-router-dom';

import 'babel-polyfill';

// import { ACTIONS } from './store';
// import { fetchWiki } from './store/actions';

import './app.scss';
import './grid.css';

// import { PageContent, PageContainer, WikiPage, WelcomePage } from './components/page';
// import FAQ from './components/faq';
// // import Favorites from './components/favorites';
// import ShirtPage from './components/shirt-page';
// import Pay from './components/pay';
// import Search from './components/search';
// import OrderStatus from './components/order-status';
// import Header from './components/header';
// import { FAB } from './components/buttons';
import { PageContainer } from './components/page';
import Router from './router';

class App extends Component {

  // shouldComponentUpdate(nextProps) {
  //   if (this.props.wikiPageLoading !== nextProps.wikiPageLoading) return false;
  //   if (this.props.wikiImagesLoading !== nextProps.wikiImagesLoading) return false;
  //   if (this.props.bufferWikiPageLoading !== nextProps.bufferWikiPageLoading) return false;
  //   if (this.props.bufferWikiImagesLoading !== nextProps.bufferWikiImagesLoading) return false;
  //   return true;
  // }

  // handleFABClick = () => {
  //   const {
  //     wikiPageLoading,
  //     wikiImagesLoading,
  //     bufferWikiPageLoading,
  //     bufferWikiImagesLoading,
  //     saveBufferAsNewPage,
  //     history,
  //     bufferReady,
  //     fetchWiki,
  //     location
  //   } = this.props;

  //   // Nothing is currently loading in the main page
  //   if (!wikiPageLoading && !wikiImagesLoading && !bufferWikiPageLoading && !bufferWikiImagesLoading) {
  //     if (location.pathname === '/') {
  //       document.title = `WearWiki - Browse`;

  //       history.replace({
  //         pathname: '/browse',
  //       });
  //     } else {
  //       fetchWiki({
  //         bufferReady
  //       })
  //     }
  //     return;
  //   }

  //   // Buffer is only thing loading
  //   if (!wikiPageLoading && !wikiImagesLoading && (bufferWikiPageLoading || bufferWikiImagesLoading)) {
  //     saveBufferAsNewPage();
  //   }
  // }

  render() {
    const { theme } = this.props;
    
    return (
      <PageContainer theme={theme}>
        <Router />
        {/* <Switch>
          <Route path="/browse" component={Header} />
          <Route path="/checkout" component={null} />
          <Route path="/faq" component={Header} />
          <Route path="/order" component={Header} />
          <Route path="/search" component={Header} />
          <Route path="/terms" component={Header} />
          <Route path="/:pageTitle/:imageTitle" component={Header} />
          <Route path="/:pageTitle" component={Header} />
          <Route path="/" component={Header} exact />
        </Switch>
        <PageContent>
          <Switch>
            <Route path="/browse" component={WikiPage} />
            <Route path="/checkout" component={Pay} />
            <Route path="/faq" component={FAQ} />
            <Route path="/order" component={OrderStatus} />
            <Route path="/search" component={Search} />
            <Route path="/terms" component={Terms} />
            <Route path="/:pageTitle/:imageTitle" component={ShirtPage} />
            <Route path="/:pageTitle" component={WikiPage} />
            <Route path="/" component={WelcomePage} exact />
          </Switch>
        </PageContent>
        <Switch>
          {/* <Route path="/checkout" component={null} />
          <Route path="/search" component={null} /> */}
          {/* <Route path="/browse" render={(props) => <FAB {...props} getNewPage={this.handleFABClick} />} /> */}
          {/* <Route path="/:pageTitle/:imageTitle" component={null} /> */}
          {/* <Route path="/:pageTitle" render={(props) => <FAB {...props} getNewPage={this.handleFABClick} />} /> */}
          {/* <Route render={(props) => <FAB {...props} getNewPage={this.handleFABClick} showPopup />} /> 
        </Switch> */}
      </PageContainer>
    );
  }
}

// const mapStateToProps = (state, ownProps) => ({
//   theme: state.theme,
//   wikiPageLoading: state.wikiPageLoading,
//   wikiImagesLoading: state.wikiImagesLoading,
//   bufferWikiPageLoading: state.bufferWikiPageLoading,
//   bufferWikiImagesLoading: state.bufferWikiImagesLoading,
//   bufferReady: state.bufferReady,
// });

// const mapDispatchToProps = dispatch => {
//   return bindActionCreators({
//     togglePushUrl: () => ({
//       type: ACTIONS.TOGGLE_PUSH_URL,
//     }),
//     fetchWiki: fetchWiki,
//     saveBufferAsNewPage: () => ({
//       type: ACTIONS.SAVE_BUFFER_AS_NEW_PAGE,
//     }),
//   }, dispatch);
// }

const mapStateToProps = (state, ownProps) => ({
  theme: state.theme
});
const mapDispatchToProps = null;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
