import _ from "underscore";

const keywordBlacklist = [
  "logo",
  "logotype",
  "an audio speaker emitting sound waves",
  "copyright",
  "trademark"
];
const allowedLicenseCodes = [
  "pd",
  "cc-by",
  "cc0",
  "No restrictions",
  "OGL",
  "attribution",
  "Hungarian banknote"
];

/**
 * We want 200 dpi minimum of an image on a shirt.
 * We allow for a minimum perimeter of 20 inches,
 * so 5x5x5x5 or 2x8x8x2 are minimum examples.
 */
const imageMinimumWidthAndHeightCombo = 2000;
// These are just raw minimums, ^ is the real catching min.
const imageMinimumWidth = 1000;
const imageMinimumHeight = 1000;

function hasAValidImageExtension(imageName) {
  return (
    imageName.toLowerCase().indexOf("png") > -1 ||
    imageName.toLowerCase().indexOf("jpg") > -1 ||
    imageName.toLowerCase().indexOf("jpeg") > -1
  );
}

export const NO_TITLE = 'no title';

export function getTitleForPage(page) {
  return page.titles?.canonical ?? (page.title || page.canonicalTitle || NO_TITLE);
}

export function getValidWikipediaImagesFromImages(imageMetas) {
  return _.filter(imageMetas, imageMeta => {
    if (imageMeta.type !== "image") {
      return false;
    }

    // Reject images that are not png or jpg.
    if (
      !imageMeta.title ||
      !hasAValidImageExtension(imageMeta.title)
    ) {
      return false;
    }

    return true;
  });
}

export function filterAllowableImages(wikiImages) {
  return _.filter(wikiImages, wikiImage => {
    if (!wikiImage.info) {
      return false
    }

    // See if the image's resolution is something we can print on a shirt.
    if (
      wikiImage.dimensions.width < imageMinimumWidth ||
      wikiImage.dimensions.height < imageMinimumHeight ||
      wikiImage.dimensions.width + wikiImage.dimensions.height <
        imageMinimumWidthAndHeightCombo
    ) {
      return false;
    }

    // Check if the image's license is one we can use.
    if (!wikiImage.license) {
      return false
    }

    let licenseMatch = false;
    for (let i = 0; i < allowedLicenseCodes.length; i++) {
      if (wikiImage.license && wikiImage.license.includes(allowedLicenseCodes[i])) {
        licenseMatch = true;
      }
    }
    if (!licenseMatch) {
      return false
    }

    // Reject images if the description contains a blacklisted key word.
    for (let i = 0; i < keywordBlacklist.length; i++) {
      if (
        wikiImage.description &&
        wikiImage.description
          .toLowerCase()
          .includes(keywordBlacklist[i].toLowerCase())
      ) {
        return false;
      }
    }

    return true
  })
}

function html2text(html) {
  var tag = document.createElement('div');
  tag.innerHTML = html;
  return tag.innerText;
}

export class WikiImage {
  constructor(image) {
    this._image = image;
  }

  get info() {
    if (!this._image.imageinfo || !this._image.imageinfo[0]) return undefined;
    return this._image.imageinfo[0];
  }

  get metadata() {
    return this.info.extmetadata;
  }

  get dimensions() {
    return {
      width: this.info.width,
      height: this.info.height,
    };
  }

  get url() {
    return this.info.url;
  }

  get license() {
    if (!this.metadata.License) return undefined;
    return this.metadata.License.value;
  }

  get description() {
    if (!this.metadata.ImageDescription) return "Unknown";
    if (!this._description) {
      this._description = html2text(this.metadata.ImageDescription.value);
    }
    return this._description;
  }

  get title() {
    return this._image.title;
  }

  get canonicalTitle() {
    return this.info.canonicaltitle;
  }

  get author() {
    if (!this.metadata.Artist) return undefined;
    if (!this._author) {
      this._author = html2text(this.metadata.Artist.value);
    }
    return this._author;
  }

  get thumb() {
    return this.info.thumburl;
  }
}

/** Example wikipedia image list response:

{
  "revision": "831582974",
  "tid": "2534d6b4-cae9-11e8-a1e0-bc08dbfe60a4",
  "items": [
    {
      "section_id": 0,
      "type": "image",
      "showInGallery": true,
      "titles": {
        "canonical": "File:Eirmocephala_brachiata_4.jpg",
        "normalized": "File:Eirmocephala brachiata 4.jpg",
        "display": "File:Eirmocephala brachiata 4.jpg"
      },
      "thumbnail": {
        "source": "https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Eirmocephala_brachiata_4.jpg/320px-Eirmocephala_brachiata_4.jpg",
        "width": 320,
        "height": 210,
        "mime": "image/jpeg"
      },
      "original": {
        "source": "https://upload.wikimedia.org/wikipedia/commons/5/53/Eirmocephala_brachiata_4.jpg",
        "width": 1450,
        "height": 950,
        "mime": "image/jpeg"
      },
      "file_page": "https://commons.wikimedia.org/wiki/File:Eirmocephala_brachiata_4.jpg",
      "artist": {
        "html": "<a href=\"//commons.wikimedia.org/wiki/User:Franz_Xaver\" title=\"User:Franz Xaver\">Franz Xaver</a>"
      },
      "credit": "<span class=\"int-own-work\" lang=\"en\">Own work</span>",
      "license": {
        "type": "CC BY-SA 3.0",
        "code": "cc-by-sa-3.0",
        "url": "https://creativecommons.org/licenses/by-sa/3.0"
      },
      "description": {
        "html": "<p><i><a href=\"//commons.wikimedia.org/wiki/Eirmocephala_brachiata\" title=\"Eirmocephala brachiata\">Eirmocephala brachiata</a></i> (= <i>Vernonia brachiata</i>), Korbblütler (Asteraceae) - Costa Rica: Prov. Cartago, Orosi\n</p>",
        "text": "Eirmocephala brachiata (= Vernonia brachiata), Korbblütler (Asteraceae) - Costa Rica: Prov. Cartago, Orosi\n"
      }
    },
    {
      "section_id": 0,
      "type": "image",
      "showInGallery": true,
      "titles": {
        "canonical": "File:Eirmocephala_brachiata_1.jpg",
        "normalized": "File:Eirmocephala brachiata 1.jpg",
        "display": "File:Eirmocephala brachiata 1.jpg"
      },
      "thumbnail": {
        "source": "https://upload.wikimedia.org/wikipedia/commons/thumb/6/66/Eirmocephala_brachiata_1.jpg/320px-Eirmocephala_brachiata_1.jpg",
        "width": 320,
        "height": 197,
        "mime": "image/jpeg"
      },
      "original": {
        "source": "https://upload.wikimedia.org/wikipedia/commons/6/66/Eirmocephala_brachiata_1.jpg",
        "width": 1300,
        "height": 800,
        "mime": "image/jpeg"
      },
      "file_page": "https://commons.wikimedia.org/wiki/File:Eirmocephala_brachiata_1.jpg",
      "artist": {
        "html": "<a href=\"//commons.wikimedia.org/wiki/User:Franz_Xaver\" title=\"User:Franz Xaver\">Franz Xaver</a>"
      },
      "credit": "<span class=\"int-own-work\" lang=\"en\">Own work</span>",
      "license": {
        "type": "CC BY-SA 3.0",
        "code": "cc-by-sa-3.0",
        "url": "https://creativecommons.org/licenses/by-sa/3.0"
      },
      "description": {
        "html": "<p><i><a href=\"//commons.wikimedia.org/wiki/Eirmocephala_brachiata\" title=\"Eirmocephala brachiata\">Eirmocephala brachiata</a></i> (= <i>Vernonia brachiata</i>), Korbblütler (Asteraceae) - Costa Rica: Prov. Cartago, Orosi\n</p>",
        "text": "Eirmocephala brachiata (= Vernonia brachiata), Korbblütler (Asteraceae) - Costa Rica: Prov. Cartago, Orosi\n"
      }
    }
  ]
}
*/
