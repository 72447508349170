import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Elements } from 'react-stripe-elements';
import { bindActionCreators } from 'redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import './pay.scss';

import BuildOrder from '../build-order';
import Checkout from '../checkout';
import Confirmation from '../confirmation';
import CustomerInformation from '../customer-information';
import Shipping from '../shipping';
import {
  validateCustomerInformation,
  validateOrder,
  validateProcessedImagesId,
  validateSelectedShippingOption,
  validateStripeToken
} from '../../util/orders';

class Pay extends Component {
  static propTypes = {
    customerInformation: PropTypes.object.isRequired,
    makingOrder: PropTypes.bool.isRequired,
    orders: PropTypes.array.isRequired,
    processedImagesId: PropTypes.string.isRequired,
    selectedShippingOption: PropTypes.object.isRequired,
    stripeToken: PropTypes.string.isRequired
  }

  state = {
    redirect: false
  }

  ensureOrderHasBeenBuilt = props => {
    const validationError = validateOrder(props.orders);
  
    if (validationError) {
      this.setState({
        redirect: '/checkout'
      });
      return validationError;
    }

    const processedImagesIdValidationError = validateProcessedImagesId(props.processedImagesId);
    if (processedImagesIdValidationError) {
      this.setState({
        redirect: '/checkout'
      });
      return processedImagesIdValidationError;
    }
  
    return false;
  }
  
  ensureCustomerInformationCompleted = props => {
    const invalidStateForStage = this.ensureOrderHasBeenBuilt(props);
    if (invalidStateForStage) {
      return invalidStateForStage;
    }
  
    const customerInformationInvalid = validateCustomerInformation(props.customerInformation);
    if (customerInformationInvalid) {
      this.setState({
        redirect: '/checkout/1'
      });
      return customerInformationInvalid;
    }
  
    const stripeTokenInvalid = validateStripeToken(props.stripeToken);
    if (stripeTokenInvalid) {
      this.setState({
        redirect: '/checkout/1'
      });
      return stripeTokenInvalid;
    }
  
    return false;
  }
  
  ensureShippingHasBeenCompleted = props => {
    const invalidStateForStage = this.ensureCustomerInformationCompleted(props);
    if (invalidStateForStage) {
      return invalidStateForStage;
    }
  
    const selectedShippingOptionInvalid = validateSelectedShippingOption(props.selectedShippingOption);
    if (selectedShippingOptionInvalid) {
      this.setState({
        redirect: '/checkout/2'
      });
      return selectedShippingOptionInvalid;
    }
  
  
    return false;
  }
  
  ensureCheckoutHasBeenCompleted = props => {
    const invalidStateForStage = this.ensureShippingHasBeenCompleted(props);
    if (invalidStateForStage) {
      console.log('redirecting');
      return invalidStateForStage;
    }
  
    // Here we want to ensure that the user has only completed
    // that checkout once for what they saw.
  }

  ensureRouteIsAllowed = props => {
    const routePathname = props.location.pathname;

    let redirected = false
    if (routePathname === '/checkout/1') {
      redirected = this.ensureOrderHasBeenBuilt(props);
    } else if (routePathname === '/checkout/2') {
      redirected = this.ensureCustomerInformationCompleted(props);
    } else if (routePathname === '/checkout/3') {
      redirected = this.ensureShippingHasBeenCompleted(props);
    } else if (routePathname === '/checkout/4') {
      redirected = this.ensureCheckoutHasBeenCompleted(props);
    }

    if (!redirected && this.state.redirect) {
      this.setState({
        redirect: false
      });
    }
  }

  componentWillMount() {
    this.ensureRouteIsAllowed(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.pathname !== this.props.location.pathname) {
      this.ensureRouteIsAllowed(nextProps);
    }
  }

  render() {
    const { redirect } = this.state;
    const { match } = this.props;

    if (redirect) {
      return <Redirect to={redirect}/>;
    }

    return (
      <Elements>
        <div className="pay-container">
          <div className="pay">
            <Switch>
              {/* Note: these are tightly coupled with componentWillReceiveProps. */}
              <Route exact path={`${match.url}/1`} component={CustomerInformation} onEnter={this.ensureOrderHasBeenBuilt}/>
              <Route exact path={`${match.url}/2`} component={Shipping} />
              <Route exact path={`${match.url}/3`} component={Checkout} />
              <Route exact path={`${match.url}/4`} component={Confirmation} />
              <Route exact path={`${match.url}`} component={BuildOrder} />
              <Redirect to="/" />
            </Switch>
          </div>
        </div>
      </Elements>
    );
  }
}

const mapStateToProps = state => ({
  customerInformation: state.customerInformation,
  makingOrder: state.makingOrder,
  orders: state.orders,
  processedImagesId: state.processedImagesId,
  selectedShippingOption: state.selectedShippingOption,
  stripeToken: state.stripeToken
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Pay);
