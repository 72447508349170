import React, { Component } from 'react';

import './toggle.scss'

class Toggle extends Component {
  render() {
    const { id, value, onChange, onBlur, label, flex, name, autocomplete } = this.props;
    return (
      <div className={`toggle input ${flex ? 'flex' : ''}`}>
        <label htmlFor={id} className={value || value === 0 ? 'value' : ''}>
          <div className="togglebox">
            <input
              type="checkbox"
              id={id}
              value={value}
              checked={value}
              onChange={onChange}
              onBlur={onBlur}
              name={name}
              autoComplete={autocomplete}
            />
            <span className="slider round"></span>
          </div>
          <div className="description">
            {label}
          </div>
        </label>
      </div>
    );
  }
}

export default Toggle;