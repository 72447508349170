import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import Input from '../inputs';

import ACTIONS from '../../store/actions';
import { countries } from '../../constants';
import { validateCustomerInformation } from '../../util/orders';

import './customer-information.scss';
import './react-floating-label-input.css';
import './stripe-styling.css';

class CustomerInformation extends Component {
  static propTypes = {
    customerInformation: PropTypes.object.isRequired,
    setShippingOptionsLoaded: PropTypes.func.isRequired,
    subtotal: PropTypes.number.isRequired,
    updateStripeToken: PropTypes.func.isRequired
  }

  state = {
    errorContinuingWithOrder: null,
    loadingStripe: false
  }

  componentDidMount() {
    document.title = `WearWiki - Checkout Information`;
  }

  handleInputChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      errorContinuingWithOrder: null
    });

    this.props.updateCustomerInformation({
      [name]: value
    });
  }

  handleInputUpdate = (name, event) => {
    this.setState({
      errorContinuingWithOrder: null
    });

    this.props.updateCustomerInformation({
      [name]: event.target.value
    });
  }

  selectShippingCountry = newCountry => {
    this.setState({
      errorContinuingWithOrder: null
    });

    this.props.updateCustomerInformation({
      shippingCountry: newCountry.value,
      shippingCountrySelected: newCountry
    });
  }

  selectShippingRegion = newRegion => {
    this.setState({
      errorContinuingWithOrder: null
    });

    this.props.updateCustomerInformation({
      shippingRegion: newRegion.value,
      shippingRegionSelected: newRegion
    });
  }

  handleValidate = async (e) => {
    e.preventDefault();

    const validationError = validateCustomerInformation(this.props.customerInformation);

    if (validationError) {
      this.setState({
        errorContinuingWithOrder: validationError
      });

      return;
    }

    this.setState({
      loadingStripe: true
    });

    // Clear any past shipping information.
    this.props.setShippingOptionsLoaded(false);

    // https://stripe.com/docs/testing#cards
    // 4242424242424242
    // 4242 4242 4242 4242

    // To create a disputed transaction:
    // 4000 0000 0000 0259

    const { token, error } = await this.props.stripe.createToken();

    if (error) {
      this.setState({
        errorContinuingWithOrder: error.message,
        loadingStripe: false
      });
      return;
    }

    if (!token || !token.id) {
      this.setState({
        errorContinuingWithOrder: 'Unable to verify your card. Please re-enter or refresh and try again.',
        loadingStripe: false
      });
      return;
    }

    this.props.updateStripeToken(`${token.id}`);

    this.setState({
      loadingStripe: false
    });

    this.props.history.push('/checkout/2');
  }

  render() {
    const {
      errorContinuingWithOrder,
      loadingStripe
    } = this.state;

    const {
      email,
        
      shippingName,
      shippingAddress1,
      shippingAddress2,
      shippingZip,
      shippingCity,
      shippingCountrySelected,
      shippingRegionSelected
    } = this.props;

    return (
      <div className="ci">
        <Link
          className="link-button icon-left m0"
          to="/checkout"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path className="icon" d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"/></svg>
          Back to Bag
        </Link>
        <h1>Billing &amp; Shipping Information</h1>
        <form className="ci-container">
          <h2>Shipping Information</h2>

          <Input
            id="shippingName"
            label="Name"
            onChange={event => this.handleInputUpdate('shippingName', event)}
            value={shippingName}
            name="name"
            autocomplete="name"
          />
          <Input
            id="email"
            label="Email"
            onChange={event => this.handleInputUpdate('email', event)}
            value={email}
          />
          <Input
            id="shippingAddress1"
            label="Shipping address"
            onChange={event => this.handleInputUpdate('shippingAddress1', event)}
            value={shippingAddress1}
          />
          <Input
            id="shippingAddress2"
            label="Apartment, suite, unit, etc."
            onChange={event => this.handleInputUpdate('shippingAddress2', event)}
            value={shippingAddress2}
          />
          <div className="flex-row city-zip">
            <Input
              id="shippingCity"
              label="City"
              onChange={event => this.handleInputUpdate('shippingCity', event)}
              value={shippingCity}
            />
            <Input
              id="shippingZip"
              label="Zip"
              onChange={event => this.handleInputUpdate('shippingZip', event)}
              value={shippingZip}
            />
          </div>
          <div className="ci-input-item">
            <div className="ci-item-label">
              Country &amp; Region
            </div>
            <Select
              value={shippingCountrySelected}
              onChange={this.selectShippingCountry}
              options={countries}
              name="country"
              menuPlacement="auto"
            />
            {shippingCountrySelected && shippingCountrySelected.states && shippingCountrySelected.states.length > 0 && (
              <div className="ci-region-container">
                <Select
                  value={shippingRegionSelected}
                  onChange={this.selectShippingRegion}
                  options={shippingCountrySelected.states}
                  menuPlacement="auto"
                />
              </div>
            )}
          </div>

          <div className="ci-section">
            <h2>Payment Information</h2>
            <CardElement
              style={{
                base: {
                  fontSize: '16px'
                }
              }}
            />
          </div>
          {errorContinuingWithOrder && (
            <div className="pay-error-message">
              {errorContinuingWithOrder}
            </div>
          )}
          <div className="ci-button-container">
            <button className="button" onClick={this.handleValidate}>
              {loadingStripe ? 'Working...' : 'Shipping options'}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...state.customerInformation,
  customerInformation: state.customerInformation,
  subtotal: state.subtotal
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    setShippingOptionsLoaded: shippingOptionsLoaded => ({
      type: ACTIONS.SET_SHIPPING_OPTIONS_LOADED,
      shippingOptionsLoaded
    }),
    updateCustomerInformation: customerInformationUpdate => ({
      type: ACTIONS.UPDATE_CUSTOMER_INFORMATION,
      customerInformationUpdate
    }),
    updateStripeToken: stripeToken => ({
      type: ACTIONS.SET_STRIPE_TOKEN,
      stripeToken
    })
  }, dispatch);
}

const StripeInjectedCustomerInformation = injectStripe(CustomerInformation);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StripeInjectedCustomerInformation);
