import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import { ACTIONS } from '../../store';
import WebService from '../../web-service';
import { validateSelectedShippingOption } from '../../util/orders';

import './shipping.scss';

class Shipping extends Component {
  static propTypes = {
    customerInformation: PropTypes.object.isRequired,
    orders: PropTypes.array.isRequired,
    selectedShippingOption: PropTypes.object.isRequired,
    selectShippingOrder: PropTypes.func.isRequired,
    setShippingOptions: PropTypes.func.isRequired,
    shippingOptions: PropTypes.array.isRequired,
    shippingOptionsAvailable: PropTypes.bool.isRequired,
    shippingOptionsLoaded: PropTypes.bool.isRequired
  }

  state = {
    errorContinuingWithOrder: null
  }

  componentDidMount = () => {
    if (!this.props.shippingOptionsLoaded) {
      this.loadShippingOptions();
    }

    document.title = `WearWiki - Shipping Options`;
  }

  loadShippingOptions = async () => {
    this.mostRecentShippingOptionsVersion++;
    const currentRequest = this.mostRecentShippingOptionsVersion;

    let shippingOptions;
    try {
      shippingOptions = await WebService.getShippingOptions(this.props.customerInformation, this.props.orders);
    } catch(e) {
      if (currentRequest === this.mostRecentShippingOptionsVersion) {
        this.setState({
          errorContinuingWithOrder: e.message
        });
        return false;
      }
    }

    if (currentRequest !== this.mostRecentShippingOptionsVersion) {
      // If we're no longer on display just do nothing with the information.
      return;
    }

    this.setState({
      errorContinuingWithOrder: null
    });

    this.props.setShippingOptions(shippingOptions);
  }

  validate = () => {
    if (!this.props.shippingOptionsLoaded) {
      this.setState({
        errorContinuingWithOrder: 'Please wait until your shipping options have loaded.'
      });
      return false;
    }

    if (!this.props.shippingOptionsAvailable) {
      this.setState({
        errorContinuingWithOrder: 'Sorry you cannot proceed until we\'ve got a shipping method for you.'
      });
      return false;
    }

    const selectedShippingOptionInvalid = validateSelectedShippingOption(this.props.selectedShippingOption);
    if (selectedShippingOptionInvalid) {
      this.setState({
        errorContinuingWithOrder: selectedShippingOptionInvalid
      });
      return false;
    }

    return true;
  }

  handleValidate = (e) => {
    const valid = this.validate();
    
    if (!valid) {
      e.preventDefault();
    }
  }

  // If a user goes back to customer information and forward quickly we might be making two data
  // queries at once. This safe guards so that only the most recent request has the ability
  // to change the state;
  mostRecentShippingOptionsVersion = 0;

  render() {
    const {
      selectShippingOrder,
      selectedShippingOption,
      shippingOptionsAvailable,
      shippingOptions,
      shippingOptionsLoaded
    } = this.props;

    const { errorContinuingWithOrder } = this.state;

    return (
      <div className="shipping">
        <Link
          className="link-button icon-left m0"
          to="/checkout/1"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path className="icon" d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"/></svg>
          Back to Billing
        </Link>
        <h1>Shipping Options</h1>
        {!shippingOptionsLoaded && !errorContinuingWithOrder && (
          <div className="shipping-options-loading">
            <div className="shipping-options-loading-message">
              Loading shipping options...
            </div>
          </div>
        )}
        {shippingOptionsLoaded && !shippingOptionsAvailable && (
          <div>
            Sorry! We can't ship to you :'(
            <br />
            Please try another address.
          </div>
        )}
        {shippingOptionsLoaded && shippingOptionsAvailable && !errorContinuingWithOrder && (
          <React.Fragment>
            <div className="shipping-description">
              It takes 2-7 days for us to print your shirt, then we ship it.
              <br />
              Nice! Your address qualifies for free shipping.
            </div>
            <div className="shipping-description">
              
            </div>
            <div className="all-radio-options">
              {shippingOptions.map((option, index) => (
                <div
                  className={`shipping-option-container ${selectedShippingOption.id === option.id ? 'checked' : ''}`}
                  key={`${index}_${option.id}`}
                >
                  <label>
                    <input
                      className="shipping-option-input"
                      key={option.id}
                      name="shippingOption"
                      placeholder="shippingOption"
                      type="radio"
                      checked={selectedShippingOption.id === option.id}
                      onChange={() => selectShippingOrder(option)}
                    />
                    <div className="shipping-option-label">
                      <div className="shipping-option-name">
                        {option.name}
                      </div>
                      <div className="shipping-option-rate">
                        {!option.isCoveredFreeShipping && (option.currency === 'USD' ? `$${option.rate}` : `${option.rate} ${option.currency}`)}
                        {option.isCoveredFreeShipping && 'FREE'}
                      </div>
                    </div>
                  </label>
                </div>
              ))}
            </div>
            <div className="shipping-checkout-button-container">
              <Link className="button" to="/checkout/3" onClick={this.handleValidate}>Continue</Link>
            </div>
          </React.Fragment>
        )}
        {errorContinuingWithOrder && (
          <div className="pay-error-message">
            {errorContinuingWithOrder}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  customerInformation: state.customerInformation,
  orders: state.orders,
  selectedShippingOption: state.selectedShippingOption,
  shippingOptions: state.shippingOptions,
  shippingOptionsAvailable: state.shippingOptionsAvailable,
  shippingOptionsLoaded: state.shippingOptionsLoaded,
  subtotal: state.subtotal
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    selectShippingOrder: newShippingOption => ({
      type: ACTIONS.SELECT_SHIPPING_OPTION,
      newShippingOption
    }),
    setShippingOptions: newShippingOptions => ({
      type: ACTIONS.SET_SHIPPING_OPTIONS,
      newShippingOptions
    })
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Shipping);
