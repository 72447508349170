import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './button.scss';

import Tooltip from '../tooltip';

class FAB extends Component {
  constructor(props) {
    super(props);
    this.state = {
      right: 10000,
      bottom: 10000,
      visible: false,
      hideTooltip: false,
      pressed: false,
      fabClicks: JSON.parse(localStorage.getItem("fab-click-count")),
    };

    this.fireInterval = null;
  }

  componentDidMount() {
    window.setTimeout(() => {
      this.setCoords();
    }, 10);
    window.addEventListener("resize", this.setCoords);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.fabClicks !== prevState.fabClicks) {
      localStorage.setItem("fab-click-count", this.state.fabClicks);
    }
    if (this.props !== prevProps) {
      if (this.props.wikiImages.length) {
        window.setTimeout(() => {
          this.setState({
            hideTooltip: false
          });
        }, 500);
      }
      this.setCoords();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setCoords); 
  }

  setCoords = () => {
    if (this.props.fabGhostRef && this.props.fabGhostRef.current) {
      const box = this.props.fabGhostRef.current.getBoundingClientRect();
      this.setState({
        right: -(window.innerWidth - box.x - box.width),
        bottom: -(window.innerHeight - (box.y + box.height)),
        visible: true,
        ghost: true,
      });    
    } else {
      this.setState({
        right: -30,
        bottom: -30,
        visible: true,
        ghost: false,
      });
    }
  }

  spawnFire = (deg, dist, dur) => {
    const btn = document.getElementById("fire-button");
    const btnBox = btn.getBoundingClientRect();
    let fire = document.createElement("span");
    fire.innerHTML = "🔥";
    fire.className = "fire";
    const spawn = (btnBox.width / 2) * 0.5;
    const originX = btnBox.x + (btnBox.width / 2) - 10;
    const originY = btnBox.y + (btnBox.height / 2) - 10;
    const spawnX = originX + (Math.cos(deg) * spawn);
    const spawnY = originY + (Math.sin(deg) * spawn);
    const destX = originX + (Math.cos(deg) * dist);
    const destY = originY + (Math.sin(deg) * dist);

    btn.parentNode.insertBefore(fire, btn.nextSibling);
    fire.style.left = `${spawnX}px`;
    fire.style.top = `${spawnY}px`;
    fire.style.transform = "translate(0px, 0px)";
    fire.style.opacity = 1;
    fire.style.transition = `all ${dur}ms ease-out`;

    window.setTimeout(() => {
      fire.style.opacity = 0;
      fire.style.transform = `translate(${destX - spawnX}px, ${destY - spawnY}px)`;
    }, 10);

    window.setTimeout(() => {
      fire.parentNode.removeChild(fire);
    }, dur + 10);
  }

  startPress = (e) => {
    this.setState({
      pressed: true,
    });

    window.addEventListener('mouseup', this.handleClick);
  }

  handleClick = (e, removeListener) => {
    window.removeEventListener('mouseup', this.handleClick);
    e.preventDefault();
    const ringSteps = 18;
    const step = Math.PI * 2 / ringSteps;
    const btn = document.getElementById("fire-button");
    const btnBox = btn.getBoundingClientRect();
    for (let i = 0; i < ringSteps; i++) {
      const deg = (step * i) + (Math.random() * step);
      const dist = btnBox.width + (Math.random() * 20);
      this.spawnFire(deg, dist, 1000);
    }

    /*
      Increment FAB click counter in LocalStorage and
      then fetch a new wikipedia page
    */
    this.setState({
      fabClicks: this.state.fabClicks + 1,
      pressed: false,
      hideTooltip: true,
    });
    this.props.getNewPage();
  }

  hideTooltip = () => {
    this.setState({
      hideTooltip: true
    });
  }

  renderTooltip() {
    const { fabClicks } = this.state;
    const isGhost = this.props.fabGhostRef && this.props.fabGhostRef.current;
    if ((fabClicks >= 3 || this.state.hideTooltip) && !isGhost) return;


    let content = {
      title: isGhost ? "Get Started" : "Welcome to WearWiki",
      subtitle: `Click on the 🔥 to see ${isGhost ? 'some' : 'more'} shirts`
    };
    if (!isGhost && fabClicks === 1) {
      content = {
        title: "Keep hitting 🔥 to get more shirts",
        subtitle: "The chances of seeing the same set of shirts is 1 in 45,257,592"
      };
    } else if (!isGhost && fabClicks === 2) {
      content = {
        title: "Now you're getting it",
        subtitle: "We'll leave you here, good luck!"
      };
    }

    return (
      <Tooltip position={this.props.fabGhostRef && this.props.fabGhostRef.current ? "right" : "left"}>
        <h1>{content.title}</h1>
        <p>{content.subtitle}</p>
        {!isGhost && <button onClick={this.hideTooltip} className="link-button">Dismiss</button>}
      </Tooltip>
    );
  }

  render() {
    const { pressed } = this.state;

    return (
      <span
        className="fab-container"
        id="fire-button"
        style={{
          transform: `translate(${this.state.right}px, ${this.state.bottom}px)`,
          display: this.state.visible ? "block" : "none",
          transition: this.state.ghost ? null : "transform 1s ease",
        }}
      >
        <button
          className={`fab ${pressed ? 'pressed' : ''}`}
          onTouchStart={(e) => this.startPress(e)}
          onTouchEnd={(e) => this.handleClick(e)}
          onMouseDown={(e) => this.startPress(e)}
          onMouseUp={(e) => this.handleClick(e)}
          onDoubleClick={(e) => {e.stopPropagation(); e.preventDefault();}}
        >
          <span role="img" aria-label="Fire">🔥</span>
        </button>
        {this.renderTooltip()}
      </span>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  fabGhostRef: state.fabGhostRef,
  wikiImages: state.wikiImages,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FAB);