import React, { Component, Fragment } from "react";
import _ from "underscore";
import { WikiPageData } from "../data-getters";
import { WikiListItem, WikiImageCard } from "../wiki-layouts";

import "./landing.scss";

const MAX_ARTICLES_TO_LOAD = 9;

class LandingDaily extends Component {
  constructor(props) {
    super(props);

    this.state = {
      daily: null,
      expanded: false
    };
  }
  componentDidMount() {
    const date = new Date();
    const year = date.getFullYear();
    const month =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
    const day = date.getDate();

    fetch(
      `https://en.wikipedia.org/api/rest_v1/feed/featured/${year}/${month}/${day}`
    )
      .then(response => response.json())
      .then(daily => {
        this.setState({ daily: daily });
      });
  }

  render() {
    const { daily, expanded } = this.state;
    if (!daily) return null;

    const { image, tfa, mostread } = daily;

    return (
      <Fragment>
        <div className="daily-container">
          <WikiImageCard image={image} />
        </div>

        {tfa && (
          <div className="daily-container">
            <div className="trending-articles">
              <div className="daily-tag">Article of the Day</div>
              <WikiPageData
                pageTitle={tfa.title ?? 'no title'}
                contents={data => <WikiListItem wiki={data} />}
                loader={() => <div>Loading...</div>}
              />
            </div>
          </div>
        )}

        {mostread?.articles && (
          <div className="daily-container no-padding-top">
            <div className="trending-articles">
              <div className="daily-tag">Trending Articles of the Day</div>
              {mostread.articles.map((article, index) => {
                if (!expanded && index > MAX_ARTICLES_TO_LOAD) return null;
                if (!article) return null;
                return (
                  <WikiPageData
                    key={article?.title ?? index}
                    pageTitle={article?.title ?? 'no title'}
                    contents={data => {
                      return <WikiListItem wiki={data} index={index} />;
                    }}
                    loader={() => <div>Loading...</div>}
                  />
                );
              })}
              <div className="wiki-list-item">
                <button
                  className="link-button"
                  style={{ margin: 0 }}
                  onClick={() => this.setState({ expanded: !expanded })}
                >
                  {expanded ? "View Less" : "View More"}
                </button>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default LandingDaily;
