module.exports.getImageDisplayName = function(meta) {
  let title = 'Untitled';
  if (meta && meta.description && meta.description.text) {
    title = meta.description.text;
  }
  return title;
}

module.exports.getImageAuthor = function(meta) {
  let author;
  if (meta?.artist?.name) {
    author = meta.artist.name;
  } else if (meta?.artist?.html) {
    if (meta.artist.html.toLowerCase().includes('unknown')) {
      author = 'Unknown';
    } else {
      // TODO TODO TODO
      // TODO: Setting inner html is a real potential xss attack point.
      // Also regexing html: https://stackoverflow.com/questions/1732348/regex-match-open-tags-except-xhtml-self-contained-tags
      let tempDiv = document.createElement('div');
      tempDiv.innerHTML = meta.artist.html;
      author = meta.artist.html.replace(/<(?:.|\n)*?>/gm, '');
    }
  } else {
    author = 'Unknown';
  }
  return author
}

/** Example wikipedia image list response:

{
  "revision": "831582974",
  "tid": "2534d6b4-cae9-11e8-a1e0-bc08dbfe60a4",
  "items": [
    {
      "section_id": 0,
      "type": "image",
      "showInGallery": true,
      "titles": {
        "canonical": "File:Eirmocephala_brachiata_4.jpg",
        "normalized": "File:Eirmocephala brachiata 4.jpg",
        "display": "File:Eirmocephala brachiata 4.jpg"
      },
      "thumbnail": {
        "source": "https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Eirmocephala_brachiata_4.jpg/320px-Eirmocephala_brachiata_4.jpg",
        "width": 320,
        "height": 210,
        "mime": "image/jpeg"
      },
      "original": {
        "source": "https://upload.wikimedia.org/wikipedia/commons/5/53/Eirmocephala_brachiata_4.jpg",
        "width": 1450,
        "height": 950,
        "mime": "image/jpeg"
      },
      "file_page": "https://commons.wikimedia.org/wiki/File:Eirmocephala_brachiata_4.jpg",
      "artist": {
        "html": "<a href=\"//commons.wikimedia.org/wiki/User:Franz_Xaver\" title=\"User:Franz Xaver\">Franz Xaver</a>"
      },
      "credit": "<span class=\"int-own-work\" lang=\"en\">Own work</span>",
      "license": {
        "type": "CC BY-SA 3.0",
        "code": "cc-by-sa-3.0",
        "url": "https://creativecommons.org/licenses/by-sa/3.0"
      },
      "description": {
        "html": "<p><i><a href=\"//commons.wikimedia.org/wiki/Eirmocephala_brachiata\" title=\"Eirmocephala brachiata\">Eirmocephala brachiata</a></i> (= <i>Vernonia brachiata</i>), Korbblütler (Asteraceae) - Costa Rica: Prov. Cartago, Orosi\n</p>",
        "text": "Eirmocephala brachiata (= Vernonia brachiata), Korbblütler (Asteraceae) - Costa Rica: Prov. Cartago, Orosi\n"
      }
    },
    {
      "section_id": 0,
      "type": "image",
      "showInGallery": true,
      "titles": {
        "canonical": "File:Eirmocephala_brachiata_1.jpg",
        "normalized": "File:Eirmocephala brachiata 1.jpg",
        "display": "File:Eirmocephala brachiata 1.jpg"
      },
      "thumbnail": {
        "source": "https://upload.wikimedia.org/wikipedia/commons/thumb/6/66/Eirmocephala_brachiata_1.jpg/320px-Eirmocephala_brachiata_1.jpg",
        "width": 320,
        "height": 197,
        "mime": "image/jpeg"
      },
      "original": {
        "source": "https://upload.wikimedia.org/wikipedia/commons/6/66/Eirmocephala_brachiata_1.jpg",
        "width": 1300,
        "height": 800,
        "mime": "image/jpeg"
      },
      "file_page": "https://commons.wikimedia.org/wiki/File:Eirmocephala_brachiata_1.jpg",
      "artist": {
        "html": "<a href=\"//commons.wikimedia.org/wiki/User:Franz_Xaver\" title=\"User:Franz Xaver\">Franz Xaver</a>"
      },
      "credit": "<span class=\"int-own-work\" lang=\"en\">Own work</span>",
      "license": {
        "type": "CC BY-SA 3.0",
        "code": "cc-by-sa-3.0",
        "url": "https://creativecommons.org/licenses/by-sa/3.0"
      },
      "description": {
        "html": "<p><i><a href=\"//commons.wikimedia.org/wiki/Eirmocephala_brachiata\" title=\"Eirmocephala brachiata\">Eirmocephala brachiata</a></i> (= <i>Vernonia brachiata</i>), Korbblütler (Asteraceae) - Costa Rica: Prov. Cartago, Orosi\n</p>",
        "text": "Eirmocephala brachiata (= Vernonia brachiata), Korbblütler (Asteraceae) - Costa Rica: Prov. Cartago, Orosi\n"
      }
    }
  ]
}
*/
