import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import { ACTIONS } from '../../store';
import WebService from '../../web-service';

import './confirmation.css';

import ShirtLoader from '../shirt-loader';

class Confirmation extends Component {
  static propTypes = {
    clearOrders: PropTypes.func.isRequired,
    customerInformation: PropTypes.object.isRequired,
    processedImagesId: PropTypes.string.isRequired,
    orders: PropTypes.array.isRequired,
    selectedShippingOption: PropTypes.object.isRequired,
    setMakingOrder: PropTypes.func.isRequired,
    stripeToken: PropTypes.string.isRequired,
    totalPrice: PropTypes.number.isRequired
  }

  state = {
    orderError: false,
    orderConfirmed: false,
    possiblyCharged: false,
    takingALongTimeWithOrder: false,
    trackingUrl: ''
  };

  componentDidMount() {
    this.makeTShirtOrder();
  }

  makeTShirtOrder = async () => {
    this.props.setMakingOrder(true);

    this.setState({
      takingALongTimeWithOrder: false,
    })

    this.orderVersion++;
    const thisOrderVersion = this.orderVersion;
    setTimeout(() => {
      if (this.orderVersion === thisOrderVersion) {
        this.setState({
          takingALongTimeWithOrder: true
        });
      }
    }, 5000);

    try {
      const customerId = await WebService.makeOrder(
        this.props.orders,
        this.props.customerInformation,
        this.props.selectedShippingOption,
        this.props.totalPrice,
        this.props.processedImagesId,
        this.props.stripeToken
      );
      this.props.setMakingOrder(false);

      const baseURL = `${window.location.origin}/`;
      const trackingUrl = `${baseURL}order?id=${customerId}`;

      // Clear the orders to ensure the user doens't try to order the same thing again.
      this.props.clearOrders();

      this.setState({
        orderConfirmed: true,
        orderError: false,
        possiblyCharged: false,
        trackingUrl
      });
    } catch (e) {
      this.props.setMakingOrder(false);
      console.log('Error w/ checkout', e);
      this.setState({
        orderConfirmed: true,
        orderError: e.message,
        possiblyCharged: true
      });
    }
  }

  orderVersion = 0;

  render() {
    const { orderError, orderConfirmed, possiblyCharged, takingALongTimeWithOrder, trackingUrl } = this.state;
    const { customerInformation } = this.props;

    return (
      <div>
        {orderConfirmed && orderError && !possiblyCharged &&(
          <Link
            className="link-button icon-left m0"
            to="/checkout/1"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path className="icon" d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"/></svg>
            Back to Billing
          </Link>
        )}
        <h1>Confirmation</h1>
        {!orderConfirmed && (
          <div className="confirmation-confirmed-message">
            <div className="confirmation-loading">
              Getting your shirt...
            </div>
            {takingALongTimeWithOrder && (
              <div className="confirmation-loading">
                Processing images... almost done...
              </div>
            )}
            <ShirtLoader count={1} />
          </div>
        )}
        {orderConfirmed && orderError && (
          <div className="confirmation-confirmed-message">
            <div className="confirmation-message-inner">
              Uh oh. Something went wrong performing the purchase request.
            </div>
            <div className="confirmation-message-inner confirmation-error-message">
              {orderError}
            </div>
          </div>
        )}
        {orderConfirmed && !orderError && (
          <React.Fragment>
            <div
              className="confirmation-confirmed-message"
            >
              <div className="confirmation-message-inner">
                Boom.
              </div>
              <div className="confirmation-message-inner">
                Your new shirt is on its way to the printing press and then it'll be shipped to your door.
                A confirmation email with a tracking link is being sent to {customerInformation.email}
              </div>
              <div className="confirmation-message-inner">
                Thank you so much for buying this shirt and supporting wikipedia - it means a tremendous amount. Please tell your friends and feel free to share this site around - it's likely no one will find the same shirt as you.
              </div>
              <div className="confirmation-message-inner">
                You can follow your order at {(
                  <a
                    className="confirmation-track-url"
                    href={trackingUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <strong>{trackingUrl}</strong>
                  </a>
                )}
              </div>
            </div>
            <Link className="button" to="/browse">
              create another one :D
            </Link>
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  customerInformation: state.customerInformation,
  processedImagesId: state.processedImagesId,
  orders: state.orders,
  selectedShippingOption: state.selectedShippingOption,
  stripeToken: state.stripeToken,
  totalPrice: state.totalPrice
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    clearOrders: () => ({
      type: ACTIONS.CLEAR_ORDERS
    }),
    setMakingOrder: makingOrder => ({
      type: ACTIONS.SET_MAKING_ORDER,
      makingOrder
    })
  }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Confirmation);
