import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "underscore";
import { connect } from "react-redux";
import Select from "react-select";
import { bindActionCreators } from "redux";
import uuidv1 from "uuid/v1";
import { Link } from "react-router-dom";

import "./build-order.scss";

import Input from "../inputs";
import ShowMoreText from "react-show-more-text";
import ItemPreview from "../item-preview";
import ACTIONS from "../../store/actions";
import {
  SHIRT_UNIT_PRICE,
  SHIRT_VARIANTS,
  SHIRT_SIZE_OPTIONS
} from "../../constants";
import {
  getCombinedItems,
  getShirtSizeLabelForValue,
  getVarientForColorAndSize,
  placeInOrders,
  validateOrder
} from "../../util/orders";

class BuildOrder extends Component {
  static propTypes = {
    orders: PropTypes.array.isRequired,
    setShirtColor: PropTypes.func.isRequired,
    setShirtSize: PropTypes.func.isRequired,
    subtotal: PropTypes.number.isRequired,
    updateOrder: PropTypes.func.isRequired
  };

  state = {
    errorContinuingWithOrder: null
  };

  componentDidMount() {
    const { location, updateOrder, orders, history, match } = this.props;
    if (location.state && location.state.newOrder) {
      updateOrder(placeInOrders(location.state.newOrder, orders));
      // Clear the browser state so that we don't add order again on refreshes.
      history.replace({
        pathname: match.url,
        state: {}
      });
    }

    document.title = `WearWiki - Bag`;
  }

  handleSelectSize = (orderIndex, newSize) => {
    const orders = this.props.orders;

    const newVariantId = getVarientForColorAndSize(
      SHIRT_VARIANTS[orders[orderIndex].variantId].color,
      newSize.value
    );

    const newOrders = [...orders];
    newOrders[orderIndex].variantId = newVariantId;

    this.setState({
      errorContinuingWithOrder: null
    });

    this.props.updateOrder(newOrders);
  };

  handleSelectQuantity = (orderIndex, newQuantity) => {
    const newOrders = [...this.props.orders];

    newOrders[orderIndex].quantity = parseInt(newQuantity);
    if (newQuantity < 0) newOrders[orderIndex].quantity = 0;
    if (newQuantity > 99) newOrders[orderIndex].quantity = 99;

    this.setState({
      errorContinuingWithOrder: null
    });

    this.props.updateOrder(newOrders);
  };

  handleQuantityBlur = (orderIndex, newQuantity) => {
    if (!newQuantity && newQuantity !== 0) {
      this.handleSelectQuantity(orderIndex, 0);
    }
  };

  removeOrder = orders => {
    let newOrders = [...this.props.orders];
    newOrders = _.without(newOrders, ...orders);

    this.setState({
      errorContinuingWithOrder: null
    });

    this.props.updateOrder(newOrders);
  };

  getTotalOrderCount = () => {
    const { orders } = this.props;
    let totalOrderCount = 0;
    for (let i = 0; i < orders.length; i++) {
      totalOrderCount += orders[i].quantity;
    }
    return totalOrderCount;
  };

  handleValidate = e => {
    e.preventDefault();
    const validationError = validateOrder(this.props.orders);

    if (validationError) {
      this.setState({
        errorContinuingWithOrder: validationError
      });

      return false;
    }

    const processedImagesId = uuidv1();

    // Here we kick off processing the images on server.
    this.props.setProcessingImages(processedImagesId, false);

    this.props.history.push("/checkout/1");

    fetch("/image/process", {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        processedImagesId,
        clientOrders: this.props.orders
      })
    })
      .then(async response => {
        if (response.ok) {
          this.props.setProcessingImages(processedImagesId, true);
        } else {
          return Promise.reject(await response.text());
        }
      })
      .catch(error => {
        // We don't mind failing processing the images, it will just mean we do it at the end.
      });
  };

  shirtPreviewClicked = item => {
    // Set the options of the shirt.
    this.props.setShirtColor(item.color);
    this.props.setShirtSize(item.size);
    this.props.toggleIncludeAuthorAttribution(item.includeAuthorAttribution);
    this.props.toggleIncludePageAttribution(item.includePageAttribution);
  };

  render() {
    const { errorContinuingWithOrder } = this.state;
    const { orders, subtotal } = this.props;

    const combinedItems = getCombinedItems(orders);

    const totalOrderCount = this.getTotalOrderCount() || 0;

    return (
      <div className="purchase">
        <Link className="link-button icon-left m0" to="/browse">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path
              className="icon"
              d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"
            />
          </svg>
          Back to Browse
        </Link>
        <h1>Your Bag</h1>
        {combinedItems.map((item, itemIndex) => {
          const id = `${item.imageTitle}_${item.color}_${item.size}_${
            item.includeAuthorAttribution
          }_${item.includePageAttribution}`;
          return (
            <div className="purchase-info-container" key={id}>
              <div className="flex-row">
                <Link
                  to={`/${item.pageTitle}/${item.imageTitle}`}
                  onClick={() => this.shirtPreviewClicked(item)}
                >
                  <ItemPreview
                    authorName={item.authorName}
                    color={item.color}
                    imageUrl={item.unprocessedImageUrl}
                    includeAuthorAttribution={item.includeAuthorAttribution}
                    includePageAttribution={item.includePageAttribution}
                    itemDisplayName={item.imageDisplayName}
                    pageDisplayTitle={item.pageDisplayTitle}
                    size={item.size}
                    thumbnailUrl={item.thumbnailUrl}
                    hideButtons
                    hideLabel
                  />
                </Link>
                <div className="column">
                  <h2>
                    <ShowMoreText lines={2} more="More" less="Less">
                      {item.imageDisplayName}
                    </ShowMoreText>
                  </h2>
                  <div className="gray">
                    <ShowMoreText lines={3} more="More" less="Less">
                      {item.authorName}
                    </ShowMoreText>
                  </div>
                </div>
              </div>
              <div className="flex-row">
                <div className="column">
                  {item.orders.map((order, orderIndex) => {
                    const { size } = SHIRT_VARIANTS[order.variantId];
                    return (
                      <div
                        className="flex-row"
                        key={item.orderIndexes[orderIndex]}
                      >
                        <Select
                          value={{
                            label: getShirtSizeLabelForValue(size),
                            value: size
                          }}
                          className="select"
                          classNamePrefix="select"
                          isSearchable={false}
                          onChange={selectedOption =>
                            this.handleSelectSize(
                              item.orderIndexes[orderIndex],
                              selectedOption
                            )
                          }
                          options={SHIRT_SIZE_OPTIONS}
                        />
                        <Input
                          label="Quantity"
                          id={`quantity_${size}_${id}`}
                          type="number"
                          value={order.quantity}
                          onChange={e =>
                            this.handleSelectQuantity(
                              item.orderIndexes[orderIndex],
                              e.target.value
                            )
                          }
                          onBlur={e =>
                            this.handleQuantityBlur(
                              item.orderIndexes[orderIndex],
                              e.target.value
                            )
                          }
                          flex
                        />
                        <button
                          className="link-button red"
                          onClick={() => this.removeOrder([order])}
                        >
                          Remove
                        </button>
                      </div>
                    );
                  })}
                  <div className="flex-row space-between">
                    {combinedItems.length > 1 && (
                      <div className="purchase-info-item">
                        <label>Price</label>
                        <p className="large">
                          ${item.unitPrice * (item.totalQuantity || 0)}{" "}
                          <span className="gray">
                            {(item.totalQuantity || 0) > 1
                              ? `(${item.totalQuantity || 0} x $${
                                  item.unitPrice
                                })`
                              : null}
                          </span>
                        </p>
                      </div>
                    )}
                    {item.orders.length > 1 && (
                      <button
                        className="link-button red"
                        onClick={() => this.removeOrder(item.orders)}
                      >
                        Remove All
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        
        {(totalOrderCount > 0)
          ? (
            <div className="purchase-controls-bar">
              <div className="purchase-info-item">
                <label>Subtotal</label>
                <p className="large">
                  ${subtotal || 0}{" "}
                  <span className="gray">
                    ({totalOrderCount} x ${SHIRT_UNIT_PRICE})
                  </span>
                </p>
              </div>
              <button className="button" onClick={this.handleValidate}>
                Checkout Now
              </button>
            </div>
        ) : (
          <div>
            <div className="purchase-info-item">
              {/* &lpar; &lpar */}
              <p className="large">Bag is empty {':\'('}</p> 
            </div>
            <div className="purchase-info-item">
              <Link className="link-button" to="/browse">
                Take me somewhere
              </Link>
            </div>
          </div>
        )}
        {errorContinuingWithOrder && (
          <div className="pay-error-message">{errorContinuingWithOrder}</div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  orders: state.orders,
  subtotal: state.subtotal
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setProcessingImages: (processedImagesId, imagesAreProcessed) => ({
        type: ACTIONS.SET_PROCESSING_IMAGES,
        processedImagesId,
        imagesAreProcessed
      }),
      setShirtColor: shirtColor => ({
        type: ACTIONS.SET_SHIRT_COLOR,
        shirtColor
      }),
      setShirtSize: shirtSize => ({
        type: ACTIONS.SET_SHIRT_SIZE,
        shirtSize: shirtSize
      }),
      toggleIncludeAuthorAttribution: includeAuthorAttribution => ({
        type: ACTIONS.SET_INCLUDE_AUTHOR_ATTRIBUTION,
        includeAuthorAttribution
      }),
      toggleIncludePageAttribution: includePageAttribution => ({
        type: ACTIONS.SET_INCLUDE_PAGE_ATTRIBUTION,
        includePageAttribution
      }),
      updateOrder: newOrders => ({
        type: ACTIONS.UPDATE_ORDERS,
        newOrders
      })
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BuildOrder);
