exports.TEES = require('./tees');

exports.PAGES = {
  WELCOME: 'WELCOME',
  WIKI: 'WIKI',
  EMPTY_WIKI: 'EMPTY_WIKI',
  SHIRT: 'SHIRT',
  SEARCH: 'SEARCH',
  FAVORITES: 'FAVORITES',
  LOADING_WIKI: 'LOADING_WIKI',
  FAILURE: 'FAILURE',
};

exports.THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
};

exports.ELEMENT_IDS = {
  PAGE_CONTAINER: 'PAGE_CONTAINER',
};

exports.SHIPPING_PRICE_CUSHION = 0.25;
exports.SHIRT_UNIT_PRICE = 26.99;
exports.FREE_SHIPPING_FOR_FIRST_SHIRT_MAXIMUM = 5;
exports.FREE_SHIPPING_FOR_ADDITIONAL_SHIRT_MAXIMUM = 1;

const SHIRT_COLORS = {
  white: 'white',
  black: 'black'
};
exports.SHIRT_COLORS = SHIRT_COLORS;
const SHIRT_COLOR_OPTIONS = [{
  value: SHIRT_COLORS.white,
  label: 'A super soft white shirt'
}, {
  value: SHIRT_COLORS.black,
  label: 'A super soft black shirt'
}];
exports.SHIRT_COLOR_OPTIONS = SHIRT_COLOR_OPTIONS;
exports.DEFAULT_SHIRT_COLOR = SHIRT_COLOR_OPTIONS[0].value;

// The `value`s here are what printful uses.
const SHIRT_SIZE_OPTIONS = [
  { value: 'extra-small', label: 'X-Small' },
  { value: 'small', label: 'Small' },
  { value: 'medium', label: 'Medium' },
  { value: 'large', label: 'Large' },
  { value: 'extra-large', label: 'X-Large' },
  { value: 'extra-extra-large', label: '2X-Large' }
];
exports.SHIRT_SIZE_OPTIONS = SHIRT_SIZE_OPTIONS;
exports.DEFAULT_SHIRT_SIZE = SHIRT_SIZE_OPTIONS[2].value;

// TODO: Each variant has its own print area.
// Products - have various products.
// We need to grab this and size accordingly?
// https://www.printful.com/docs/generator#actionPrintfiles
// Maybe this https://www.printful.com/docs/generator#actionTemplates
// Need to check out gildan ladies 2000L and 5000L
// https://www.printful.com/products
// const SHIRT_PRODUCTS = {

// };
// // This is unisex softstyle 64000 gildan.
// const SHIRT_VARIANTS = {
//   // White
//   473: { color: SHIRT_COLORS.white, size: 'small', id: 473 },
//   504: { color: SHIRT_COLORS.white, size: 'medium', id: 504 },
//   535: { color: SHIRT_COLORS.white, size: 'large', id: 535 },
//   566: { color: SHIRT_COLORS.white, size: 'extra-large', id: 566 },
//   // 597: { color: SHIRT_COLORS.white, size: '2XL', id: 597 },

//   // Black
//   474: { color: SHIRT_COLORS.black, size: 'small', id: 474 },
//   505: { color: SHIRT_COLORS.black, size: 'medium', id: 505 },
//   536: { color: SHIRT_COLORS.black, size: 'large', id: 536 },
//   567: { color: SHIRT_COLORS.black, size: 'extra-large', id: 567 },
//   // 598: { color: SHIRT_COLORS.black, size: '2XL', id: 598 },
// };

// This is Next Level 3600.
const SHIRT_VARIANTS = {

  // This is Next Level 3600. CURRENTLY DISABLED FOR ANOTHER
  // White
  4872: { color: SHIRT_COLORS.white, size: 'extra-small', id: 4872, isDisabled: true },
  4873: { color: SHIRT_COLORS.white, size: 'small', id: 4873, isDisabled: true },
  4874: { color: SHIRT_COLORS.white, size: 'medium', id: 4874, isDisabled: true },
  4875: { color: SHIRT_COLORS.white, size: 'large', id: 4875, isDisabled: true },
  4876: { color: SHIRT_COLORS.white, size: 'extra-large', id: 4876, isDisabled: true },
  4877: { color: SHIRT_COLORS.white, size: 'extra-extra-large', id: 4877, isDisabled: true },

  // // Black
  4865: { color: SHIRT_COLORS.black, size: 'extra-small', id: 4865, isDisabled: true },
  4866: { color: SHIRT_COLORS.black, size: 'small', id: 4866, isDisabled: true },
  4867: { color: SHIRT_COLORS.black, size: 'medium', id: 4867, isDisabled: true },
  4868: { color: SHIRT_COLORS.black, size: 'large', id: 4868, isDisabled: true },
  4869: { color: SHIRT_COLORS.black, size: 'extra-large', id: 4869, isDisabled: true },
  4870: { color: SHIRT_COLORS.black, size: 'extra-extra-large', id: 4870, isDisabled: true },



  // Unisex Staple T-Shirt | Bella + Canvas 3001 // 30.48 × 40.64 cm
  // Shirt id: 71 //  "main_category_id": 24,
  // White
  9526: { color: SHIRT_COLORS.white, size: 'extra-small', id: 9526 },
  4011: { color: SHIRT_COLORS.white, size: 'small', id: 4011 },
  4012: { color: SHIRT_COLORS.white, size: 'medium', id: 4012 },
  4013: { color: SHIRT_COLORS.white, size: 'large', id: 4013 },
  4014: { color: SHIRT_COLORS.white, size: 'extra-large', id: 4014 },
  4015: { color: SHIRT_COLORS.white, size: 'extra-extra-large', id: 4015 },

  // Black
  9527: { color: SHIRT_COLORS.black, size: 'extra-small', id: 9527 },
  4016: { color: SHIRT_COLORS.black, size: 'small', id: 4016 },
  4017: { color: SHIRT_COLORS.black, size: 'medium', id: 4017 },
  4018: { color: SHIRT_COLORS.black, size: 'large', id: 4018 },
  4019: { color: SHIRT_COLORS.black, size: 'extra-large', id: 4019 },
  4020: { color: SHIRT_COLORS.black, size: 'extra-extra-large', id: 4020 }
};
exports.SHIRT_VARIANTS = SHIRT_VARIANTS;

exports.VARIANT_IDS = Object.keys(SHIRT_VARIANTS);
