const _ = require('underscore');

const {
  SHIRT_COLORS,
  SHIRT_SIZE_OPTIONS,
  SHIRT_UNIT_PRICE,
  SHIRT_VARIANTS,
  VARIANT_IDS
} = require('../constants');

const { isStringAndIsInBounds } = require('./type');
const { getTitleForPage } = require('./wikipedia-images');

module.exports.getShirtSizeLabelForValue = function(shirtSizeValue) {
  for(let i = 0; i < SHIRT_SIZE_OPTIONS.length; i++) {
    if (SHIRT_SIZE_OPTIONS[i].value === shirtSizeValue) {
      return SHIRT_SIZE_OPTIONS[i].label;
    }
  }
}

module.exports.createNewOrder = function(variantId, wikiImage, wikiPage, includeAuthorAttribution, includePageAttribution) {
  const authorName = wikiImage.author;
  const imageDisplayName = wikiImage.description;

  return {
    authorName: authorName ? authorName : '',
    includeAuthorAttribution,
    includePageAttribution,
    unprocessedImageUrl: wikiImage.url,
    imageDisplayName: imageDisplayName ? imageDisplayName : '',
    quantity: 1,
    pageTitle: getTitleForPage(wikiPage),
    pageDisplayTitle: wikiPage.title,
    imageTitle: wikiImage.canonicalTitle,
    thumbnailUrl: wikiImage.thumb,
    unitPrice: SHIRT_UNIT_PRICE,
    variantId,

    createdAt: new Date(), // Used for ordering in bag.
    lastModifiedAt: new Date(), // Used for ordering in bag.
  };
}

module.exports.placeInOrders = function(newOrder, orders) {
  const orderIndex = findOrderByImageTitleAndVariant(orders, newOrder.imageTitle, newOrder.variantId);
  if (orderIndex >= 0 && orders[orderIndex].includeAuthorAttribution === newOrder.includeAuthorAttribution && orders[orderIndex].includePageAttribution === newOrder.includePageAttribution) {
    /* An order with this image and variant (shirt size and color) already exists, combine quanitities and continue */
    orders[orderIndex].quantity += newOrder.quantity;
    orders[orderIndex].lastModifiedAt = newOrder.lastModifiedAt;
    return orders;
  } else {
    /* No order exists for this image and variant, add as new order */
    orders.push(newOrder);
    return orders;
  }
}

const findOrderByImageTitleAndVariant = function(orders, imageTitle, variant) {
  return _.findIndex(orders, (order) => {
    return order.imageTitle === imageTitle && order.variantId === variant;
  });
}

module.exports.getVarientForColorAndSize = function(color, size) {
  let variantId = null;

  for(let i = 0; i < VARIANT_IDS.length; i++) {
    if (
      color === SHIRT_VARIANTS[VARIANT_IDS[i]].color
      && size === SHIRT_VARIANTS[VARIANT_IDS[i]].size
      && !SHIRT_VARIANTS[VARIANT_IDS[i]].isDisabled
    ) {
      variantId = VARIANT_IDS[i];
    }
  }

  return variantId;
}

module.exports.validateOrder = function(orders) {
  let totalOrderCount = 0;
  for (let i = 0; i < orders.length; i++) {
    if (!orders[i].quantity || isNaN(orders[i].quantity) || orders[i].quantity < 1) {
      return `Invalid order quantity: "${orders[i].quantity}".`;
    }
    totalOrderCount += orders[i].quantity;
  }

  if (totalOrderCount <= 0) {
    return 'Please add a shirt before checking out.';
  }

  if (totalOrderCount > 20) {
    return 'We currently do not allow over 20 shirts in one order.';
  }
}

module.exports.validateStripeToken = function(stripeToken) {
  if (!isStringAndIsInBounds(stripeToken, 10, 100)) {
    return 'Invalid stripe token.';
  }
}

module.exports.validateProcessedImagesId = function(processedImagesId) {
  if (!isStringAndIsInBounds(processedImagesId, 10, 100)) {
    return 'Invalid processed images reference id.';
  }
}

module.exports.validateCustomerInformation = function(customerInformation) {
  const {
    email,
      
    shippingName,
    shippingAddress1,
    shippingZip,
    shippingCity,
    shippingCountry,
    shippingCountrySelected,
    shippingRegion,
  } = customerInformation;

  if (!email) {
    return 'No email supplied.';
  }

  if (!isStringAndIsInBounds(shippingName, 1, 200)) {
    return 'No shipping name supplied.';
  } else if (!isStringAndIsInBounds(shippingAddress1, 1, 400)) {
    return 'No shipping address supplied.';
  } else if (!isStringAndIsInBounds(shippingZip, 1, 200)) {
    return 'No shipping zip supplied.';
  } else if (!isStringAndIsInBounds(shippingCity, 1, 200)) {
    return 'No shipping city supplied.';
  } else if (!isStringAndIsInBounds(shippingCountry, 1, 200)) {
    return 'No shipping country supplied.';
  } else if (shippingCountrySelected.states && shippingCountrySelected.states && !isStringAndIsInBounds(shippingRegion, 1, 200)) {
    return 'No shipping region supplied.';
  }
}

module.exports.validateSelectedShippingOption = function(selectedShippingOption) {
  if (!selectedShippingOption || Object.keys(selectedShippingOption).length < 2) {
    return 'No selected shipping option.';
  }
}

module.exports.getDefaultVariantIdForColor = function(color) {
  // Maybe this should be in a util...
  if (color === SHIRT_COLORS.black) {
    return SHIRT_VARIANTS[4017].id;
  } else {
    return SHIRT_VARIANTS[4012].id;
  }
}

const getSubtotalFromOrders = function(orders) {
  let subtotal = 0;
  for (let i = 0; i < orders.length; i++) {
    subtotal += Number(orders[i].quantity) * SHIRT_UNIT_PRICE;
  }

  return subtotal;
}
module.exports.getSubtotalFromOrders = getSubtotalFromOrders;

module.exports.getTotalPrice = function(orders, shippingOption) {
  const shippingPrice = shippingOption.isCoveredFreeShipping ? 0 : Number(shippingOption.rate);
  return getSubtotalFromOrders(orders) + shippingPrice;
}

module.exports.getCombinedItems = function(orders) {
  let combinedItems = [];
  _.each(orders, (order, i) => {
    const variant = SHIRT_VARIANTS[order.variantId];

    const index = _.findIndex(combinedItems, (o) => {
      return o.imageTitle === order.imageTitle && o.color === variant.color && o.includeAuthorAttribution === order.includeAuthorAttribution && o.includePageAttribution === order.includePageAttribution;
    });
    if (index >= 0) {
      combinedItems[index].orders.push(order);
      combinedItems[index].totalQuantity += order.quantity;
      combinedItems[index].orderIndexes.push(i);
      if (order.lastModifiedAt > combinedItems[index].lastModifiedAt) combinedItems[index].lastModifiedAt = order.lastModifiedAt;
    } else {
      combinedItems.push({
        imageTitle: order.imageTitle,
        pageTitle: order.pageTitle,
        authorName: order.authorName,
        imageDisplayName: order.imageDisplayName,

        color: variant.color,
        size: variant.size,

        includeAuthorAttribution: order.includeAuthorAttribution,
        includePageAttribution: order.includePageAttribution,
        lastModifiedAt: order.lastModifiedAt,
        pageDisplayTitle: order.pageDisplayTitle,
        totalQuantity: order.quantity,
        unitPrice: order.unitPrice,
        unprocessedImageUrl: order.unprocessedImageUrl,

        orders: [order],
        orderIndexes: [i],

      });
    }
  });
  return combinedItems;
}

module.exports.getDescriptionForStatus = function(status) {
  switch(status) {
    case 'draft':
      return 'Your order is currently being submitted into the system. Please note the images shown below are not to scale.';
    case 'failed':
      return 'There was an error creating your order with our manufacturer. We\'ll be looking into it. Feel free to reach out to support@wearwiki.com';
    case 'pending':
      return 'Your order has been created and we are working with our manufacturer. It\'s on it way to be printed. Please check back for shipping information later.';
    case 'canceled':
      return 'Your order was canceled in our system. If you believe this is an error please reach out to us at support@wearwiki.com';
    case 'onhold':
      return 'We\'ve encountered a problem with your order during the manufacturing process, we\'re fixing the issue now. Please check back later for more information. Feel free to reach out to support@wearwiki.com';
    case 'inprocess':
      return 'Your order is currently in progress, we\'re printing it right now. Please check back later for shipping information.';
    case 'partial':
      return 'Part of your order has already shipped, and we\'re working on the rest right now. Please check back later for more shipping information.';
    case 'fulfilled':
      return 'Your order has been shipped! It\'s on its way. :D';
    case 'archived':
      return 'Your order is in the archives. If you believe this is an error please reach out to us at support@wearwiki.com';
    default:
      console.log('Unknown status?', status);
      return 'Your order is currently be submitted into the system.';
  }
}

module.exports.getTotalQuantity = function(orders) {
  return orders.reduce((s, f) => s + f.quantity, 0);
}