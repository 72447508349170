import React, { Component } from "react";

class RelatedPagesData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      titleLoadedFor: null
    };
  }

  componentDidMount() {
    this.loadData();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.pageTitle !== this.state.pageTitle) return true;
    if (nextState.data !== this.state.data) return true;
    return false;
  }

  componentDidUpdate() {
    if (this.state.titleLoadedFor !== this.props.pageTitle) this.loadData();
  }

  loadData() {
    const { pageTitle } = this.props;
    fetch(`https://en.wikipedia.org/api/rest_v1/page/related/${pageTitle}`)
      .then(response => response.json())
      .then(data => {
        this.setState({ data: data, titleLoadedFor: pageTitle });
      });
  }

  render() {
    const { contents, loader } = this.props;
    if (!this.state.data.pages) return loader();
    return contents(this.state.data);
  }
}

export default RelatedPagesData;
