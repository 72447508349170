import React, { Component } from 'react';

import './page-content.scss';

class PageContent extends Component {
  render() {
    const { centered, children } = this.props;
    return (
      <div className="page-content">
        <div className={`page ${centered ? 'centered' : ''}`}>
          {children}
        </div>
      </div>
    );
  }
}

export default PageContent;