import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import ShirtLoader from "../shirt-loader";
import { SHIRT_COLORS } from "../../constants";

import "./mockup.scss";

import {
  addTextToBottomRightOfImage,
  addTextToTopLeftOfImage,
  getImageDimensionsOnCanvas,
  getTransparentProcessedImageData
} from "../../util/image";

class Mockup extends Component {
  static propTypes = {
    authorName: PropTypes.string,
    color: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    includeAuthorAttribution: PropTypes.bool,
    includePageAttribution: PropTypes.bool,
    pageDisplayTitle: PropTypes.string.isRequired,
    thumbnailUrl: PropTypes.string,
    useThumbnail: PropTypes.bool
  };

  static defaultProps = {
    authorName: "",
    useThumbnail: false,
    thumbnailUrl: "",
    includeAuthorAttribution: false,
    includePageAttribution: false
  };

  state = {
    imageError: false,
    canvasSet: false,
    imageReady: false
  };

  componentDidMount() {
    this.loadImages();
  }

  componentDidUpdate(prevProps) {
    if (this.props.imageUrl === prevProps.imageUrl) {
      if (
        this.props.color !== prevProps.color ||
        this.props.includeAuthorAttribution !==
          prevProps.includeAuthorAttribution ||
        this.props.includePageAttribution !== prevProps.includePageAttribution
      ) {
        this.loadImages();
      } else {
        return;
      }
    }
    this.loadImages();
  }

  componentWillUnmount() {
    this.refs.canvas.width = 0;
    this.refs.canvas.height = 0;
    this.bg = null;
    this.artwork = null;
  }

  loadImages = () => {
    const {
      color,
      blackShirt,
      imageUrl,
      thumbnailUrl,
      useThumbnail,
      whiteShirt,
      blackShirtThumb,
      whiteShirtThumb,
      dimensions
    } = this.props;

    this.ctx = this.refs.canvas.getContext("2d");
    if (useThumbnail && thumbnailUrl) {
      this.bg =
        color === SHIRT_COLORS.black ? blackShirtThumb : whiteShirtThumb;
    } else {
      this.bg = color === SHIRT_COLORS.black ? blackShirt : whiteShirt;
    }

    if (this.bg && this.bg.src && this.bg.complete) {
      this.refs.canvas.width = this.bg.width;
      this.refs.canvas.height = this.bg.height;
      this.drawMockup();
      this.setState({
        canvasSet: true
      });
    } else {
      const bgLoadCheck = setInterval(() => {
        if (!this.bg) return;
        if (this.bg.complete) {
          clearInterval(bgLoadCheck);
          this.refs.canvas.width = this.bg.width;
          this.refs.canvas.height = this.bg.height;
          this.drawMockup();
          this.setState({
            canvasSet: true
          });
        }
      }, 100);
    }

    this.artwork = new Image();
    // https://developer.mozilla.org/en-US/docs/Web/HTML/CORS_enabled_image
    this.artwork.crossOrigin = "Anonymous";
    this.artwork.onload = e => {
      if (!this.artwork) {
        this.setState({
          imageError: true
        });
        return;
      }

      if (dimensions) {
        this.imageWidth = dimensions.width;
        this.imageHeight = dimensions.height;
      } else {
        this.imageWidth = e.target.width;
        this.imageHeight = e.target.height;
      }

      this.drawMockup();
    };

    this.artwork.src = useThumbnail && thumbnailUrl ? thumbnailUrl : imageUrl;
  };

  drawMockup() {
    if (
      !(
        this.bg &&
        this.bg.src &&
        this.bg.complete &&
        this.artwork &&
        this.artwork.src &&
        this.artwork.complete
      )
    ) {
      return;
    }

    const {
      authorName,
      color,
      includeAuthorAttribution,
      includePageAttribution,
      pageDisplayTitle
    } = this.props;

    const imageWidth = this.imageWidth;
    const imageHeight = this.imageHeight;

    const width = this.refs.canvas.width;
    const height = this.refs.canvas.height;

    // Clear the current canvas.
    this.ctx.clearRect(0, 0, width, height);

    const printAreaWidth = Math.floor(width * (13 / 24));
    const printAreaHeight = Math.floor(height * (10 / 24));
    const printAreaOffsetX = Math.floor(width / 2 - printAreaWidth / 2);
    const printAreaOffsetY = Math.floor(height / 2 - printAreaHeight / 2);

    // 1. Get the image data of the image with transparency processed.
    const artImageData = getTransparentProcessedImageData(
      this.artwork,
      this.ctx,
      printAreaWidth,
      printAreaHeight,
      color,
      imageWidth,
      imageHeight
    );
    const artPixels = artImageData.data;
    const artDimensions = getImageDimensionsOnCanvas(
      this.artwork,
      printAreaWidth,
      printAreaHeight,
      imageWidth,
      imageHeight
    );

    // 2. Clear the canvas and draw on the t shirt.
    this.ctx.clearRect(0, 0, width, height);
    if (this.bg) {
      this.ctx.drawImage(this.bg, 0, 0);
    }

    // 3. Sew together the image and the shirt.
    const shirtImageData = this.ctx.getImageData(
      artDimensions.x + printAreaOffsetX,
      artDimensions.y + printAreaOffsetY,
      artDimensions.width + 1,
      artDimensions.height + 1
    );
    const shirtPixels = shirtImageData.data;
    for (let i = 0; i < shirtPixels.length; i += 4) {
      const r = artPixels[i],
        g = artPixels[i + 1],
        b = artPixels[i + 2],
        a = artPixels[i + 3];

      // When we encounter a pixel in the threshold we make it transparent.
      // This makes unnescessary backgrounds not show up on the shirt.
      if (
        (color === SHIRT_COLORS.black &&
          r === 0 &&
          g === 0 &&
          b === 0 &&
          a === 0) ||
        (color === SHIRT_COLORS.white &&
          r === 0 &&
          g === 0 &&
          b === 0 &&
          a === 0)
      ) {
        // Do nothing as we want the shirt pixel to come through.
      } else {
        shirtPixels[i] = artPixels[i];
        shirtPixels[i + 1] = artPixels[i + 1];
        shirtPixels[i + 2] = artPixels[i + 2];
        shirtPixels[i + 3] = artPixels[i + 3];
      }
    }

    // White transparency tests:
    // http://localhost:3000/Prepona_pylene
    // http://localhost:3000/Fishing_River
    // http://localhost:3000/Prosopocoilus_savagei

    // Just a cool page:
    // http://localhost:3000/Bagan

    artDimensions.x += printAreaOffsetX;
    artDimensions.y += printAreaOffsetY;

    // 4. Re-apply the processed image data to the canvas.
    this.ctx.clearRect(
      artDimensions.x,
      artDimensions.y,
      artDimensions.width,
      artDimensions.height
    );
    this.ctx.putImageData(shirtImageData, artDimensions.x, artDimensions.y);

    // VVV Image attribution. Un-comment this on server as well if we want to add it.
    if (includeAuthorAttribution) {
      addTextToBottomRightOfImage(this.ctx, artDimensions, authorName, color);
    }

    if (includePageAttribution && pageDisplayTitle) {
      addTextToTopLeftOfImage(this.ctx, artDimensions, pageDisplayTitle, color);
    }

    // If you want to test what our print area looks like uncomment this:
    // this.ctx.fillStyle = 'rgb(20, 220, 20)';
    // this.ctx.fillRect(printAreaOffsetX, printAreaOffsetY, printAreaWidth, printAreaHeight)

    // this.ctx.font='20px Georgia';
    // if (this.props.meta.description && this.props.meta.description.text) {
    //   this.wrapText(
    //     this.ctx,
    //     this.props.meta.description.text,
    //     x,
    //     y + maxHeight + 40,
    //     maxWidth,
    //     28
    //   );
    // }

    this.setState({
      imageReady: true
    });
    this.props.onImageRender && this.props.onImageRender();
  }

  render() {
    const { canvasSet, imageReady, imageError } = this.state;
    return (
      <div className="mockup">
        {imageError
        ? (
          <div>
            <h1>Unable to load image</h1>
          </div>
        )
        : (
          <>
            <canvas ref="canvas" />
            {canvasSet && !imageReady && <ShirtLoader count={1} fix />}
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  whiteShirt: state.whiteShirt,
  blackShirt: state.blackShirt,
  blackShirtThumb: state.blackShirtThumb,
  whiteShirtThumb: state.whiteShirtThumb
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Mockup);
