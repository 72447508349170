import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import "./header.scss";
import { getTotalQuantity } from "../../util/orders";

class Header extends Component {
  static propTypes = {
    orders: PropTypes.array.isRequired,
    showButtons: PropTypes.bool
  };

  static defaultProps = {
    showButtons: true
  };

  render() {
    const { orders, showButtons } = this.props;
    const sum = getTotalQuantity(orders);
    return (
      <header className="header nav" id="header-box">
        <h1 className="title header-item ml-4 mr-4">
          <Link to="/">WearWiki</Link>
        </h1>
        {showButtons && (
          <Fragment>
            <nav className="d-flex header-item-full">
              <div className="header-item header-item-full">
                <Link className="header-link mr-3" to="/featured">
                  Featured
                </Link>
                <Link className="header-link mr-3" to="/browse">
                  Random
                </Link>
                <Link className="header-link mr-3" to="/faq">
                  F.A.Q.
                </Link>
              </div>
              {/* <div className="header-item">
                <Link className="header-link mr-3" to="/about">
                  About
                </Link>
              </div> */}
              {/*<Link to="/favorites" className="button icon-button">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path className="icon" d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"/></svg>
              </Link>*/}
              <div className="header-item mr-1">
                <Link className="button icon-button" to="/search">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      className="icon"
                      d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
                    />
                    <path d="M0 0h24v24H0z" fill="none" />
                  </svg>
                </Link>
              </div>
              <div className="header-item mr-0">
                <Link className="button icon-button" to="/checkout">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      className="icon"
                      d="M12,13A5,5 0 0,1 7,8H9A3,3 0 0,0 12,11A3,3 0 0,0 15,8H17A5,5 0 0,1 12,13M12,3A3,3 0 0,1 15,6H9A3,3 0 0,1 12,3M19,6H17A5,5 0 0,0 12,1A5,5 0 0,0 7,6H5C3.89,6 3,6.89 3,8V20A2,2 0 0,0 5,22H19A2,2 0 0,0 21,20V8C21,6.89 20.1,6 19,6Z"
                    />
                  </svg>
                  {sum > 0 && <div className="red-indicator">{sum}</div>}
                </Link>
              </div>
            </nav>
          </Fragment>
        )}
      </header>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  orders: state.orders
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
