import _ from "underscore";

import { getValidWikipediaImagesFromImages, filterAllowableImages, WikiImage, getTitleForPage } from "../util/wikipedia-images";
import { transformUrlText } from "../util/url";

const ACTIONS = {
  CLEAR_DEEP_LINK_IMAGE_TITLE: "wearwiki/CLEAR_DEEP_LINK_IMAGE_TITLE",
  CLEAR_ORDERS: "wearwiki/CLEAR_ORDERS",
  UPDATE_CUSTOMER_INFORMATION: "wearwiki/UPDATE_CUSTOMER_INFORMATION",
  UPDATE_ORDERS: "wearwiki/UPDATE_ORDERS",
  FETCH_WIKI_PAGE_BEGIN: "wearwiki/FETCH_WIKI_PAGE_BEGIN",
  FETCH_WIKI_PAGE_SUCCESS: "wearwiki/FETCH_WIKI_PAGE_SUCCESS",
  FETCH_WIKI_PAGE_FAILURE: "wearwiki/FETCH_WIKI_PAGE_FAILURE",
  FETCH_WIKI_IMAGES_BEGIN: "wearwiki/FETCH_WIKI_IMAGES_BEGIN",
  FETCH_WIKI_IMAGES_SUCCESS: "wearwiki/FETCH_WIKI_IMAGES_SUCCESS",
  FETCH_WIKI_IMAGES_FAILURE: "wearwiki/FETCH_WIKI_IMAGES_FAILURE",
  LOAD_FROM_BUFFER: "wearwiki/LOAD_FROM_BUFFER",
  REPORT_SHIRT: "wearwiki/REPORT_SHIRT",
  SAVE_BUFFER_AS_NEW_PAGE: "wearwiki/SAVE_BUFFER_AS_NEW_PAGE",
  SELECT_SHIPPING_OPTION: "wearwiki/SELECT_SHIPPING_OPTION",
  SET_CURRENT_PAGE: "wearwiki/SET_CURRENT_PAGE",
  SET_FAB_GHOST_REF: "wearwiki/SET_FAB_GHOST_REF",
  SET_GALLERY_SCROLL_POSITION: "wearwiki/SET_GALLERY_SCROLL_POSITION",
  SET_INCLUDE_AUTHOR_ATTRIBUTION: "wearwiki/SET_INCLUDE_AUTHOR_ATTRIBUTION",
  SET_INCLUDE_PAGE_ATTRIBUTION: "wearwiki/SET_INCLUDE_PAGE_ATTRIBUTION",
  SET_MAKING_ORDER: "wearwiki/SET_MAKING_ORDER",
  SET_PROCESSING_IMAGES: "wearwiki/SET_PROCESSING_IMAGES",
  SET_SHIPPING_OPTIONS: "wearwiki/SET_SHIPPING_OPTIONS",
  SET_SHIPPING_OPTIONS_LOADED: "wearwiki/SET_SHIPPING_OPTIONS_LOADED",
  SET_SHIRT_COLOR: "wearwiki/SET_SHIRT_COLOR",
  SET_SHIRT_SIZE: "wearwiki/SET_SHIRT_SIZE",
  SET_STRIPE_TOKEN: "wearwiki/SET_STRIPE_TOKEN",
  TOGGLE_PUSH_URL: "wearwiki/TOGGLE_PUSH_URL",
  FETCH_WIKI_SUMMARY_SUCCESS: "wearwiki/FETCH_WIKI_SUMMARY_SUCCESS",
  FETCH_RANDOM_WIKI_IMAGES_SUCCESS: "wearwiki/FETCH_RANDOM_WIKI_IMAGES_SUCCESS",
  EMPTY_RANDOM_WIKIS: "wearwiki/EMPTY_RANDOM_WIKIS"
};

export default ACTIONS;

export const fetchNextRandomWiki = () => {
  //TODO dispatch(fetchNextRandomWikiBegin)
  return dispatch => {
    doFetchWikiSummary(dispatch);
  };
};

export const fetchWikiByTitle = title => {
  return dispatch => {
    doFetchWikiSummary(dispatch, title);
  };
};

const doFetchWikiSummary = (dispatch, title = null) => {
  const isRandom = !title;
  const fetchUrl = isRandom
    ? "https://en.wikipedia.org/api/rest_v1/page/random/summary"
    : `https://en.wikipedia.org/api/rest_v1/page/summary/${transformUrlText(
        title
      )}`;
  fetch(fetchUrl)
    .then(response => response.json())
    .then(page => {
      if (isRandom && !page.titles) {
        // If loading a random page and it doesn't work then try again
        fetchNextRandomWiki(dispatch);
      } else {
        // Save to page to wikis and fetch images
        dispatch(fetchWikiSummarySuccess(page));
        doFetchWikiImages(dispatch, page, isRandom);
      }
    });
};

const doFetchWikiImages = (dispatch, page, isRandom) => {
  fetch(
    `https://en.wikipedia.org/api/rest_v1/page/media-list/${transformUrlText(
      getTitleForPage(page)
    )}`
  )
    .then(response => response.json())
    .then(data => {
      // Filters out media objects into images that are either png or jpg
      const validImages = getValidWikipediaImagesFromImages(data.items);
      
      // Need to query additional image metadata to see license and sizing and stuff
      const origin = window.location.protocol+'//'+window.location.hostname+(window.location.port ? ':'+window.location.port: '')
      fetch(
        `https://en.wikipedia.org/w/api.php?action=query&prop=imageinfo&iiprop=extmetadata|size|url|canonicaltitle&iiurlwidth=200&titles=${
          encodeURIComponent(validImages.map(i => i.title).join('|'))
        }&format=json&origin=*`
      )
        .then(response => response.json())
        .then(data => {
          if (data && data.query && data.query.pages) {
            const wikiImages = _.map(data.query.pages, (i, key) => new WikiImage(i));
            const allowableImages = filterAllowableImages(wikiImages);
            if (allowableImages.length >= 1) {
              dispatch(fetchWikiImagesSuccess(page, allowableImages, isRandom));
              return
            }
          }
          
          if (isRandom) {
            doFetchWikiSummary(dispatch);
          }
        });
    });
};

export const fetchWikiSummarySuccess = page => ({
  type: ACTIONS.FETCH_WIKI_SUMMARY_SUCCESS,
  payload: { page }
});

export const fetchWikiImagesSuccess = (page, images, addToRandom = false) => ({
  type: ACTIONS.FETCH_WIKI_IMAGES_SUCCESS,
  payload: { page, images, addToRandom }
});

export const emptyRandomWikis = () => ({
  type: ACTIONS.EMPTY_RANDOM_WIKIS
});
