import PropTypes from "prop-types";
import React, { Component } from "react";

import Mockup from "../mockup";
import {
  SHIRT_UNIT_PRICE,
  DEFAULT_SHIRT_COLOR,
  DEFAULT_SHIRT_SIZE
} from "../../constants";

import "./item-preview.scss";

class ItemPreview extends Component {
  static propTypes = {
    authorName: PropTypes.string,
    color: PropTypes.string,
    hideButtons: PropTypes.bool,
    hideLabel: PropTypes.bool,
    imageUrl: PropTypes.string,
    includeAuthorAttribution: PropTypes.bool,
    includePageAttribution: PropTypes.bool,
    itemDisplayName: PropTypes.string,
    pageDisplayTitle: PropTypes.string,
    hideMockup: PropTypes.bool,
    size: PropTypes.string,
    thumbnailUrl: PropTypes.string,
    fixedHeight: PropTypes.bool
  };

  static defaultProps = {
    authorName: "",
    color: DEFAULT_SHIRT_COLOR,
    hideButtons: false,
    hideLabel: false,
    includeAuthorAttribution: false,
    includePageAttribution: false,
    pageDisplayTitle: "",
    hideMockup: false,
    size: DEFAULT_SHIRT_SIZE,
    thumbnailUrl: "",
    fixedHeight: false
  };

  state = {
    imageRendered: false
  };

  onImageRender = () => {
    this.setState({
      imageRendered: true
    });
  };

  render() {
    const {
      authorName,
      color,
      imageUrl,
      includePageAttribution,
      includeAuthorAttribution,
      itemDisplayName,
      pageDisplayTitle,
      hideButtons,
      hideLabel,
      hideMockup,
      thumbnailUrl,
      dimensions,
      fixedHeight
    } = this.props;
    const { imageRendered } = this.state;

    const isImageRendered = hideMockup || imageRendered;

    return (
      <div
        className={`shirt-item ${isImageRendered ? "" : "hide-box"} ${
          fixedHeight ? "fixed" : ""
        }`}
      >
        <div className="preview">
          {!hideMockup ? (
            <Mockup
              authorName={authorName}
              color={color}
              imageUrl={imageUrl}
              includeAuthorAttribution={includeAuthorAttribution}
              includePageAttribution={includePageAttribution}
              onImageRender={this.onImageRender}
              pageDisplayTitle={pageDisplayTitle}
              thumbnailUrl={thumbnailUrl}
              useThumbnail
              dimensions={dimensions}
            />
          ) : (
            <img
              alt={pageDisplayTitle}
              className="preview-image"
              src={imageUrl}
            />
          )}
          {!hideButtons && (
            <div className="preview-action-row">
              <div className="price tag">${SHIRT_UNIT_PRICE}</div>
            </div>
          )}
        </div>
        {!hideLabel && (
          <div className="label">
            <p>{itemDisplayName}</p>
            <p className="gray">{authorName}</p>
          </div>
        )}
      </div>
    );
  }
}

export default ItemPreview;
