import React, { Component } from "react";
import { Link } from "react-router-dom";
import Mockup from "../mockup";
import { getImageAuthor } from "../../util/parsers";
import { DEFAULT_SHIRT_COLOR } from "../../constants";

import "./wiki-layouts.scss";

class WikiImageCard extends Component {
  render() {
    const { image } = this.props;
    if (!image) {
      // Don't always have image of the day.
      return null;
    }
    // const pageLink = getTitleForPage(image);
    return (
      <div className="wiki-image-card">
        <div
          className="wiki-image-background"
          style={image?.image?.source ? { backgroundImage: `url("${image.image.source}")` } : undefined}
        >
          <div className="daily-tag">Image of the Day</div>
          <div className="wiki-shirt-preview-small">
            <Mockup
              authorName={getImageAuthor(image)}
              imageUrl={image?.image?.source}
              color={DEFAULT_SHIRT_COLOR}
              pageDisplayTitle={image?.title}
              thumbnailUrl={
                image?.thumbnail && image.thumbnail?.source
                  ? image?.thumbnail?.source
                  : null
              }
              useThumbnail
              original={image?.image}
              includePageAttribution={false}
              includeAuthorAttribution={false}
            />
          </div>
        </div>
        <div className="wiki-image-card-content">
          <h1>{image?.description?.text}</h1>

          <Link
            className="link-button"
            to="/featured"
          >
            {/*
              We currently disable the daily image as it isn't
              associated with a page so it would take some work to support. 
            */}
            {/* See Shirt */}
            Browse Featured
          </Link>
        </div>
      </div>
    );
  }
}

export default WikiImageCard;
