import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";

import { WikiPageData, RelatedPagesData } from "../data-getters";
import { WikiListItem } from "../wiki-layouts";

import { ACTIONS } from "../../store";
import { fetchNextRandomWiki, emptyRandomWikis } from "../../store/actions";
import { getTitleForPage } from "../../util/wikipedia-images";

import Header from "../header";
import ItemPreview from "../item-preview";
import { PageContent } from "../page";
import RandomizeButton from "../randomize-button";
import ShirtLoader from "../shirt-loader";

import "./wiki-page.scss";

const BUFFER_SIZE = 10;

class WikiPage extends Component {
  static propTypes = {
    orders: PropTypes.array
  };

  constructor(props) {
    super(props);

    this.state = {
      favorites: JSON.parse(localStorage.getItem("favorites")),
      randomWikiIndex: 0
    };

    this.galleryRef = React.createRef();
  }

  componentDidMount() {
    this.props.emptyRandomWikis();
    this.props.fetchNextRandomWiki();
    this.props.fetchNextRandomWiki();
    this.props.fetchNextRandomWiki();
    this.props.fetchNextRandomWiki();
    this.props.fetchNextRandomWiki();
  }

  componentDidUpdate() {
    const { randomWikis, match, history } = this.props;
    const { randomWikiIndex } = this.state;
    if (randomWikis.length < this.state.randomWikiIndex + BUFFER_SIZE) {
      this.props.fetchNextRandomWiki();
    }
    if (
      match.params.pageTitle === randomWikis[randomWikiIndex] &&
      history.action === "REPLACE"
    ) {
      this.setState({
        randomWikiIndex: this.state.randomWikiIndex + 1
      });
    }
  }

  renderEmpty() {
    const { wikiPage, timeRemaining, title } = this.props;
    return (
      <div className="page-details no-padding">
        <p>No suitable images for</p>
        <h2>{(wikiPage && wikiPage.title) || title}</h2>
        <p>Loading random page in {timeRemaining} seconds</p>
        <p className="help-text">
          We only return shirts that use images
          <br /> with an acceptable license and resolution.
        </p>
      </div>
    );
  }

  // addToBag = (e, shirtMeta) => {
  //   e.stopPropagation();

  //   // Add item to cart and then show them the checkout window.
  //   const newOrders = [
  //     ...this.props.orders,
  //     createNewOrder(
  //       getDefaultVariantIdForColor(SHIRT_COLORS.white),
  //       shirtMeta,
  //       this.props.wikiPage,
  //       false, // No author attribution.
  //       false // No page attribution.
  //     )
  //   ];
  // }

  toggleFavorite = e => {
    e.stopPropagation();
    const { wikiPage } = this.props;
    const title = getTitleForPage(wikiPage);

    let favorites = JSON.parse(localStorage.getItem("favorites"));
    if (favorites && favorites[title]) {
      // Remove from favorites list.
      delete favorites[title];
      localStorage.setItem("favorites", JSON.stringify(favorites));
      this.setState({
        favorite: false
      });
    } else {
      // Add and instantiate favorites list.
      if (!favorites) favorites = {};
      favorites[title] = true;
      localStorage.setItem("favorites", JSON.stringify(favorites));
      this.setState({
        favorite: true
      });
    }
  };

  onShirtClick = () => {
    this.props.setGalleryScrollPosition(this.galleryRef.current.scrollLeft);
  };

  render() {
    const { match, history, randomWikis } = this.props;

    const title =
      match.params.pageTitle || randomWikis[this.state.randomWikiIndex];

    if (!title) {
      return (
        <Fragment>
          <Header />
          <PageContent>
            <ShirtLoader count={10} />
          </PageContent>
        </Fragment>
      );
    }

    return (
      <Fragment>
        <Header />
        <PageContent>
          <WikiPageData
            pageTitle={title}
            contents={({ page, images }) => {
              if (!page) return 'Oops! Could not find page.';
              if (match.url !== `/${getTitleForPage(page)}`)
                history.replace(`/${getTitleForPage(page)}`);
              return (
                <Fragment>
                  <div className="gallery" ref={this.galleryRef}>
                    <div className="page-card">
                      <div className="card-content">
                        <div className="title-row">
                          <h2>{page.title}</h2>
                        </div>
                        <p>{page.extract}</p>
                      </div>
                      <div className="card-footer">
                        <a
                          href={page.content_urls.desktop.page}
                          rel="noopener noreferrer"
                          target="_blank"
                          className="link-button mt10"
                        >
                          View on Wikipedia
                        </a>
                      </div>
                    </div>
                    {images.map(wikiImage => {
                      return (
                        <Link
                          to={`${match.url}/${wikiImage.canonicalTitle}`}
                          key={wikiImage.canonicalTitle}
                          onClick={this.onShirtClick}
                        >
                          <ItemPreview
                            fixedHeight
                            authorName={wikiImage.author}
                            imageUrl={wikiImage.url}
                            itemDisplayName={wikiImage.description}
                            pageDisplayTitle={page.title}
                            thumbnailUrl={wikiImage.thumb}
                            dimensions={wikiImage.dimensions}
                          />
                        </Link>
                      );
                    })}
                  </div>
                  <div className="page-details">
                    <RelatedPagesData
                      pageTitle={title}
                      contents={({ pages }) => {
                        return (
                          <div className="list-container">
                            <div className="trending-articles">
                              <div className="daily-tag">Related Pages</div>
                              {pages.map((page, index) => {
                                return (
                                  <WikiPageData
                                    key={page.title}
                                    pageTitle={page.title}
                                    contents={data => {
                                      return <WikiListItem wiki={data} />;
                                    }}
                                    loader={() => (
                                      <div className="wiki-list-loader">
                                        Loading...
                                      </div>
                                    )}
                                  />
                                );
                              })}
                            </div>
                          </div>
                        );
                      }}
                      loader={() => <div>Loading...</div>}
                    />
                  </div>
                  <RandomizeButton
                    handleClick={() => history.push("/browse")}
                  />
                </Fragment>
              );
            }}
            loader={() => <div>Loading...</div>}
          />
        </PageContent>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  randomWikis: state.randomWikis
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      clearDeepLinkImageTitle: () => ({
        type: ACTIONS.CLEAR_DEEP_LINK_IMAGE_TITLE
      }),
      setGalleryScrollPosition: xOffset => ({
        type: ACTIONS.SET_GALLERY_SCROLL_POSITION,
        x: xOffset
      }),
      clearPushUrl: () => ({
        type: ACTIONS.TOGGLE_PUSH_URL
      }),
      fetchNextRandomWiki: fetchNextRandomWiki,
      emptyRandomWikis: emptyRandomWikis
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WikiPage);
