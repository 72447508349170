import _ from 'underscore';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './order-status.css';

import { getIdFromURL } from '../../util/url';
import { SHIRT_VARIANTS } from '../../constants';
import { getDescriptionForStatus, getShirtSizeLabelForValue } from '../../util/orders';
import WebService from '../../web-service';

import Header from '../header';
import ItemPreview from '../item-preview';
import Loader from '../loader';
import ShowMoreText from 'react-show-more-text';

class OrderStatus extends Component {
  state = {
    failedToLoadOrder: false,
    failedToLoadOrderMessage: '',
    manufacturingStatus: 'unknown',
    orderStatusLoaded: false,
    orders: [],
    shipments: [],
    statusDescription: ''
  }

  loadOrderState = async () => {
    let orderStatus;
    try {
      orderStatus = await WebService.getOrderStatus(this.customerId);
    } catch (e) {
      console.log('Error fetching the order status:', e);
      this.setState({
        failedToLoadOrder: true,
        failedToLoadOrderMessage: e.message,
        orderStatusLoaded: true
      });
      return;
    }

    const {
      manufacturingStatus,
      orders,
      shipments
    } = orderStatus;

    const statusDescription = getDescriptionForStatus(manufacturingStatus);

    this.setState({
      failedToLoadOrder: false,
      orderStatusLoaded: true,
      manufacturingStatus,
      orders,
      shipments,
      statusDescription
    });
  }

  componentDidMount() {
    this.customerId = getIdFromURL();
    if (!this.customerId) {
      this.setState({
        failedToLoadOrder: true,
        failedToLoadOrderMessage: 'No order is supplied on the url. Please double check your email for the link to your order.',
        orderStatusLoaded: true
      });
    } else {
      this.loadOrderState();
    }
  }

  getCombinedItems = function(orders) {
    let combinedItems = [];
    _.each(orders, (order, i) => {
      const variant = SHIRT_VARIANTS[order.variantId];
      const index = _.findIndex(combinedItems, (o) => {
        return o.imageTitle === order.imageTitle && o.color === variant.color && o.authorAttribution === order.authorAttribution && o.pageAttribution === order.pageAttribution;
      });
      if (index >= 0) {
        combinedItems[index].orders.push(order);
        combinedItems[index].totalQuantity += order.quantity;
        combinedItems[index].orderIndexes.push(i);
        if (order.lastModifiedAt > combinedItems[index].lastModifiedAt) combinedItems[index].lastModifiedAt = order.lastModifiedAt;
      } else {
        console.log('authorAttribution', order.authorAttribution);
        console.log('pageAttribution', order.pageAttribution);

        combinedItems.push({
          imageTitle: order.imageTitle,
          pageTitle: order.pageTitle,
        
          authorAttribution: order.authorAttribution,
          pageAttribution: order.pageAttribution,

          authorName: order.authorName,
          imageDisplayName: order.imageDisplayName,
          pageDisplayTitle: order.pageDisplayTitle,

          color: variant.color,
          size: variant.size,
          totalQuantity: Number(order.quantity),

          thumbnailUrl: order.thumbnailUrl,
          unprocessedImageUrl: order.unprocessedImageUrl,

          orders: [order],
          orderIndexes: [i],
        });
      }
    });
    return combinedItems;
  }

  render() {
    const {
      failedToLoadOrder,
      failedToLoadOrderMessage,
      manufacturingStatus,
      orders,
      orderStatusLoaded,
      shipments,
      statusDescription
    } = this.state;

    const combinedItems = this.getCombinedItems(orders);

    return (
      <div className="order-status">
        <Header />

        <div
          className="order-status-container"
        >
          {failedToLoadOrder && (
            <div className="order-status-failed">
              <div className="order-status-failed-text">
                Unable to load the order's status: {failedToLoadOrderMessage}
              </div>
            </div>
          )}
          {!orderStatusLoaded && (
            <div className="order-status-loading">
              <div className="order-status-loading-text">
                Loading...
              </div>
              <Loader />
            </div>
          )}
          {orderStatusLoaded && !failedToLoadOrder && (
            <div className="order-status-info-container">
              <h1>
                Order status: <strong>{manufacturingStatus}</strong>
              </h1>
              <p>
                {statusDescription}
              </p>
              {shipments && shipments.length > 0 && (
                <div>
                  {shipments.map(shipment => {
                    return (
                      <div className="order-status-shipments">
                        <div className="order-status-shipment">
                          Carrier: {shipment.service}
                        </div>
                        <div>
                          Shipped date: {shipment.shippedDate}
                        </div>
                        <div className="order-status-shipment">
                          Tracking url:&nbsp;
                            <a
                              className="order-status-shipment-url"
                              href={shipment.trackingUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {shipment.trackingUrl}
                            </a>
                        </div>
                        <div className="order-status-shipment">
                          Tracking code: {shipment.trackingCode}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              {(!shipments || shipments.length < 1) && manufacturingStatus !== 'archived' && manufacturingStatus !== 'fulfilled' && manufacturingStatus !== 'canceled' && (
                <p>
                  Once your order has been shipped from the facility, a tracking number and url will appear here.
                </p>
              )}
              <div className="order-status-items">
              {combinedItems.map(item => {
                const id = `${item.imageTitle}_${item.color}_${item.size}_${!!item.authorAttribution}_${!!item.imageAttribution}`;
                return (
                  <div
                    className="purchase-info-container"
                    key={id}
                  >
                    <div className="flex-row">
                      <Link
                        to={`/${item.pageTitle}/${item.imageTitle}`}
                      >
                        <ItemPreview
                          authorName={item.authorName}
                          color={item.color}
                          size={item.size}
                          imageUrl={item.unprocessedImageUrl}
                          includeAuthorAttribution={!!item.authorAttribution}
                          includePageAttribution={!!item.pageAttribution}
                          pageDisplayTitle={item.pageDisplayTitle}
                          thumbnailUrl={item.thumbnailUrl}
                          hideButtons
                          hideLabel
                        />
                      </Link>
                      <div className="column">
                        <h2><ShowMoreText lines={2} more='More' less='Less'>
                          {item.imageDisplayName}
                        </ShowMoreText></h2>
                        <div className="gray">
                          <ShowMoreText lines={3} more='More' less='Less'>
                            {item.authorName}
                          </ShowMoreText>
                        </div>
                        <div className="row">
                          <div className="column">
                            {item.orders.map((order, orderIndex) => {
                              const { size } = SHIRT_VARIANTS[order.variantId];
                              return (
                                <React.Fragment key={item.orderIndexes[orderIndex]}>
                                  <div className="order-status-size">Size: {getShirtSizeLabelForValue(size)}</div>
                                  <div className="order-status-quantity">Quantity: {order.quantity}</div>
                                </React.Fragment>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default OrderStatus;
