import React, { Component } from "react";
import { Link } from "react-router-dom";

import Mockup from "../mockup";
import { DEFAULT_SHIRT_COLOR } from "../../constants";
import { getTitleForPage } from "../../util/wikipedia-images";
import "./wiki-layouts.scss";

const MAX_IMAGES_PER_ARTICLE = 12;

class WikiListItem extends Component {
  render() {
    const { wiki, index = null } = this.props;
    const { page, images } = wiki;
    return (
      <div className="wiki-list-item">
        <div
          className={`wiki-list-left ${
            index === null ? "no-link-padding" : ""
          }`}
        >
          <h1>
            {page.title}
          </h1>
          <Link
            className={`link-button ${images.length ? "" : "disabled"}`}
            to={`/${getTitleForPage(page)}`}
          >
            See Shirts
          </Link>
        </div>
        <div className="wiki-list-right">
          {images.map((wikiImage, index) => {
            if (index > MAX_IMAGES_PER_ARTICLE) return null;
            return (
              <Link
                to={`/${getTitleForPage(page)}/${wikiImage.canonicalTitle}`}
                key={wikiImage.url}
              >
                <div
                  className="wiki-shirt-preview-small"
                >
                  <Mockup
                    authorName={wikiImage.author}
                    imageUrl={wikiImage.url}
                    color={DEFAULT_SHIRT_COLOR}
                    pageDisplayTitle={page.title}
                    // thumbnailUrl={wikiImage.thumb}
                    useThumbnail
                    dimensions={wikiImage.dimensions}
                    include
                  />
                </div>
              </Link>
            );
          })}
          {!images.length && <p className="gray">No available shirts</p>}
        </div>
      </div>
    );
  }
}

export default WikiListItem;
