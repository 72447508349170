import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import './faq.scss';

import Header from '../header';

class FAQ extends Component {
  componentDidMount() {
    document.title = `WearWiki - FAQ`;
  }

  render() {
    return (
      <div className="faq">
        <Header />

        <div className="faq-container">
          <h1>
            Frequently Asked Questions
          </h1>
          <div className="faq-qa-container">
            <div className="faq-question">
              What are the t-shirts made out of?
            </div>
            <div className="faq-answer">
              The t-shirts are 100% ring spun hand drawn cotton. Our t-shirt blanks are from the folks over at Next Level Apparel. They are made in central america.
            </div>
          </div>
          <div className="faq-qa-container">
            <div className="faq-question">
              These images are great - where are they from?
            </div>
            <div className="faq-answer">
              We pull our images from images embedded in wikipedia articles. We only pull images which fit a certain license criteria and meet a minimum resolution size.
            </div>
          </div>
          <div className="faq-qa-container">
            <div className="faq-question">
              How long does t-shirt fulfillment take?
            </div>
            <div className="faq-answer">
              T-shirt fulfillment typically takes 2-7 days.
            </div>
          </div>
          <div className="faq-qa-container">
            <div className="faq-question">
              How can I contact WearWiki?
            </div>
            <div className="faq-answer">
              <Link
                className="faq-contact-link"
                to="/contact"
                target="_blank"
              >Contact form</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FAQ;
