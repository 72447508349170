import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "intersection-observer"; // optional polyfill
import Observer from "@researchgate/react-intersection-observer";
import { fetchNextRandomWiki } from "../../store/actions";
import ShirtLoader from "../shirt-loader";
import { PageContent } from "../page";

import RandomChild from "./random-child.js";
import "./random-page.scss";

const BUFFER_SIZE = 10;

class RandomPage extends Component {
  constructor(props) {
    super(props);

    this.loaderTimeout = null;

    this.state = {
      visibleWikis: 5
    };
  }

  handleIntersection = event => {
    if (event.intersectionRatio > 0.2) {
      this.setState({ visibleWikis: this.state.visibleWikis + 5 });
      this.loaderTimeout = setTimeout(() => {
        this.setState({ visibleWikis: this.state.visibleWikis + 5 });
      }, 2000);
    } else {
      if (this.loaderTimeout) clearTimeout(this.loaderTimeout);
    }
  };

  renderLoader() {
    const options = {
      onChange: this.handleIntersection,
      root: "#page-container",
      threshold: 0.2
    };

    return (
      <Observer {...options}>
        <div className="loader-pane">
          <ShirtLoader count={1} />
          <p>Rare shirts incoming...</p>
        </div>
      </Observer>
    );
  }

  componentDidMount() {
    this.props.fetchNextRandomWiki();
    this.props.fetchNextRandomWiki();
    this.props.fetchNextRandomWiki();
    this.props.fetchNextRandomWiki();
    this.props.fetchNextRandomWiki();
  }

  componentDidUpdate() {
    const { randomWikis } = this.props;
    if (randomWikis.length < this.state.visibleWikis + BUFFER_SIZE) {
      this.props.fetchNextRandomWiki();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.randomWikis !== this.props.randomWikis) return true;
    if (nextState.visibleWikis !== this.state.visibleWikis) return true;
    return false;
  }

  render() {
    const { randomWikis, wikis } = this.props;
    return (
      <PageContent>
        <div>
          {randomWikis.map((wikiId, index) => {
            const { page, images } = wikis[wikiId];
            if (!images || !images.length) return null;
            if (index > this.state.visibleWikis) return null;
            return (
              <div className="child-container">
                <RandomChild
                  key={wikiId}
                  wikiId={wikiId}
                  page={page}
                  images={images}
                />
              </div>
            );
          })}
          {this.renderLoader()}
        </div>
      </PageContent>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  randomWikis: state.randomWikis,
  wikis: state.wikis
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      fetchNextRandomWiki: fetchNextRandomWiki
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RandomPage);
