module.exports.formatMoneyAsNiceString = function(money) {
  let moneyString = `${money}`;
  if (moneyString.indexOf('.') === -1) {
    moneyString += '.00';
  } else if (moneyString.indexOf('.') === moneyString.length - 1) {
    moneyString += '0';
  }

  return moneyString;
}
