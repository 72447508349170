import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import './terms.scss';

import Footer from '../footer';
import Header from '../header';

class Terms extends Component {
  componentDidMount() {
    document.title = `WearWiki - Terms & Conditions`;
  }

  render() {
    return (
      <Fragment>
        <Header />
        <div className="terms">
          <h2>Terms &amp; Conditions</h2>

          <p><em>Effective February 26, 2019</em></p>

          <p><strong>Thank you for visiting the Wearwiki website, wearwiki.com ("Site") owned and operated by Rocket Emoji LLC. You should access this Site or use our services only after you have carefully read and agreed to these terms and conditions ("Terms") of use which govern your access to and use of the site. These Terms apply to all Site visitors’ access to and use of the Site.</strong></p>

          <h2>Using this Site</h2>
          <p>Please use a standards-compliant web browser such as Safari 3.0+, Chrome, or Firefox 3.0+. Javascript must be enabled to use the Site.</p>

          <h2>Terms of Use</h2>
          <p>By using our Site, you agree to the Terms of Use. We may change or update these terms so please check this page regularly. We do not represent or warrant that the information on our web site is accurate, complete, or current. This includes pricing and availability information. We reserve the right to correct any errors or omissions, and to change or update information at any time without prior notice.</p>

          <h2>Termination Clause</h2>
          <p>We reserve the right to terminate your access to our Site, without cause or notice. All provisions of this Agreement that, by their nature, should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.</p>

          <h2>Contact Information</h2>
          <p>To contact us either fill out the form on the <Link to="/contact">contact us page</Link> or email us at <a href="mailto:support@wearwiki.com">support@wearwiki.com</a></p>

          <h2>Cancellation Policy</h2>
          <p>We do not allow cancellations once the order has been placed. All orders are final and cannot be changed after submission. We do our best to ship all packages within 14 days. In the event that you order has not shipped within 14 days, you may reach out to customer service to request a cancellation. </p>

          <h2>Sales Tax</h2>

          <p>Sales Tax is charged on sales where required by law. We've baked this into our pricing model so that a user does not directly see their individual sales tax.</p>

          <h2>Return Policy</h2>
          <p>We will gladly exchange an item for online store credit only. Before sending the item back, please <Link to="/contact">contact us</Link> within 2 days of receipt of your shipment. Packages sent without first contacting us will not be accepted. Items to be exchanged must be returned in perfect, new condition with all original tags attached. Wearwiki must receive the returned item within 7 days of return authorization.</p>

          <p>For any returned items, you will not be refunded the original cost of shipping, and you are responsible for the cost of the return shipping. We do not assume responsibility for reimbursement or compensation of returned packages lost in transit without proof of delivery to Wearwiki.</p>

          <h2>Purchase for Personal Use Only</h2>
          <p>You may purchase products only for personal use and not for resale. By placing your order, you certify that you are purchasing products for personal use only and not for resale. We reserve the right to refuse orders for any reason without explanation.</p>

          <h2>Secure Processing</h2>

          <p>Wearwiki uses secure processing SSL(Secure Sockets Layer), a protocol developed for the transmission of private information over the internet. SSL uses a private key to encrypt your data, including your credit card information, so that it cannot be read while being transferred over the internet.</p>

          <h2>Privacy</h2>

          <p>Wearwiki.com respects our customers account information as private and confidential information and will never share this with any outside afflictions or individuals. Your information is secured and encrypted with the Secure Socket Layer Software. You acknowledge that you are solely responsible for the accuracy and content of such information.</p>

          <h2>Indemnity</h2>
          <p>You agree to defend, indemnify and hold us, our directors, officers, employees, agents and affiliates harmless from any and all claims, liabilities, damages, costs and expenses, including reasonable attorneys’ fees, in any way arising from, related to or in connection with your use of the Site, your violation of the Terms or the posting or transmission of any materials on or through the Site by you, including, but not limited to, any third party claim that any information or materials you provide infringes any third party proprietary right.</p>

          <h2>Disclaimer of Warranties</h2>

          <p>
            YOU UNDERSTAND AND AGREE THAT:
            <br />
            THE SITE, INCLUDING, WITHOUT LIMITATION, ALL CONTENT, FUNCTION, MATERIALS AND SERVICES IS PROVIDED “AS IS,” WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY WARRANTY FOR INFORMATION, DATA, DATA PROCESSING SERVICES OR UNINTERRUPTED ACCESS, ANY WARRANTIES CONCERNING THE AVAILABILITY, ACCURACY, COMPLETENESS, USEFULNESS, OR CONTENT OF INFORMATION, AND ANY WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. WE DO NOT WARRANT THAT THE SITE OR THE FUNCTION, CONTENT OR SERVICES MADE AVAILABLE THEREBY WILL BE TIMELY, SECURE, UNINTERRUPTED OR ERROR FREE, OR THAT DEFECTS WILL BE CORRECTED. WEARWIKI MAKES NO WARRANTY THAT THE SITE WILL MEET USERS’ EXPECTATIONS OR REQUIREMENTS. NO ADVICE, RESULTS OR INFORMATION, OR MATERIALS WHETHER ORAL OR WRITTEN, OBTAINED BY YOU THROUGH THE SITE SHALL CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN. IF YOU ARE DISSATISFIED WITH THE SITE, YOUR SOLE REMEDY IS TO DISCONTINUE USING THE SITE.
          </p>


          <h2>Limitation of Liability</h2>

          <p>IN NO EVENT SHALL WE, OUR AFFILIATES OR ANY OF THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, OR CONTENT OR SERVICE PROVIDERS BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY OR PUNITIVE DAMAGES ARISING FROM OR DIRECTLY OR INDIRECTLY RELATED TO THE USE/MISUSE OF, OR THE INABILITY TO USE, THE SITE OR THE CONTENT, MATERIALS AND FUNCTION RELATED THERETO, INCLUDING, WITHOUT LIMITATION, LOSS OF REVENUE, OR ANTICIPATED PROFITS, OR LOST BUSINESS, DATA OR SALES, OR COST OF SUBSTITUTE SERVICES, EVEN IF WE OR OUR REPRESENTATIVE OR SUCH INDIVIDUAL HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY SO SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU. IN NO EVENT SHALL THE TOTAL LIABILITY OF US TO YOU FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION (WHETHER IN CONTRACT OR TORT, INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE OR OTHERWISE) ARISING FROM THE TERMS OR YOUR USE OF THE SITE EXCEED, IN THE AGGREGATE, $100.00.</p>

          <h2>Governing Law Clause</h2>

          <p>These terms and conditions are governed by the laws of the United States of America and the laws of the State of New York.</p>

          <h2>General Information</h2>

          <p>The Terms constitute the entire agreement between you and us and govern your use of the Site, superseding any prior agreements between you and us.</p>
          <p>Any claim or cause of action you may have with respect to us or the Site must be commenced within one (1) year after the claim or cause of action arose.</p>
          <p>Our failure to exercise or enforce any right or provision of the Terms shall not constitute a waiver of such right or provision. If any provision of the Terms is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties’ intentions as reflected in the provision, and the other provisions of the Terms remain in full force and effect.</p>
          <p>The Terms inure to the benefit of our successors, assigns and licensees. The section titles in the Terms are for convenience only and have no legal or contractual effect.</p>
        </div>
        <Footer />
      </Fragment>
    );
  }
}

export default Terms;
