module.exports = {
  FEATURED_TEES: [{
    imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/6/69/Osteology_of_the_carnivorous_Dinosauria_in_the_United_States_National_museum_BHL40623209_edited.jpg',
    pageTitle: 'Ceratosaurus',
    imageTitle: 'File:Osteology of the carnivorous Dinosauria in the United States National museum BHL40623209 edited.jpg',
    authorName: 'Gilmore, Charles W.',
    itemDisplayName: 'Skull diagram of Ceratosaurus nasicornis, from Gilmore, C.W. (1920). "Osteology of the carnivorous Dinosauria in the United States National Museum, with special reference to the genera Antrodemus (Allosaurus) and Ceratosaurus". Bulletin of the United States National Museum. 110 (110): 1–154. doi:10.5479/si.03629236.110.i.'
  },  {
    imageUrl: "https://upload.wikimedia.org/wikipedia/commons/a/ae/Darwin%27s_finches_by_Gould.jpg",
    pageTitle: "Extinction",
    imageTitle: "File:Darwin's finches by Gould.jpg",
    authorName: "John Gould (14.Sep.1804 - 3.Feb.1881)",
    itemDisplayName: "Darwin's finches or Galapagos finches. Darwin, 184…ynchus parvulus4. (category) Certhidea olivacea"
  }, 
  // // VVV went offline.
  // {
  //   imageUrl: "https://upload.wikimedia.org/wikipedia/commons/e/e0/Ishihara_9.png",
  //   pageTitle: "Ishihara_test",
  //   imageTitle: "File:Ishihara 9.png",
  //   authorName: "Unknown",
  //   itemDisplayName: "Ishihara Plate 9"
  // }, 
  {
    imageUrl: "https://upload.wikimedia.org/wikipedia/commons/9/96/Apollo_landing_sites.jpg",
    pageTitle: "Apollo_program",
    imageTitle: "File:Apollo landing sites.jpg",
    authorName: "Soerfm",
    itemDisplayName: "Landing sites for Apollo Moon missions (Apollo 13 …n the Fra Mauro area, the same area as Apollo 14)"
  }, {
    imageUrl: "https://upload.wikimedia.org/wikipedia/commons/c/c9/US_2010_Census_State_Population_Per_Electoral_Vote.png",
    pageTitle: "National_Popular_Vote_Interstate_Compact",
    imageTitle: "File:US 2010 Census State Population Per Electoral Vote.png",
    authorName: "Perl coder",
    itemDisplayName: "Chart showing 2010 census state population per ele…state (from apportionment following 2010 census)."
  }, {
    imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/f/f8/Great_Cameo_of_France_CdM_Paris_Bab264_white_background.jpg',
    pageTitle: 'Augustus',
    imageTitle: 'File:Great Cameo of France CdM Paris Bab264 white background.jpg',
    authorName: 'Unknown',
    itemDisplayName: 'So-called Great Cameo of France. Five-layered sard…an artwork, second quarter of the 1st century AD.'
  }, {
    imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/2/24/Antarctica_relief_location_map.jpg',
    pageTitle: 'Williams_Field',
    imageTitle: 'File:Antarctica relief location map.jpg',
    authorName: 'Alexrk2',
    itemDisplayName: 'Physical Location map Antarctica, Azimuthal equidistant projection'
  }, {
    imageUrl: "https://upload.wikimedia.org/wikipedia/commons/1/1b/Roman_Senators_by_A_Yakovlev_1911.jpg",
    pageTitle: "Lucius_Scribonius_Libo_(consul_34_BC)",
    imageTitle: "File:Roman Senators by A Yakovlev 1911.jpg",
    authorName: "Alexandre Jacovleff",
    itemDisplayName: "The illustration for the humorous book The General History Edited by Satyricon."
  }, {
    imageUrl: "https://upload.wikimedia.org/wikipedia/commons/a/ad/Schokland-natuur-OpenTopo.jpg",
    pageTitle: "Schokland",
    imageTitle: "File:Schokland-natuur-OpenTopo.jpg",
    authorName: "Janwillemvanaalst",
    itemDisplayName: "Topografische kaart van Schokland (natuurgebied). …et Map en uit de Risicokaart. Zie ook de Legenda."
  }, {
    imageUrl: "https://upload.wikimedia.org/wikipedia/commons/0/06/Periodic_trends.png",
    pageTitle: "Periodic_table",
    imageTitle: "File:Periodic trends.png",
    authorName: "Sandbh",
    itemDisplayName: "Various periodic trends"
  }, {
    imageUrl: "https://upload.wikimedia.org/wikipedia/commons/6/60/Jan_Frans_van_Dael_-_Flower_bouquet_in_a_glass.jpg",
    pageTitle: "Jan_Frans_van_Dael",
    imageTitle: "File:Jan Frans van Dael - Flower bouquet in a glass.jpg",
    authorName: "Jan Frans van Dael",
    itemDisplayName: "Untitled"
  },{
    imageUrl: "https://upload.wikimedia.org/wikipedia/commons/f/f6/Potentially_Hazardous_Asteroids_2013.png",
    pageTitle: "NASA",
    imageTitle: "File:Potentially Hazardous Asteroids 2013.png",
    authorName: "NASA/JPL-Caltech",
    itemDisplayName: "Lightness-reversed cropped plot of orbits of all t…hin 4.7 million miles or 7.5 million kilometers)."
  }, {
    imageUrl: "https://upload.wikimedia.org/wikipedia/commons/2/22/Da_Vinci_Vitruve_Luc_Viatour.jpg",
    pageTitle: "Vitruvius",
    imageTitle: "File:Da Vinci Vitruve Luc Viatour.jpg",
    authorName: "Leonardo da Vinci",
    itemDisplayName: "Vitruvian Man"
  }, {
    imageUrl: "https://upload.wikimedia.org/wikipedia/commons/e/e1/Parides_aeneas_didas_MHNT_dos.jpg",
    pageTitle: "Parides",
    imageTitle: "File:Parides aeneas didas MHNT dos.jpg",
    authorName: "Didier Descouens",
    itemDisplayName: "English:  ♀  - Dorsal side Locality : Cacao Crique Baguot, French Guiana."
  }, {
    imageUrl: "https://upload.wikimedia.org/wikipedia/commons/1/15/No%C3%ABl_Coypel_-_Story_of_Apollo-Apollo_and_Mercury%2C_1688.jpg",
    pageTitle: "Apollo",
    imageTitle: "File:Noël Coypel - Story of Apollo-Apollo and Mercury, 1688.jpg",
    authorName: "Noël Coypel",
    itemDisplayName: "Untitled"
  }, {
    imageUrl: "https://upload.wikimedia.org/wikipedia/commons/3/3f/Hydroxyethylstarch_ball-and-stick.png",
    pageTitle: "Hydroxyethyl_starch",
    imageTitle: "File:Hydroxyethylstarch ball-and-stick.png",
    authorName: "MarinaVladivostok",
    itemDisplayName: "Ball-and-stick model of hydroxyethylstarch molecul…e structure is taken from ChemSpider. ID 17340832"
  }],
  LANDING_TEES: [{
    imageUrl: "https://upload.wikimedia.org/wikipedia/commons/6/60/Jan_Frans_van_Dael_-_Flower_bouquet_in_a_glass.jpg",
    pageTitle: "Jan_Frans_van_Dael",
    imageTitle: "File:Jan Frans van Dael - Flower bouquet in a glass.jpg",
    authorName: "Jan Frans van Dael",
    itemDisplayName: "Untitled",
    whiteLabel: true
  }, {
    imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/2/24/Antarctica_relief_location_map.jpg',
    pageTitle: 'Williams_Field',
    imageTitle: 'File:Antarctica relief location map.jpg',
    authorName: 'Alexrk2',
    itemDisplayName: 'Physical Location map Antarctica, Azimuthal equidistant projection'
  }]
};


/*
Unused
{
  imageUrl: "https://upload.wikimedia.org/wikipedia/commons/d/dd/Buzz_salutes_the_U.S._Flag.jpg",
  pageTitle: "Western_culture",
  imageTitle: "File:Buzz_salutes_the_U.S._Flag.jpg",
  authorName: "NASA / Neil A. Armstrong",
  itemDisplayName: "Buzz Aldrin salutes the U.S flag on the Moon (miss…er Neil's LM window is just beyond the LM shadow."
}

{imageUrl: "https://upload.wikimedia.org/wikipedia/commons/7/72/Bastet_dame_katzenkopf.jpg", pageTitle: "Cats_in_ancient_Egypt", imageTitle: "File:Bastet_dame_katzenkopf.jpg", authorName: "Kotofeij K. Bajun", itemDisplayName: "Godess Bastet shown as a ancient egyptian lady wit…e is a modern replication of an antique antetype."}


{imageUrl: "https://upload.wikimedia.org/wikipedia/commons/9/9f/Rhino_size_comparison.jpg", pageTitle: "Rhinoceros", imageTitle: "File:Rhino_size_comparison.jpg", authorName: "Unknown", itemDisplayName: "Comparison of sizes between extant and extint rhin… Paraceratherium transouralicum, acerathere, etc."}

*/