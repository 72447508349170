import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ACTIONS } from '../../store';
import { isStringAndIsInBounds } from '../../util/type';

import './contact-us.scss';

import Header from '../header';
import Input from '../inputs';
import Loader from '../loader';
import { PageContent } from '../page';
import { getTitleForPage } from '../../util/wikipedia-images';

class Contact extends Component {
  static propTypes = {
    reportShirt: PropTypes.func.isRequired,
    reportShirtShirtName: PropTypes.string,
    reportShirtWikiPage: PropTypes.object
  }

  state = {
    email: '',
    errorSending: false,
    message: '',
    subject: '',
    sending: false,
    sent: false
  }

  componentDidMount() {
    document.title = `WearWiki - Contact`;
  }

  componentWillMount() {
    if (this.props.reportShirtShirtName && this.props.reportShirtWikiPage) {
      this.setState({
        message: `Hi, I'm writing to report the image named ${this.props.reportShirtShirtName} on the page ${getTitleForPage(this.props.reportShirtWikiPage)}. I am reporting this shirt because: TYPE_YOUR_REASON_HERE`,
        subject: `Wearwiki shirt report: ${this.props.reportShirtShirtName}`
      });

      this.props.reportShirt(null, null);
    }
  }

  onClickSend = async () => {
    const validationError = this.validate();

    if (validationError) {
      this.setState({
        errorSending: validationError
      });
      return;
    }

    const {
      email,
      subject,
      message
    } = this.state;

    this.setState({
      sending: true
    });

    try {
      const response = await fetch('/contact', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email,
          subject,
          message
        })
      });

      if (!response.ok) {
        const text = await response.text();

        this.setState({
          errorSending: text,
          sending: false
        });
        return;
      }

      // Success!
      this.setState({
        errorSending: false,
        sending: false,
        sent: true
      });
    } catch (e) {
      this.setState({
        errorSending: 'Unable to communicate with server. Please refresh and try again.',
        sending: false
      });
    }
  }

  validate = () => {
    const {
      email,
      message,
      subject
    } = this.state;

    if (!isStringAndIsInBounds(email, 2, 120)) {
      return 'Please enter a valid email.';
    }
    if (!isStringAndIsInBounds(subject, 1, 300)) {
      return 'Please enter a valid message subject.';
    }
    if (!isStringAndIsInBounds(message, 2, 10000)) {
      if (message && message.length > 10000) {
        return 'That message is too long sorry - please shorten it.';
      }
      return 'Please enter a valid message.';
    }

    return false;
  }

  handleInputChange = (name, event) => {
    this.setState({
      errorSending: false,
      [name]: event.target.value
    });
  }

  render() {
    const {
      email,
      errorSending,
      message,
      sending,
      sent,
      subject
    } = this.state;

    return (
      <Fragment>
        <Header />
        <PageContent>
          <div className="contact">

            <h1>
              Contact us
            </h1>
            <div className="contact-description">
              You can also reach out to us at&nbsp;
              <a
                className="contact-email-link"
                href="mailto:support@wearwiki.com?Subject=WearWiki%20contact"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>support@wearwiki.com</strong>
              </a>
            </div>
            
            {!sending && !sent && (
              <React.Fragment>
                <Input
                  id="email"
                  label="Contact Email"
                  onChange={event => this.handleInputChange('email', event)}
                  value={email}
                  type="email"
                />
                <Input
                  id="subject"
                  label="Message Subject"
                  onChange={event => this.handleInputChange('subject', event)}
                  value={subject}
                />
                <div className="contact-body-title">
                  Message
                </div>
                <textarea
                  className="contact-message-body"
                  id="message"
                  name="message"
                  placeholder="Type your message to us here"
                  onChange={event => this.handleInputChange('message', event)}
                  value={message}
                />
                <div className="contact-send-button-container">
                  <button
                    onClick={this.onClickSend}
                  >
                    Send
                  </button>
                </div>
              </React.Fragment>
            )}
            {sending && !sent && (
              <div>
                <div className="contact-sending-message">
                  Sending...
                </div>
                <Loader />
              </div>
            )}
            {!sending && sent && (
              <div className="contact-success-message">
                Success. Your message has been sent. We'll get back to you in the next few days.
              </div>
            )}
            {errorSending && (
              <div className="contact-error-message">
                {errorSending}
              </div>
            )}
          </div>
        </PageContent>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  reportShirtShirtName: state.reportShirtShirtName,
  reportShirtWikiPage: state.reportShirtWikiPage
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    reportShirt: (wikiPage, shirtName) => ({
      type: ACTIONS.REPORT_SHIRT,
      wikiPage,
      shirtName
    })
  }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Contact);
