import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import './footer.scss'

class Footer extends Component {
  render() {
    
    return ( 
      <div className="footer">
        <div className="row">
          <div className="col-sm-4 footer-item">
            <h2 className="title">
              <Link to="/">
                WearWiki
              </Link>
            </h2>
            <div className="footer-item">
              &copy;{new Date().getFullYear()} WearWiki 🚀
            </div>
          </div>
          <div className="col-sm-4 footer-item">
            <div>
              check out our&nbsp;
              <Link to="/faq">
                faq
              </Link>
            </div>
            <div>
              <Link to="/contact">
                contact us
              </Link>
              &nbsp;-  we'll get back to you asap
            </div>
          </div>
          <div className="col-sm-4 footer-item">
            <a
              href="https://instagram.com/wearwiki"
              rel="noopener noreferrer"
              target="_blank"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M21.231 0h-18.462c-1.529 0-2.769 1.24-2.769 2.769v18.46c0 1.531 1.24 2.771 2.769 2.771h18.463c1.529 0 2.768-1.24 2.768-2.771v-18.46c0-1.529-1.239-2.769-2.769-2.769zm-9.231 7.385c2.549 0 4.616 2.065 4.616 4.615 0 2.549-2.067 4.616-4.616 4.616s-4.615-2.068-4.615-4.616c0-2.55 2.066-4.615 4.615-4.615zm9 12.693c0 .509-.413.922-.924.922h-16.152c-.511 0-.924-.413-.924-.922v-10.078h1.897c-.088.315-.153.64-.2.971-.05.337-.081.679-.081 1.029 0 4.079 3.306 7.385 7.384 7.385s7.384-3.306 7.384-7.385c0-.35-.031-.692-.081-1.028-.047-.331-.112-.656-.2-.971h1.897v10.077zm0-13.98c0 .509-.413.923-.924.923h-2.174c-.511 0-.923-.414-.923-.923v-2.175c0-.51.412-.923.923-.923h2.174c.511 0 .924.413.924.923v2.175z" fillRule="evenodd" clipRule="evenodd"/></svg>&nbsp;follow us on gram
            </a>
            <div className="footer-item rip-vine">
              RIP VINE
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-1" />
          
        </div>
      </div>
    );
  }
}

const mapStateToProps = null;
const mapDispatchToProps = null;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Footer);
