import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import './featured.scss';

// import { ACTIONS } from '../../store';
import { TEES } from '../../constants';

import Footer from '../footer';
import Header from '../header';
import ItemPreview from '../item-preview';
// import Mockup from '../mockup';

class Featured extends Component {
  render() {
    return (
      <div className="featured">
        <Header />

        <div className="featured-gallery">
          <h2 className="featured-title">
            Featured Ts
          </h2>
          {TEES.FEATURED_TEES.map(featured => (
            <Link
              className="featured-tee"
              key={featured.itemDisplayName + featured.imageUrl}
              to={`/${featured.pageTitle}/${featured.imageTitle}`}
            >
              <ItemPreview
                authorName={featured.authorName}
                itemDisplayName={featured.itemDisplayName}
                // color=""
                hideButtons
                // hideLabels
                // onClick={}
                imageUrl={featured.imageUrl}
                // includeAuthorAttribution={includeAuthorAttribution}
                // includePageAttribution={includePageAttribution}
                onImageRender={this.onImageRender}
                // pageDisplayTitle={pageDisplayTitle}
                // thumbnailUrl={thumbnailUrl}
                // useThumbnail
              />
            </Link>
          ))}
        </div>

        <Footer />
      </div>
    );
  }
}

const mapStateToProps = null;

const mapDispatchToProps = null;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Featured);