import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchWikiByTitle } from "../../store/actions";

class WikiPageData extends Component {
  componentDidMount() {
    const { pageTitle, wikis, fetchWikiByTitle } = this.props;
    if (!wikis[pageTitle]) fetchWikiByTitle(pageTitle);
  }

  render() {
    const { contents, pageTitle, loader, wikis } = this.props;
    if (!wikis[pageTitle] || !wikis[pageTitle].images) return null;
    return contents(wikis[pageTitle]);
  }
}

const mapStateToProps = (state, ownProps) => ({
  wikis: state.wikis
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      fetchWikiByTitle: fetchWikiByTitle
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WikiPageData);
