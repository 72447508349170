function getRecipientFromCustomerInfo(customerInformation) {
  return {
    address1: customerInformation.shippingAddress1,
    address2: customerInformation.shippingAddress2,
    city: customerInformation.shippingCity,
    country_code: customerInformation.shippingCountry,
    state_code: customerInformation.shippingRegion,
    zip: customerInformation.shippingZip
  };
}

function getItemsFromOrders(orders) {
  return orders.map(orderItem => {
    return {
      quantity: Number(orderItem.quantity),
      variant_id: orderItem.variantId,
      files: [{
        url: orderItem.unprocessedImageUrl
      }]
    };
  });
}

// https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
// Fetch errors when the internet goes down or cant comelete normally.
// 500 or 404 does not erorr. just response.ok = false.
// We should handle the errors better.
class WebService {
  getOrderStatus = async (customerId) => {
    const response = await fetch(`order/status?id=${customerId}`);

    if (!response.ok) {
      const responseText = await response.text();
      throw Error(responseText);
    }

    const orderInfo = await response.json();

    return orderInfo;
  }

  // Order is an array of order objects.
  // Each order object has a quantity and size.
  getShippingOptions = async (customerInformation, orderItems) => {
    let recipient = getRecipientFromCustomerInfo(customerInformation);

    const items = getItemsFromOrders(orderItems);

    // https://www.printful.com/docs/shipping
    const response = await fetch('/shipping/rates', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        recipient,
        items,
        currency: 'USD'
      })
    });

    if (!response.ok) {
      const responseText = await response.text();
      throw Error(responseText);
    }

    const { shippingOptions } = await response.json();

    return shippingOptions;
  }

  makeOrder = async (orders, customerInformation, selectedShippingOption, totalPrice, processedImagesId, stripeToken) => {
    const response = await fetch('/order/create', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        customerInformation,
        clientOrders: orders,
        selectedShippingOption,
        clientTotalPrice: totalPrice,
        processedImagesId,
        stripeToken
      })
    });

    if (!response.ok) {
      const responseText = await response.text();
      throw Error(responseText);
    }

    const { customerId } = await response.json();

    return customerId;
  }
}

export default new WebService();
