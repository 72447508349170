
const { SHIRT_COLORS } = require('../constants');

// 12 x 16 - for men's and unisex.
// https://www.printful.com/faq/printing/50-what-is-the-max-print-area-for-t-shirts-
// 300 DPI
const maxPrintArea300DPIWidth = 3600;
const maxPrintArea300DPIHeight = 4800;

// 200 dpi
const maxPrintArea200DPIWidth = 2400;
const maxPrintArea200DPIHeight = 3200;

// We don't actually want the image to fit the full print area.
// These constants define how big the image can get in relation to the print area.
const maxImageWidthRatio = 0.75;
const maxImageHeightRatio = 0.75;

function scaleImageDown(imageWidth, imageHeight, maxPrintAreaWidth, maxPrintAreaHeight) {
  let width, height = 0;

  if (imageWidth > imageHeight) {
    // Scale for a horizontal fit.
    width = maxImageWidthRatio * maxPrintAreaWidth;
    // Copy the same scaling ratio to the height.
    height = (width / imageWidth) * imageHeight;
  } else {
    // Scale for a vertical fit.
    height = maxImageHeightRatio * maxPrintAreaHeight;
    width = (height / imageHeight) * imageWidth;

    // Here we have to special case where the image width could be bigger than the print area.
    if (width > maxImageWidthRatio * maxPrintAreaWidth) {
      const scaleRatio = (maxImageWidthRatio * maxPrintAreaWidth) / width;
      width = maxImageWidthRatio * maxPrintAreaWidth;
      height = height * scaleRatio;
    }
  }

  return { width, height };
}

/**
 * This dictates where vertically we show the print on a shirt.
 * With a small image we want the image to be centered in the chest area,
 * so it's not as easy as the x positioning (which is always centered).
 * When the image is large enough we want it to take up the entire height.
 **/
function getPrintAreaYOffset(canvasHeight, imageHeight) {
  if (imageHeight < canvasHeight * (19 / 30)) { // A little less than (2 / 3)
    // Center on the chest area.
    return Math.floor((canvasHeight * (1 / 3)) - (imageHeight / 2));
  }

  // Vertically center
  return Math.floor((canvasHeight / 2) - (imageHeight / 2));
}

// You can not supply canvas dimensions if you want it to return the recomended canvas size.
function getImageDimensionsOnCanvas(image, canvasWidth, canvasHeight, optImageWidth, optImageHeight) {
  const imageWidth = optImageWidth || image.width;
  const imageHeight = optImageHeight || image.height;
  let dimensions = {
    width: imageWidth,
    height: imageHeight,
  };

  let is300DPI = false;

  // We scale an image up as much as we can on the page to fit the dimensions when we use the final.
  // First we see if we can scale the image down to fit in 300 dpi.
  if (imageWidth > maxPrintArea300DPIWidth * maxImageWidthRatio || imageHeight > maxPrintArea300DPIHeight * maxImageHeightRatio) {
    is300DPI = true;
    // We can fit the image at 300 dpi.
    dimensions = scaleImageDown(imageWidth, imageHeight, maxPrintArea300DPIWidth, maxPrintArea300DPIHeight);
  } else {
    // Next we see if we have to scale it to fit into 200dpi.
    if (imageWidth > maxPrintArea200DPIWidth * maxImageWidthRatio || imageHeight > maxPrintArea200DPIHeight * maxImageHeightRatio) {
      // We can fit the image at 200 dpi.
      dimensions = scaleImageDown(imageWidth, imageHeight, maxPrintArea200DPIWidth, maxPrintArea200DPIHeight);
    } else {
      // If we've made it here then we don't have to do any image
      // scaling and we can just show the image at it's original resolution 200dpi.
    }
  }

  if (canvasWidth && canvasHeight) {
    // Now that we know where the image will be placed on the shirt we
    // scale the image down to fit into the passed canvas.
    if (is300DPI) {
      const scaleRatio = canvasWidth / maxPrintArea300DPIWidth;
      dimensions.width *= scaleRatio;
      dimensions.height *= scaleRatio;
    } else {
      // 200 dpi.
      const scaleRatio = canvasWidth / maxPrintArea200DPIWidth;
      dimensions.width *= scaleRatio;
      dimensions.height *= scaleRatio;
    }

    // Center the image in the print area.
    return {
      canvasWidth,
      canvasHeight,
      // There will be some loss of accuracy from flooring things.
      // It shouldn't be noticeable to the eye.
      x: Math.floor((canvasWidth / 2) - (dimensions.width / 2)),
      y: getPrintAreaYOffset(canvasHeight, dimensions.height),
      width: Math.floor(dimensions.width),
      height: Math.floor(dimensions.height),
    };
  } else {
    // When the user doesn't supply a canvas width and height
    // we send back the recommended.
    const printAreaWidth = is300DPI ? maxPrintArea300DPIWidth : maxPrintArea200DPIWidth;
    const printAreaHeight = is300DPI ? maxPrintArea300DPIHeight : maxPrintArea200DPIHeight;

    return {
      canvasWidth: printAreaWidth,
      canvasHeight: printAreaHeight,
      // There will be some loss of accuracy from flooring things.
      // It shouldn't be noticeable to the eye.
      x: Math.floor((printAreaWidth / 2) - (dimensions.width / 2)),
      y: getPrintAreaYOffset(printAreaHeight, dimensions.height),
      width: Math.floor(dimensions.width),
      height: Math.floor(dimensions.height),
    };
  }
}

function wrapText(ctx, text, x, y, maxWidth, lineHeight) {
  const words = text.split(' ');
  let line = '';

  for(let n = 0; n < words.length; n++) {
    const testLine = line + words[n] + ' ';
    const metrics = ctx.measureText(testLine);
    if (metrics.width > maxWidth && n > 0) {
      ctx.fillText(line, x, y);
      line = words[n] + ' ';
      y += lineHeight;
    }
    else {
      line = testLine;
    }
  }

  ctx.fillText(line, x, y);
}

module.exports = {
  getImageDimensionsOnCanvas: getImageDimensionsOnCanvas,

  getTransparentProcessedImageData(image, ctx, canvasWidth, canvasHeight, backgroundColor, optImageWidth, optImageHeight) {
    if (backgroundColor === SHIRT_COLORS.black) {
      ctx.globalCompositeOperation = 'lighten';
    } else if (backgroundColor === SHIRT_COLORS.white) {
      ctx.globalCompositeOperation = 'darken';
    } else {
      throw new Error(`ERROR: UNKNOWN BACKGROUND COLOR: ${backgroundColor}`);
    }

    const imageDimensions = getImageDimensionsOnCanvas(image, canvasWidth, canvasHeight, optImageWidth, optImageHeight);

    if (backgroundColor === SHIRT_COLORS.black) {
      ctx.fillStyle = 'rgb(0,0,0)';
    } else {
      ctx.fillStyle = 'rgb(255,255,255)';
    }
    ctx.fillRect(imageDimensions.x, imageDimensions.y, imageDimensions.width, imageDimensions.height);

    ctx.drawImage(image, imageDimensions.x, imageDimensions.y, imageDimensions.width, imageDimensions.height);

    // 1. Get the image data and remove pixels that hit a certain threshold based
    // on the background color (remove pure white from a white t shirt).
    const imageData = ctx.getImageData(imageDimensions.x, imageDimensions.y, imageDimensions.width + 1, imageDimensions.height + 1);    
    const pixels = imageData.data;
    for (let i = 0; i < pixels.length; i += 4) {
      const r = pixels[i],
        g = pixels[i+1],
        b = pixels[i+2];
  
      // When we encounter a pixel in the threshold we make it transparent.
      // This makes unnescessary backgrounds not show up on the shirt.
      if ((backgroundColor === SHIRT_COLORS.black && r < 20 && g < 20 && b < 20) ||
          (backgroundColor === SHIRT_COLORS.white && r > 235 && g > 235 && b > 235)
      ) {
        pixels[i] = 0;
        pixels[i+1] = 0;
        pixels[i+2] = 0;
        pixels[i+3] = 0;
      }
    }

    // Reset the global composition option to be polite.
    ctx.globalCompositeOperation = 'source-over';

    return imageData;
  },

  addTextToBottomRightOfImage(ctx, imageDimensions, imageAttributionText, backgroundColor) {
    if (backgroundColor === SHIRT_COLORS.black) {
      ctx.fillStyle = 'rgb(250, 250, 250)';
    } else if (backgroundColor === SHIRT_COLORS.white) {
      ctx.fillStyle = 'rgb(10, 10, 10)';
    }
    
    // Ensure the font is relatively sized for the canvas.
    // Based at 40px at max width.
    const fontSize = Math.floor(80 * (imageDimensions.canvasWidth / maxPrintArea300DPIWidth));
    ctx.font = `${fontSize}px Georgia`;
    ctx.textBaseline = 'top';
    ctx.textAlign = 'right';
    wrapText(ctx, imageAttributionText, imageDimensions.x + imageDimensions.width, imageDimensions.y + imageDimensions.height, imageDimensions.width, fontSize);
  },

  addTextToTopLeftOfImage(ctx, imageDimensions, imageAttributionText, backgroundColor) {
    if (backgroundColor === SHIRT_COLORS.black) {
      ctx.fillStyle = 'rgb(250, 250, 250)';
    } else if (backgroundColor === SHIRT_COLORS.white) {
      ctx.fillStyle = 'rgb(10, 10, 10)';
    }
    
    // Ensure the font is relatively sized for the canvas.
    // Based at 40px at max width.
    const fontSize = Math.floor(80 * (imageDimensions.canvasWidth / maxPrintArea300DPIWidth));
    ctx.font = `${fontSize}px Georgia`;
    ctx.textBaseline = 'bottom';
    ctx.textAlign = 'left';
    wrapText(ctx, imageAttributionText, imageDimensions.x, imageDimensions.y, imageDimensions.width, fontSize);
  }
};
