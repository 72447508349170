import _ from "underscore";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ShowMoreText from "react-show-more-text";
import Select from "react-select";
import { Link } from "react-router-dom";
import { WikiPageData } from "../data-getters";

import { ACTIONS } from "../../store";
import { createNewOrder, getVarientForColorAndSize } from "../../util/orders";
import {
  SHIRT_COLOR_OPTIONS,
  SHIRT_SIZE_OPTIONS,
  SHIRT_UNIT_PRICE
} from "../../constants";
import { getImageDisplayName, getImageAuthor } from "../../util/parsers";

import "./shirt-page.scss";

import Header from "../header";
import Mockup from "../mockup";
import { PageContent } from "../page";
import Toggle from "../toggle";
import { getTitleForPage } from "../../util/wikipedia-images";

class ShirtPage extends Component {
  static propTypes = {
    includeAuthorAttribution: PropTypes.bool.isRequired,
    includePageAttribution: PropTypes.bool.isRequired,
    reportShirt: PropTypes.func.isRequired,
    setShirtColor: PropTypes.func.isRequired,
    setShirtSize: PropTypes.func.isRequired,
    shirtColor: PropTypes.string.isRequired,
    shirtSize: PropTypes.string.isRequired,
    toggleIncludeAuthorAttribution: PropTypes.func.isRequired,
    toggleIncludePageAttribution: PropTypes.func.isRequired
  };

  state = {
    shirtOptionsMenuOpen: false
  };

  optionsDropdown = null;

  componentDidMount() {
    const { match, wikiPage, wikiImages } = this.props;
    // const activeImage = _.find(
    //   wikiImages,
    //   (img, i) => getTitleForPage(img) === match.params.imageTitle
    // );

    // // There is a page title and it's not loaded
    // if (
    //   match.params.pageTitle &&
    //   match.params.imageTitle &&
    //   (!wikiPage || !wikiImages || !activeImage)
    // ) {
    //   fetchWiki({
    //     pageTitle: match.params.pageTitle
    //   });
    // }

    document.addEventListener("click", this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClick);
  }

  handleClick = e => {
    if (
      this.state.shirtOptionsMenuOpen &&
      !this.optionsDropdown.contains(e.target)
    ) {
      this.setState({
        shirtOptionsMenuOpen: false
      });
    }
  };

  reportShirtClicked = () => {
    this.props.reportShirt(
      this.props.wikiPage,
      this.props.match.params.imageTitle
    );
    this.props.history.push("/contact");
  };

  handleColorChange = e => {
    this.setState({
      color: e
    });
  };

  handleSizeChange = e => {
    this.setState({
      size: e
    });
  };

  render() {
    const { shirtOptionsMenuOpen } = this.state;

    const {
      match,
      includeAuthorAttribution,
      includePageAttribution,
      setShirtColor,
      setShirtSize,
      shirtColor,
      shirtSize,
      toggleIncludeAuthorAttribution,
      toggleIncludePageAttribution
    } = this.props;

    // const toLog = {
    //   imageUrl: activeImageMeta.original.source,
    //   pageTitle: match.params.pageTitle,
    //   imageTitle: match.params.imageTitle,
    //   authorName: getImageAuthor(activeImageMeta),
    //   itemDisplayName: getImageDisplayName(activeImageMeta)
    // };
    // console.log(toLog);

    return (
      <Fragment>
        <Header />
        <PageContent>
          <WikiPageData
            pageTitle={match.params.pageTitle}
            contents={({ page, images }) => {
              const wikiImage = _.find(
                images, // canonical
                (wikiImage, i) => wikiImage.canonicalTitle === match.params.imageTitle || wikiImage.canonicalTitle === match.params.imageTitle
              );
              if (!wikiImage) {
                return (
                  <div className="shirt-page-empty">
                    <h3>
                      Page not found. Please random again :&#41;
                    </h3>
                    <Link className="link-button" to="/browse">
                      go
                    </Link>
                  </div>
                );
              }
              document.title = `WearWiki - ${wikiImage.description}`;
              return (
                <div className="shirt-page">
                  <div className="shirt-preview">
                    <Mockup
                      authorName={wikiImage.author}
                      color={shirtColor}
                      imageUrl={wikiImage.url}
                      includeAuthorAttribution={includeAuthorAttribution}
                      includePageAttribution={includePageAttribution}
                      pageDisplayTitle={page.title}
                      dimensions={wikiImage.dimensions}
                    />
                  </div>
                  <div className="shirt-title container-flex">
                    <Link
                      className="link-button icon-left"
                      to={`/${getTitleForPage(page)}`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path
                          className="icon"
                          d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"
                        />
                      </svg>
                      <span>{page.title}</span>
                    </Link>
                    <div
                      className="shirt-options-container"
                      ref={ref => (this.optionsDropdown = ref)}
                    >
                      <svg
                        className="shirt-options-button"
                        onClick={() => {
                          this.setState({
                            shirtOptionsMenuOpen: !shirtOptionsMenuOpen
                          });
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <circle cx="5" cy="12" r="2" className="icon" />
                        <circle cx="12" cy="12" r="2" className="icon" />
                        <circle cx="19" cy="12" r="2" className="icon" />
                      </svg>
                      <div
                        className="shirt-options-dropdown"
                        style={{
                          visibility: shirtOptionsMenuOpen
                            ? "visible"
                            : "hidden"
                        }}
                      >
                        <div
                          onClick={this.reportShirtClicked}
                          className="shirt-dropdown-item"
                        >
                          Report
                        </div>
                      </div>
                    </div>
                    <h1>
                      <ShowMoreText lines={3} more="More" less="Less">
                        {wikiImage.description}
                      </ShowMoreText>
                    </h1>
                    <div className="gray">
                      <ShowMoreText lines={3} more="More" less="Less">
                        {wikiImage.author}
                      </ShowMoreText>
                    </div>
                    <div className="flex-row">
                      <div className="column">
                        <p className="large">${SHIRT_UNIT_PRICE}</p>
                      </div>
                      <div className="column grow">
                        <Select
                          options={SHIRT_COLOR_OPTIONS}
                          className="select m0"
                          classNamePrefix="select"
                          isSearchable={false}
                          menuPlacement="auto"
                          value={_.find(
                            SHIRT_COLOR_OPTIONS,
                            option => option.value === shirtColor
                          )}
                          onChange={e => {
                            console.log("e", e);
                            setShirtColor(e.value);
                          }}
                        />
                        <Toggle
                          onChange={() =>
                            toggleIncludePageAttribution(
                              !includePageAttribution
                            )
                          }
                          label="Include page name"
                          value={includePageAttribution}
                        />
                        <Toggle
                          onChange={() =>
                            toggleIncludeAuthorAttribution(
                              !includeAuthorAttribution
                            )
                          }
                          label="Include author name"
                          value={includeAuthorAttribution}
                        />
                        <div className="flex-row">
                          <Select
                            options={SHIRT_SIZE_OPTIONS}
                            className="select"
                            classNamePrefix="select"
                            isSearchable={false}
                            value={_.find(
                              SHIRT_SIZE_OPTIONS,
                              option => option.value === shirtSize
                            )}
                            menuPlacement="auto"
                            placeholder="Select a size..."
                            onChange={e => setShirtSize(e.value)}
                          />
                          <Link
                            className="button m0"
                            to={{
                              pathname: "/checkout",
                              state: {
                                newOrder: createNewOrder(
                                  getVarientForColorAndSize(
                                    shirtColor,
                                    shirtSize
                                  ),
                                  wikiImage,
                                  page,
                                  this.props.includeAuthorAttribution,
                                  this.props.includePageAttribution
                                )
                              }
                            }}
                          >
                            Add to Bag
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }}
            loader={() => <div>Loading...</div>}
          />
        </PageContent>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  includeAuthorAttribution: state.includeAuthorAttribution,
  includePageAttribution: state.includePageAttribution,
  orders: state.orders,
  shirtColor: state.shirtColor,
  shirtSize: state.shirtSize
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(
    {
      toggleIncludeAuthorAttribution: includeAuthorAttribution => ({
        type: ACTIONS.SET_INCLUDE_AUTHOR_ATTRIBUTION,
        includeAuthorAttribution
      }),
      toggleIncludePageAttribution: includePageAttribution => ({
        type: ACTIONS.SET_INCLUDE_PAGE_ATTRIBUTION,
        includePageAttribution
      }),
      reportShirt: (wikiPage, shirtName) => ({
        type: ACTIONS.REPORT_SHIRT,
        wikiPage,
        shirtName
      }),
      setCurrentPage: newPage => ({
        type: ACTIONS.SET_CURRENT_PAGE,
        page: newPage
      }),
      setShirtColor: shirtColor => ({
        type: ACTIONS.SET_SHIRT_COLOR,
        shirtColor: shirtColor
      }),
      setShirtSize: shirtSize => ({
        type: ACTIONS.SET_SHIRT_SIZE,
        shirtSize: shirtSize
      })
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShirtPage);
