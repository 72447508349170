import React, { Component } from "react";
import { Link } from "react-router-dom";

import { getTitleForPage } from "../../util/wikipedia-images";
import ItemPreview from "../item-preview";

class RandomChild extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.wikiId !== nextProps.wikiId) return true;
    return false;
  }

  render() {
    const { wikiId, page, images } = this.props;
    return (
      <div key={wikiId} className="wikiEntry">
        <div className="titleRow">
          <Link
            className="link-button icon-right"
            to={`/${getTitleForPage(page)}`}
          >
            <span>From: {page.title}</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              style={{ transform: "rotate(180deg)" }}
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path
                className="icon"
                d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"
              />
            </svg>
          </Link>
        </div>
        <div className="gallery">
          {images && images.length
            ? images.map(wikiImage => {
                return (
                  <Link
                    to={`/${getTitleForPage(page)}/${wikiImage.canonicalTitle}`}
                    key={wikiImage.canonicalTitle}
                  >
                    <ItemPreview
                      fixedHeight
                      authorName={wikiImage.author}
                      thumbnailUrl={wikiImage.thumb}
                      imageUrl={wikiImage.url}
                      itemDisplayName={wikiImage.description}
                      pageDisplayTitle={page.title}
                      dimensions={wikiImage.dimensions}
                    />
                  </Link>
                );
              })
            : null}
        </div>
      </div>
    );
  }
}

export default RandomChild;
