import React, { Component } from "react";

import "./page-container.scss";

class PageContainer extends Component {
  render() {
    const { children, theme } = this.props;
    return (
      <div id="page-container" className={`page-container ${theme}`}>
        {children}
      </div>
    );
  }
}

export default PageContainer;
