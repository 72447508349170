import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { ACTIONS } from "../../store";

import "./randomize-button.scss";

import { FAB } from "../buttons";

const RANDOM_TEXTS = [
  "Take me somewhere new",
  "Randomize",
  "Do the random thing again",
  "Another one",
  "RANDOM",
  "Show me something new",
  "New wikipedia page please",
  "Show me a new one",
  "Again",
  "Random!",
  "Click me!",
  "Click click click",
  "ǝzᴉɯopuɐɹ",
  "Riddikulus!",
  "Rrrrrrandomize",
  "random random random",
  "5 million pages eh?",
  "20,000 pages created each month. click me for another",
  "What else is there?",
  "randomize",
  "Raaaaaandomize",
  "click me for another",
  "click 4 new page",
  "random!!",
  "yarrrr! show me a new page matey",
  "free popcorn if you click me",
  "click me already",
  "Click 4 random",
  "→→🔴←←",
  "random 🔥"
];

class RandomizeButton extends Component {
  state = {
    randomTextIndex: 1
  };

  handleRandomizeClick = () => {
    const { handleClick } = this.props;
    this.setState({
      randomTextIndex: Math.floor(Math.random() * RANDOM_TEXTS.length)
    });
    handleClick();
  };

  render() {
    const { randomTextIndex } = this.state;

    if (window.innerWidth <= 800) {
      return <FAB getNewPage={this.handleRandomizeClick} />;
    }

    return (
      <button
        className="button randomize-button"
        onClick={this.handleRandomizeClick}
      >
        {RANDOM_TEXTS[randomTextIndex]}
      </button>
    );
  }
}

export default RandomizeButton;
