import React, { Component } from 'react';

import './tooltip.scss';

class Tooltip extends Component {
  constructor(props) {
    super(props);

    this.ref = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateWindowSize);
    setTimeout(() => {
      this.sizeTooltip(true);
    }, 100);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowSize);
  }

  updateWindowSize = () => {
    this.sizeTooltip(false);
  }

  sizeTooltip = (animate) => {
    if (this.ref && this.ref.current) {
      let box = this.ref.current.getBoundingClientRect();
      if (box.width > window.innerWidth - (window.innerWidth - box.right) - 15) {
        this.ref.current.style.width = window.innerWidth - (window.innerWidth - box.right) - 15 + 'px';
        this.ref.current.style.whiteSpace = 'normal';
      }

      setTimeout(() => {
        box = this.ref.current.getBoundingClientRect();
        if (box.bottom > (window.innerHeight - 15)) {
          const yOffset = parseInt(this.ref.current.style.getPropertyValue('--yOffset'), 10);
          const newOffset = (yOffset ? yOffset : 0) + box.bottom - (window.innerHeight - 15) + 'px';
          
          this.ref.current.style.setProperty('--yOffset', newOffset);
          this.ref.current.style.setProperty('--arrowOffset', '-55px');
        }

        if (animate) this.ref.current.style.animation = `grow-${this.props.position} 0.5s ease forwards`;
      }, 10);
    }
  }

  render() {
    const { position } = this.props;

    return (
      <div className={`tooltip anchor-${position} nowrap`} ref={this.ref}>
        {this.props.children}
      </div>
    );
  }
}

Tooltip.defaultProps = {
  position: "left"
};

export default Tooltip;